@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500&display=swap");
@import url("./fonts/gilroy/fonts.css");
@import url("./fonts/gordita/stylesheet.css");

@import "bootstrap/dist/css/bootstrap.min.css";

@import "./scss/animation";
@import "./scss/404";
@import "./scss/framework";
@import "./scss/mega-menu";
@import "./scss/style";
@import "./scss/mobile-menu";
@import "./scss/custom-reactify";
@import "./scss/responsive";
