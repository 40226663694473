/* Responsive CSS Document */

@media (min-width: 1350px) {
  .lg-container .container {
    max-width: 1320px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .container-custom.container {
    max-width: 1200px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 0;
    padding-left: 0;
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 1200px) and (max-width: 1450px) {
  .theme-main-menu {
    padding: 20px 40px 0px 40px;
  }
  .fancy-feature-twelve {
    margin-top: 120px;
  }
  .fancy-feature-thirteen .carousel-inner .shape-two {
    left: -45px;
  }
  .pricing-section-five {
    margin-top: 80px;
    padding-top: 100px;
  }
  .fancy-feature-thirtyNine .title {
    font-size: 85px;
  }
  .SC_block_one {
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 1200px) and (max-width: 1366px) {
  .fancy-feature-thirtyFive .slider-wrapper {
    width: 85vw;
    right: -16vw;
  }
}
@media (max-width: 1199px) {
  #mega-menu-holder .mega-menu li .img-box .hover {
    font-size: 20px;
  }
  .accordion-style-two {
    padding-right: 0 !important;
  }
  .pricing-table-area-three .pr-table-wrapper ul {
    padding-left: 0;
  }
  .fancy-feature-one .feature-img-area {
    padding-left: 15px;
  }
  .team-block-one .info .name {
    font-size: 20px;
    padding-top: 0;
  }
  .team-block-one .info {
    padding: 25px 15px;
  }
  .doc-container.full-width {
    padding-right: 0;
  }
  .theme-menu-five .right-widget .demo-button {
    line-height: 48px;
    font-size: 15px;
    width: 160px;
  }
  .pricing-table-area-four .pr-table-wrapper .price {
    font-size: 35px;
    padding-right: 15px;
  }
  .pricing-table-area-four .pr-table-wrapper .top-banner span {
    font-size: 19px;
  }
  .modal-contact-popup-one .main-body .right-side .form-title {
    font-size: 28px;
  }
  .modal-contact-popup-one .main-body .left-side blockquote {
    font-size: 25px;
  }
  .modal-contact-popup-one .main-body .right-side {
    padding: 40px 5%;
  }
  .modal-contact-popup-one .main-body .input-group-meta input {
    height: 55px;
  }
  .modal-contact-popup-one .main-body .input-group-meta textarea,
  .modal-contact-popup-one .main-body .input-group-meta input {
    font-size: 15px;
  }
  .fancy-feature-twelve .row {
    margin: 0 -15px;
  }
  .fancy-feature-twelve .row [class*="col-"] {
    padding: 0 15px;
  }
  .fancy-feature-thirteen .carousel-indicators li p {
    font-size: 20px;
  }
  .fancy-feature-thirteen .carousel-inner {
    padding: 30px;
  }
  .theme-menu-five .right-widget .signIn-action {
    font-size: 18px;
  }
  .theme-menu-five .right-widget .download-btn {
    display: none;
  }
  .hero-banner-seven .download-btn {
    display: block;
  }
  .hero-banner-seven .hero-sub-heading {
    font-size: 18px;
  }
  .fancy-text-block-twentyTwo .text-wrapper {
    padding-left: 0;
  }
  .fancy-feature-seventeen .block-meta {
    padding: 0;
  }
  .terms_and_policy .tab-content {
    padding-left: 0;
  }
  .error-page .text-wrapper p {
    font-size: 20px;
    padding: 30px 0 40px 0;
  }
  .error-page .text-wrapper .logo {
    top: 30px;
  }
  .pricing-table-area-seven .pr-table-wrapper {
    padding-left: 20px;
  }
  .useable-tools-section-four .text-wrapper p {
    font-size: 20px;
  }
  .pricing-section-eight .pr-column .info {
    font-size: 16px;
  }
  .counter-box-five .number {
    font-size: 35px;
  }
  .counter-box-five p {
    font-size: 16px;
  }

  .pricing-section-nine .pr-table-wrapper {
    padding: 30px 15px;
  }
  .pricing-section-nine .pr-table-wrapper .pack-name {
    font-size: 22px;
  }
  .pricing-section-nine .pr-table-wrapper .pack-details {
    font-size: 16px;
    padding: 10px 0 25px;
  }
  .pricing-section-nine .pr-table-wrapper .price {
    font-size: 24px;
  }
  .pricing-section-nine .pr-table-wrapper .top-banner span {
    font-size: 20px;
  }
  .fancy-feature-thirtyNine .img-meta:before {
    width: 700px;
    height: 700px;
  }
  .fancy-feature-thirtyNine .img-meta:after {
    width: 500px;
    height: 500px;
  }
  body .shop-btn-one {
    width: 55px;
    height: 55px;
  }
  .SC_block_one {
    width: 250px;
    height: 250px;
  }
  .SC_block_one h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .flash-sale-banner .right-half .fancy-text {
    font-size: 30px;
  }
  .flash-sale-banner .right-half .sale-text {
    font-size: 15px;
  }
  .flash-sale-banner .right-half h3 {
    font-size: 28px;
  }
  .flash-sale-banner .right-half {
    padding: 70px 30px;
  }
  .product-details-one .product-info .product-name {
    font-size: 28px;
    margin: 18px 0 10px;
  }
  .product-details-one .product-info .price {
    font-size: 22px;
    padding: 20px 0 5px;
  }
  .product-details-one .product-info .description-text {
    font-size: 17px;
    padding: 15px 0 20px;
  }
  .product-details-one .product-info .customize-order h6 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .product-details-one .product-review-tab .tab-content h5 {
    font-size: 18px;
    padding-top: 15px;
  }
  .product-details-one .user-comment-area {
    padding: 15px 0 0;
  }
  .product-details-one .product-review-tab .nav-tabs .nav-link {
    font-size: 14px;
    padding-bottom: 15px;
  }
  .cart-list-form .table th {
    font-size: 14px;
    padding-bottom: 20px;
  }
  .cart-list-form .table tbody tr:first-child td {
    padding-top: 30px;
  }
  .cart-list-form .table tbody td {
    padding: 0 15px 50px;
  }
  .cart-list-form .table .product-info {
    padding-left: 15px;
  }
  .cart-list-form .table .product-info .product-name {
    font-size: 18px;
  }
  .cart-list-form .table .price {
    font-size: 16px;
  }
}
/*(max-width: 1199px)*/
@media (min-width: 992px) and (max-width: 1199px) {
  .theme-main-menu {
    padding: 30px 25px;
  }
  .title-style-five h2,
  .title-style-three h2 {
    font-size: 48px;
  }
  .title-style-one h2 {
    font-size: 50px;
  }
  .title-style-two h2 {
    font-size: 50px;
  }
  .title-style-three p {
    font-size: 18px;
  }
  .fancy-text-block-twenty .nav-tabs li {
    margin-right: 55px;
  }
  .user-data-page .form-wrapper {
    padding: 35px 2%;
  }
  .user-data-page .form-wrapper h2 {
    font-size: 40px;
  }
  .block-style-three {
    padding: 25px 30px 50px 30px;
  }
  .fancy-text-block-one .quote-wrapper blockquote {
    font-size: 32px;
  }
  .fancy-text-block-one .quote-wrapper blockquote:before {
    top: 0;
    left: -75px;
  }
  .fancy-text-block-three .feature-content .header,
  .fancy-text-block-three .feature-content .feature-list {
    padding-left: 25px;
  }
  .fancy-text-block-ten p {
    font-size: 34px;
  }
  .block-style-seven p {
    font-size: 25px;
  }
  .contact-style-two .contact-info-wrapper .address-info p {
    font-size: 22px;
  }
  .fancy-text-block-eleven .img-gallery .overlay-img {
    right: -100px;
  }
  .title-style-four h2 {
    font-size: 50px;
    line-height: 1.2em;
  }
  .fancy-text-block-fifteen .bg-wrapper {
    padding: 70px 15px 0 15px;
  }
  .fancy-text-block-fifteen .text-wrapper p {
    font-size: 36px;
  }
  body .theme-btn-five {
    padding: 0 15px;
  }
  .navbar-nav .nav-item .nav-link {
    margin: 0 18px;
  }

  .theme-menu-five .navbar-nav .nav-item .nav-link {
    font-size: 19px;
    margin: 0 20px;
  }
  .theme-main-menu .navbar-nav .nav-item .nav-link {
    margin: 0 15px;
  }
  .fancy-text-block-twentyOne .text-wrapper p {
    font-size: 40px;
  }
  .fancy-feature-twelve {
    margin-top: 100px;
  }
  .fancy-feature-thirteen .carousel-indicators li {
    width: 33.333%;
  }
  .pricing-section-five {
    margin-top: 80px;
    padding-top: 100px;
  }
  .hero-banner-seven .hero-heading {
    font-size: 60px;
  }
  .hero-banner-seven {
    margin-top: 50px;
  }
  .block-style-eighteen .text-wrapper .title {
    font-size: 55px;
  }
  .block-style-eighteen .screen-holder-two .screen-one {
    right: -15%;
  }
  .title-style-seven h2 {
    font-size: 58px;
  }
  .team-details .main-bg .text-wrapper {
    padding: 20px 40px;
  }
  .error-page .text-wrapper h1 {
    font-size: 60px;
  }
  .theme-menu-five .right-widget .download-btn.style-two {
    display: block;
  }
  .app-screen-preview-one .round-bg {
    width: 78%;
  }
  .title-style-twelve h2 {
    font-size: 54px;
  }
  .title-style-twelve p {
    font-size: 21px;
  }
  .useable-tools-section-four .logo-container .icon {
    height: 220px;
  }
  .pricing-section-eight .pr-list-wrapper li {
    font-size: 15px;
    padding-left: 10px;
  }
  .title-style-thirteen h2 {
    font-size: 52px;
  }
  .title-style-fourteen h2 {
    font-size: 42px;
  }
  .fancy-feature-thirtyFive .slider-wrapper {
    width: 88vw;
    right: -22vw;
  }
  .fancy-feature-thirtyNine .title {
    font-size: 55px;
  }
  .title-style-fifteen h2,
  .title-style-sixteen h2 {
    font-size: 50px;
  }
}
/*(min-width: 992px) and (max-width: 1199px)*/

@media (max-width: 991px) {
  .title-style-five h2 {
    font-size: 40px;
  }
  .title-style-one h2 {
    font-size: 42px;
  }
  .title-style-two h2 {
    font-size: 40px;
    line-height: 1.2em;
  }
  .title-style-three h2 {
    font-size: 38px;
  }
  .title-style-five h2 span:before {
    height: 10px;
  }
  .title-style-five h6 {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .title-style-two p {
    font-size: 15px;
  }
  .title-style-three p {
    font-size: 15px;
    padding-bottom: 8px;
  }
  .title-style-four h2 {
    font-size: 40px;
    line-height: 1.2em;
  }
  .title-style-four h6 {
    font-size: 18px;
    padding-bottom: 10px;
  }
  .title-style-two .sub-text {
    font-size: 20px;
  }
  .title-style-six h6 {
    font-size: 15px;
    padding-bottom: 15px;
  }
  .title-style-six h2 {
    font-size: 45px;
  }
  .title-style-seven h2 {
    font-size: 38px;
  }
  .title-style-six .text-xs {
    font-size: 18px;
    padding-top: 25px;
  }
  body .theme-btn-five {
    line-height: 50px;
    padding: 0 25px;
    min-width: 155px;
  }
  .theme-main-menu {
    padding: 30px 15px;
  }
  .theme-main-menu > div {
    position: static;
  }
  .theme-menu-five .logo {
    left: 15px;
    position: static;
    transform: none;
  }
  .theme-menu-five .right-widget {
    right: 75px;
  }
  .theme-menu-five #mega-menu-holder {
    margin-left: auto;
  }
  .main-page-wrapper {
    padding-top: 99px;
  }
  .theme-main-menu.sticky-menu.fixed {
    padding: 20px 15px;
  }
  .fancy-text-block-four .wrapper:before,
  .fancy-text-block-five:before,
  .fancy-text-block-one .quote-wrapper blockquote:before,
  .contact-us-light:before,
  .pricing-section-two .pricing-table-area .shape-one,
  .fancy-text-block-twentyOne .img-meta .shape-one,
  .fancy-feature-twelve .shape-one,
  .fancy-feature-thirteen .carousel-indicators li:before,
  .block-style-eighteen .text-wrapper .title br {
    display: none;
  }
  .fancy-text-block-nineteen .text-wrapper p {
    font-size: 20px;
    padding-top: 30px;
  }
  .fancy-text-block-nineteen .text-wrapper .name {
    font-size: 22px;
    margin-top: 40px;
  }
  .fancy-text-block-nineteen .text-wrapper {
    padding-top: 50px;
  }
  .fancy-feature-nine {
    padding: 70px 0 40px;
  }
  .accordion-style-two .card .card-header button {
    font-size: 20px;
  }
  .accordion-style-two .card .card-body p {
    font-size: 18px;
  }
  .accordion-style-two .card .card-body {
    padding: 0 30px 30px 0;
  }
  .pricing-section-three {
    padding: 40px 0 60px;
  }
  .pricing-table-area-three .pr-table-wrapper .pack-name {
    font-size: 28px;
  }
  .pricing-table-area-three .pr-table-wrapper .price {
    font-size: 50px;
    padding-top: 15px;
  }
  .pricing-table-area-three .pr-table-wrapper .user-condition {
    font-size: 20px;
  }
  .faq-section-four {
    padding: 50px 0 75px;
  }
  .fancy-text-block-twenty {
    padding: 130px 0 70px;
  }
  .fancy-text-block-twenty .title {
    font-size: 48px;
    padding-bottom: 50px;
  }
  .counter-box-three .number {
    font-size: 30px;
    padding-bottom: 10px;
  }
  .counter-box-three p {
    font-size: 20px;
  }
  .pricing-section-three-inner-page {
    padding: 130px 0 30px;
  }
  .user-data-page .form-wrapper {
    width: 100%;
  }
  .user-data-page .illustration-wrapper {
    display: none !important;
  }
  .user-data-page .form-wrapper {
    padding: 40px 15px 25px;
  }
  .user-data-page .form-wrapper h2 {
    font-size: 38px;
  }
  .user-data-page .form-wrapper .header-info {
    font-size: 18px;
  }
  .fancy-feature-one {
    background: #fff;
  }
  .useable-tools-section h2 {
    font-size: 40px;
    margin: 10px 0 80px;
  }
  .useable-tools-section .img-box {
    width: 145px;
    height: 145px;
  }
  .useable-tools-section .img-box.bx-g {
    width: 160px;
    height: 160px;
  }
  .useable-tools-section.bg-color {
    padding: 100px 0 60px;
  }
  .counter-info-classic {
    margin: 60px auto 0;
  }
  .footer-bg-wrapper {
    padding-top: 130px;
  }
  body .theme-btn-one.btn-lg {
    padding: 0 40px;
    line-height: 52px;
    font-size: 15px;
  }
  .title-style-one p {
    font-size: 18px;
    padding-top: 20px;
  }
  .block-style-three {
    padding: 25px 15px 40px;
  }
  .fancy-text-block-one .quote-wrapper {
    padding: 50px 0 0 0;
  }
  .fancy-text-block-one .quote-wrapper p {
    font-size: 18px;
  }
  .fancy-text-block-one .quote-wrapper blockquote {
    font-size: 28px;
    margin-bottom: 30px;
  }
  .fancy-text-block-one .quote-wrapper h6 {
    font-size: 18px;
  }
  .fancy-text-block-one {
    padding-bottom: 80px;
  }
  .counter-box-two .number {
    font-size: 50px;
  }
  .counter-box-two em {
    font-size: 18px;
    padding-top: 5px;
  }
  .counter-box-two p {
    font-size: 22px;
    padding-top: 12px;
  }
  .fancy-text-block-three .feature-content .header {
    padding: 50px 20px 40px;
  }
  .fancy-text-block-three .feature-content .header h3 {
    font-size: 38px;
  }
  .fancy-text-block-three .feature-content .header p {
    font-size: 18px;
    padding-top: 15px;
  }
  .fancy-text-block-three .feature-content .feature-list {
    padding: 40px 20px 0 20px;
  }
  .fancy-text-block-three .feature-content {
    padding-bottom: 40px;
  }
  .fancy-text-block-three .feature-content .feature-list h4 {
    font-size: 20px;
  }
  .fancy-text-block-three .feature-content.light-bg {
    border-radius: 5px 5px 0 0;
  }
  .fancy-text-block-three .feature-content.dark-bg {
    border-radius: 0 0 5px 5px;
  }
  .fancy-text-block-three .slogan {
    font-size: 24px;
    line-height: 1.5em;
  }
  .fancy-text-block-three {
    padding-bottom: 80px;
  }
  .contact-us-light .address-info .icon {
    height: 50px;
  }
  .contact-us-light .address-info .title {
    padding-top: 20px;
  }
  .contact-us-light .address-info p {
    font-size: 20px;
    line-height: 1.6em;
  }
  .form-style-light {
    margin-top: 40px;
    padding: 50px 20px 35px;
  }
  .faqs-inner-page {
    padding: 80px 0 70px;
  }
  .faqs-header .icon {
    display: none;
  }
  .all-faqs .faqs-all-qus .article-preview {
    padding: 45px 20px;
  }
  .faqs-all-qus .article-preview .article-details {
    padding-bottom: 0;
  }
  .blog-page-bg {
    padding: 90px 0 90px;
  }
  .blog-sidebar-one {
    padding: 60px 0 0;
  }

  .fancy-feature-four .bg-wrapper {
    padding: 70px 0 60px;
  }
  .fancy-text-block-six .img-meta-container {
    position: static;
    margin: 50px auto 0;
    max-width: 60%;
  }
  .fancy-text-block-seven .quote-wrapper {
    text-align: center;
  }
  .fancy-text-block-seven .quote-wrapper .icon {
    margin: 0 auto;
  }
  .fancy-text-block-seven .quote-wrapper blockquote {
    font-size: 32px;
    line-height: 1.5em;
  }
  .useable-tools-section-two.bg-shape .bg-wrapper {
    padding-top: 70px;
  }
  .fancy-short-banner-three .bg-wrapper {
    padding: 60px 20px;
  }
  .fancy-short-banner-three .title-style-one h2 {
    font-size: 40px;
  }
  .fancy-short-banner-three .form-wrapper {
    padding: 30px 0 0;
  }
  .fancy-short-banner-three .form-wrapper form {
    height: 60px;
  }
  .fancy-feature-seven {
    padding: 80px 0 80px;
  }
  .fancy-text-block-thirteen .text-wrapper p {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .fancy-text-block-fourteen .text-wrapper .sub-text {
    font-size: 20px;
    padding-top: 25px;
  }
  .fancy-text-block-fourteen .text-wrapper .name {
    font-size: 20px;
    margin-top: 30px;
  }
  .fancy-hero-two .page-title {
    font-size: 20px;
  }
  .fancy-hero-two .heading {
    font-size: 50px;
  }
  .fancy-hero-two .sub-heading {
    font-size: 18px;
    line-height: 1.7em;
  }
  .fancy-hero-two {
    padding: 0 15px;
  }
  .fancy-hero-two .bg-wrapper {
    padding: 26px 0;
  }
  .fancy-text-block-nine .text-meta {
    font-size: 18px;
    padding-top: 25px;
    line-height: 1.75em;
  }
  .counter-with-icon-one .border-style {
    padding: 20px 0 40px;
  }
  .fancy-feature-five {
    padding: 0 15px;
  }
  .fancy-feature-five .bg-wrapper {
    padding: 80px 0 90px;
  }
  .block-style-seven .icon {
    height: 60px;
  }
  .block-style-seven {
    padding: 40px 0 0;
  }
  .block-style-seven .feature-info {
    padding-top: 22px;
  }
  .block-style-seven p {
    font-size: 20px;
    line-height: 1.45em;
  }
  body .theme-btn-three {
    padding: 0 25px;
    line-height: 48px;
    font-size: 16px;
  }
  .contact-style-two .contact-info-wrapper .address-info {
    height: auto;
    padding: 35px 15px 35px;
  }
  .contact-style-two .contact-info-wrapper .address-info .icon {
    height: 55px;
  }
  .contact-style-two .contact-info-wrapper .address-info p {
    font-size: 18px;
    line-height: 1.5em;
  }
  .form-style-classic .input-group-meta {
    height: 50px;
  }
  .form-style-classic .input-group-meta input,
  .form-style-classic .input-group-meta textarea {
    font-size: 18px;
  }
  .form-style-classic .input-group-meta.lg {
    height: 150px;
  }
  .pricing-section-two .pricing-table-area {
    margin-top: -253px;
  }
  .pricing-section-two .pr-table-wrapper {
    border-bottom: 1px solid #f1f1f1;
  }
  .pricing-section-two .pr-table-wrapper .pack-name {
    font-size: 16px;
  }
  .pricing-section-two .pr-table-wrapper .price {
    font-size: 50px;
    padding: 25px 0 15px;
  }
  .pricing-section-two .pr-table-wrapper .price sup {
    top: -23px;
  }
  .pricing-section-two .pr-table-wrapper .theme-btn-three {
    line-height: 40px;
    padding: 0 50px;
  }
  .pricing-section-two .pr-table-wrapper .pr-feature {
    padding-left: 15px;
  }
  .pricing-section-two .pr-table-wrapper .pr-feature li {
    font-size: 15px;
  }
  .accordion-style-three .card .card-header button {
    font-size: 20px;
    padding: 25px 35px 12px 0;
  }
  .accordion-style-three .card .card-header button[data-toggle="collapse"].collapsed {
    padding-bottom: 25px;
  }
  .fancy-text-block-eleven .text-wrapper {
    padding: 0 0 100px 0 !important;
  }
  .fancy-text-block-eleven .text-wrapper p {
    font-size: 20px;
  }
  .title-style-two span img {
    bottom: -18px;
  }
  .fancy-feature-six .bg-wrapper {
    padding: 80px 0;
  }
  .fancy-text-block-twelve .text-wrapper .sub-text {
    font-size: 18px;
    padding-top: 30px;
  }
  .fancy-text-block-twelve .img-gallery {
    margin-top: 60px;
  }
  .blog-page-white-bg {
    padding-top: 100px;
  }
  .search-filter-form form {
    height: 65px;
  }
  .search-filter-form select {
    top: 16px;
    width: 100px;
    right: 80px;
  }
  .search-filter-form button {
    width: 60px;
  }
  .search-filter-form input {
    padding: 0 180px 0 15px;
  }
  .fancy-text-block-sixteen .img-slick-slider .slick-dots li button {
    width: 100%;
  }
  .fancy-text-block-sixteen {
    padding-bottom: 80px;
  }
  .fancy-text-block-sixteen .img-slick-slider {
    margin-bottom: 70px;
  }
  .fancy-text-block-sixteen .img-slick-slider .slick-dots {
    bottom: -70px;
  }
  .useable-tools-section-three .logo-wrapper {
    position: static;
    transform: none;
    margin: 50px auto 0;
    max-width: 100%;
  }
  .useable-tools-section-three .sub-text {
    font-size: 20px;
    padding: 35px 0 25px 0;
  }
  .useable-tools-section-three .all-button {
    font-size: 17px;
  }
  .faq-classic.with-bg {
    padding: 80px 0 200px;
  }
  .fancy-text-block-seventeen .text-meta {
    font-size: 20px;
    padding: 30px 0 0;
  }
  .teamSliderOne .team-member {
    margin-left: 10px;
    margin-right: 10px;
  }
  .fancy-text-block-eighteen {
    padding-bottom: 300px;
  }
  .useable-tools-section .sub-text {
    font-size: 20px;
  }
  .doc-container.top-border {
    margin-top: 99px;
  }
  .doc-sidebar .list-item li h4 {
    font-size: 20px;
  }
  .doc-sidebar .list-item > li {
    padding: 5px 0;
  }
  .doc-container.full-width .doc-main-body {
    border-right: none;
    padding-left: 20px;
    padding-right: 15px;
  }
  .doc-container .doc-main-body h2 {
    font-size: 30px;
    padding-bottom: 20px;
  }
  .doc-container .doc-main-body h3 {
    font-size: 22px;
    padding-bottom: 20px;
  }
  .doc-container .doc-main-body p {
    padding-bottom: 25px;
  }
  #doc-sidebar-nav {
    padding-right: 15px;
  }
  .doc-container .doc-main-body {
    padding-left: 15px;
  }
  .blog-v4 .post-meta .post {
    padding-top: 30px;
  }
  .blog-v4 .post-meta .post .title {
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 1.2em;
  }
  .blog-v4 .post-meta .post p {
    font-size: 18px;
  }
  .blog-v4 .post-meta .post .read-more {
    margin-top: 25px;
    font-size: 17px;
  }
  .blog-v4 .post-meta {
    margin-bottom: 60px;
  }
  .pricing-section-one .pr-table-wrapper {
    margin-bottom: 45px;
  }
  .pricing-section-one .pricing-table-area {
    padding-bottom: 40px;
  }
  .pricing-section-one .pr-table-wrapper .price {
    font-size: 50px;
  }
  .fancy-text-block-fifteen .bg-wrapper {
    padding: 40px 15px 0;
  }
  .fancy-text-block-fifteen .text-wrapper p {
    font-size: 26px;
  }
  .fancy-text-block-fifteen .text-wrapper h6 {
    font-size: 18px;
    padding-top: 25px;
  }
  .fancy-text-block-fifteen .contact-banner p {
    font-size: 30px;
    text-align: center;
  }
  .fancy-text-block-fifteen .contact-banner .theme-btn-four {
    margin: 25px auto 0 !important;
    line-height: 50px;
    font-size: 18px;
    width: 170px;
  }
  .fancy-text-block-fifteen {
    padding-bottom: 80px;
  }
  .blog-details-post-v1 .post-meta .mark-text {
    font-size: 20px;
  }
  .blog-details-post-v1 .post-meta h4 {
    font-size: 28px;
    padding-top: 40px;
  }
  .fancy-feature-ten .bg-wrapper {
    padding: 60px 0 80px;
  }
  .fancy-feature-ten .sub-text {
    font-size: 20px;
    padding-bottom: 0;
  }
  .fancy-text-block-twentyOne .text-wrapper .icon {
    width: 45px;
  }
  .fancy-text-block-twentyOne .text-wrapper p {
    font-size: 30px;
    padding-bottom: 0px;
  }
  .counter-style-two .border-bottom {
    padding-bottom: 40px;
  }
  .block-style-sixteen {
    margin-top: 60px;
    padding: 40px 0 0;
  }
  .pricing-nav-three {
    margin: 40px 0 50px;
  }
  .pricing-nav-four {
    margin: 40px auto 50px;
  }
  .pricing-table-area-four .pr-table-wrapper.most-popular:after {
    right: 6%;
  }
  body .theme-btn-seven {
    line-height: 54px;
    padding: 0 30px;
    font-size: 16px;
  }
  .fancy-feature-thirteen .carousel-indicators li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .fancy-feature-thirteen .carousel-indicators {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .fancy-feature-thirteen .carousel-inner .shape-one {
    width: 80px;
    top: -27px;
  }
  .fancy-feature-thirteen .carousel-inner .shape-two {
    width: 180px;
    bottom: -76px;
    left: -83px;
  }
  .fancy-portfolio-one {
    text-align: center;
  }
  .fancy-portfolio-one .slider-arrows {
    position: static;
    margin-top: 40px;
  }
  .fancy-portfolio-one .slider-arrows > div {
    width: 45px;
    height: 45px;
    margin: 0 5px;
  }
  .fancy-feature-thirteen:before {
    width: 390px;
    height: 390px;
    top: 0;
  }
  .fancy-feature-thirteen:after,
  .fancy-portfolio-one:before,
  .fancy-portfolio-one:after {
    width: 390px;
    height: 390px;
  }
  .fancy-feature-fourteen .right-side:after,
  .fancy-feature-fourteen .right-side:before {
    width: 290px;
    height: 290px;
  }
  .pricing-section-five:before {
    width: 360px;
    height: 360px;
    top: 10%;
  }
  .pricing-section-five:after {
    width: 360px;
    height: 360px;
    bottom: 0;
  }
  .dark-style .modal-contact-popup-one .right-side:after,
  .dark-style .modal-contact-popup-one .right-side:before {
    width: 270px;
    height: 270px;
    top: 10%;
  }
  .hero-banner-seven .illustration-container {
    position: relative;
    margin: 0 auto 50px;
    max-width: 80%;
    right: auto;
    top: 0;
  }
  .hero-banner-seven {
    margin-top: 40px;
  }
  .hero-banner-seven .hero-heading {
    font-size: 45px;
    padding: 20px 0 0;
  }
  .hero-banner-seven .hero-heading span:before {
    bottom: 10px;
  }
  .hero-banner-seven .hero-sub-heading {
    padding: 30px 0 45px 0;
  }
  .block-style-eighteen .text-wrapper h6 {
    font-size: 14px;
  }
  .block-style-eighteen .text-wrapper .title {
    font-size: 32px;
  }
  .block-style-eighteen .text-wrapper .title span:before {
    height: 10px;
    bottom: 2px;
  }
  .block-style-eighteen .text-wrapper p {
    font-size: 18px;
    padding: 30px 0 40px;
  }
  .block-style-eighteen .text-wrapper {
    padding-bottom: 60px;
  }
  .block-style-eighteen .screen-holder-one,
  .block-style-eighteen .screen-holder-three {
    margin: 0 auto;
  }
  .fancy-text-block-twentyTwo .text-wrapper {
    padding-bottom: 50px;
  }
  .fancy-text-block-twentyTwo .text-wrapper .icon {
    width: 45px;
  }
  .fancy-text-block-twentyTwo .text-wrapper p {
    font-size: 18px;
    padding: 30px 0 40px;
  }
  .fancy-feature-seventeen .bg-wrapper {
    padding: 60px 0 10px;
  }
  .pricing-nav-four .nav-item .nav-link {
    line-height: 50px;
    font-size: 16px;
  }
  .pricing-table-area-six .pr-table-wrapper .pack-name {
    font-size: 20px;
  }
  .pricing-table-area-six .pr-table-wrapper .price {
    font-size: 40px;
  }
  .pricing-table-area-six .pr-table-wrapper .pack-details {
    font-size: 14px;
    padding-bottom: 20px;
  }
  .pricing-table-area-six .pr-table-wrapper {
    padding: 30px 15px;
  }
  .theme-footer-seven .title {
    color: #000;
    padding-bottom: 12px;
    font-size: 18px;
  }
  .theme-footer-seven .footer-list a {
    font-size: 16px;
  }
  .theme-footer-seven .bottom-footer {
    margin-top: 30px;
    padding: 20px 0 5px;
  }
  .theme-footer-seven .bottom-footer .footer-nav a {
    margin: 0 12px;
  }
  .theme-footer-seven .bottom-footer .social-icon a {
    margin: 0 8px;
  }
  .title-style-eight h2 {
    font-size: 42px;
  }
  .title-style-eight h2 br {
    display: none;
  }
  .title-style-eight p {
    font-size: 18px;
    padding-top: 25px;
  }
  .fancy-feature-eighteen {
    padding: 80px 15px;
  }
  .fancy-feature-eighteen .nav-item .nav-link {
    width: 60px;
    height: 60px;
    margin: 10px 12px;
  }
  .fancy-feature-eighteen .nav-item .hover {
    width: 67%;
  }
  .fancy-feature-eighteen .nav-tabs {
    margin: 50px 0 40px;
  }
  .fancy-feature-eighteen .tab-content .text-wrapper h4 {
    font-size: 24px;
    padding-bottom: 30px;
  }
  .fancy-feature-eighteen .tab-content .text-wrapper p {
    font-size: 18px;
  }
  .fancy-feature-eighteen .tab-content .text-wrapper {
    padding: 40px 20px;
  }
  .fancy-feature-twentyOne {
    padding: 100px 0;
  }
  .coming-soon-content h6 {
    font-size: 14px;
  }
  .coming-soon-content h1 {
    font-size: 45px;
    padding: 30px 0 40px;
  }
  .coming-soon-content p {
    font-size: 18px;
    padding-bottom: 40px;
  }
  .coming-soon-content form {
    height: 65px;
  }
  .coming-soon-content form button {
    width: 150px;
    font-size: 16px;
  }
  .coming-soon-content form input {
    padding: 0 165px 0 15px;
    font-size: 16px;
  }
  .coming-soon-content .social-icon {
    position: static;
    transform: none;
    margin-top: 50px;
  }
  .coming-soon-content .shape-ten {
    display: none;
  }
  .title-style-nine h2 {
    font-size: 35px;
    line-height: 1.45em;
  }
  .fancy-feature-twentyFour .bg-wrapper {
    padding: 100px 0 80px;
  }
  .team-details {
    padding: 80px 0 100px;
  }
  .team-details .main-bg .img-meta {
    width: 100%;
  }
  .team-details .main-bg .text-wrapper {
    width: 100%;
    padding: 50px 25px;
  }
  .team-details .pager {
    position: static;
    width: 100%;
    margin-top: 50px;
  }
  .team-details .main-bg .text-wrapper .name {
    font-size: 30px;
  }
  .terms_and_policy .nav-tabs {
    display: none !important;
  }
  .terms_and_policy .tab-content > .tab-pane {
    display: block;
    opacity: 1;
    margin-bottom: 60px;
  }
  .terms_and_policy {
    padding: 80px 0 0;
  }
  .terms_and_policy .tab-content h2 {
    font-size: 38px;
  }
  .terms_and_policy .tab-content h3 {
    font-size: 24px;
    padding: 40px 0 20px;
  }
  .error-page .img-holder {
    width: 100%;
    height: 100%;
  }
  .error-page .img-holder .illustration {
    min-height: 100%;
  }
  .error-page .text-wrapper {
    width: 100%;
    padding: 50px 15px;
    text-align: center;
  }
  .error-page .text-wrapper h1 {
    font-size: 40px;
  }
  .error-page .text-wrapper .back-home {
    margin: 0 auto;
  }
  .title-style-ten h2 {
    font-size: 40px;
  }
  .title-style-ten p {
    font-size: 20px;
    line-height: 1.6em;
  }
  .title-style-eleven h2 {
    font-size: 38px;
  }
  .partner-slider-two p.text-lg {
    font-size: 24px;
  }
  .title-style-twelve .upper-title {
    font-size: 14px;
  }
  .title-style-twelve h2 {
    font-size: 40px;
  }
  .title-style-twelve p {
    font-size: 18px;
  }
  .title-style-twelve span img {
    display: none;
  }
  .video-box-two .video-icon {
    width: 120px;
    height: 70px;
  }
  .video-box-two .video-icon img {
    width: 45px;
  }
  .fancy-text-block-twentyThree .text-wrapper .cl-text {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .useable-tools-section-four .logo-container .inner-wrapper {
    position: static;
    transform: none;
  }
  .useable-tools-section-four .text-wrapper p {
    padding: 25px 0 30px;
  }
  .useable-tools-section-four .text-wrapper {
    padding: 60px 0 70px;
  }
  .useable-tools-section-four .logo-container .line-two {
    transform: none;
  }
  .useable-tools-section-four .logo-container .icon {
    height: 150px;
    margin: 0 0 35px;
  }
  .pricing-section-eight .pr-column .price {
    font-size: 35px;
  }
  .pricing-section-eight .pr-body li {
    padding: 22px 0;
  }
  .pricing-section-eight .pr-footer {
    padding: 25px 0 20px;
  }
  .po-control-one button {
    font-size: 16px;
    line-height: 40px;
    padding: 0 18px;
    margin: 0 0.8% 10px;
  }
  .mixitUp-container.gutter-space-one,
  .mixitUp-container.gutter-space-two {
    margin: 0 -15px;
  }
  .mixitUp-container.gutter-space-one .mix,
  .mixitUp-container.gutter-space-two .mix {
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .portfolio-block-two .hover-content h3 a {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .portfolio-block-two .hover-content {
    padding: 10px 5px 12px 20px;
  }
  .fancy-portfolio-four .contact-title {
    font-size: 28px;
  }
  .portfolio_slider_one {
    margin: 0 -10px;
  }
  .portfolio_slider_one .item {
    padding: 0 10px;
  }
  .portfolio-block-three .text a {
    font-size: 24px;
  }
  .portfolio-block-three .text .tag {
    margin-bottom: 0;
  }
  .portfolio-block-three .img-holder {
    margin-bottom: 20px;
  }
  .fancy-portfolio-five .slider-wrapper {
    margin: 0 -50vw;
  }
  .po-control-two button {
    font-size: 18px;
  }
  .fancy-portfolio-six .scroll-target {
    width: 60px;
    height: 60px;
    padding: 13px;
  }
  .fancy-portfolio-six .mix {
    margin-bottom: 30px;
  }
  .portfolio-details-one .portfolio-pagination .tp1 {
    font-size: 13px;
    margin-bottom: 0;
  }
  .portfolio-details-one .portfolio-pagination .tp2 {
    font-size: 20px;
  }
  .title-style-thirteen h2 {
    font-size: 42px;
  }
  .title-style-thirteen .upper-title {
    font-size: 13px;
    padding-bottom: 10px;
  }
  .title-style-thirteen span img {
    display: none;
  }
  .fancy-feature-thirty .wrapper {
    padding: 40px 0 90px;
  }
  .title-style-four .sub-text {
    font-size: 18px;
  }
  .fancy-feature-thirtyThree .contact-title {
    font-size: 30px;
  }
  .title-style-eleven p {
    font-size: 18px;
  }
  .title-style-eleven .upper-title,
  .title-style-fourteen .upper-title {
    font-size: 12px;
    padding-bottom: 2px;
  }
  .title-style-fourteen h2 {
    font-size: 35px;
  }
  .fancy-feature-thirtyFive .slider-wrapper {
    width: 100%;
    position: relative;
    right: auto;
    margin: 0 auto;
    padding: 0 15px;
  }
  .portfolio_slider_two {
    margin: 0 -12px;
  }
  .portfolio_slider_two .item {
    margin: 0 12px;
  }
  .fancy-feature-thirtySix .slider-arrows {
    position: static;
    margin-top: 40px;
    width: 100%;
  }
  .fancy-feature-thirtySix .slider-arrows li {
    margin: 0 10px;
  }
  .title-style-fifteen .upper-title {
    font-size: 15px;
  }
  .title-style-fifteen h2 {
    font-size: 32px;
    line-height: 1.55em;
  }
  .fancy-feature-thirtySeven .text-lg {
    font-size: 18px;
    line-height: 2em;
  }
  .counter-box-six .number {
    font-size: 32px;
  }
  .counter-box-six p {
    font-size: 15px;
  }
  .fancy-feature-thirtyNine .title {
    font-size: 35px;
    letter-spacing: 0px;
    line-height: 1.4em;
  }
  .fancy-feature-thirtyNine .img-meta {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: 50px 0 0;
    max-width: 100%;
  }
  .fancy-feature-thirtyNine {
    padding: 100px 0 0;
  }
  .fancy-feature-thirtyNine .video-icon i {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 26px;
  }
  .fancy-feature-thirtyNine .video-icon .ps-text {
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
  .fancy-feature-thirtyNine .video-icon strong {
    font-size: 16px;
  }
  .fancy-feature-thirtyNine .img-meta:before {
    width: 500px;
    height: 500px;
  }
  .fancy-feature-thirtyNine .img-meta:after {
    width: 350px;
    height: 350px;
  }
  .title-style-sixteen .upper-title {
    font-size: 18px;
    padding-bottom: 15px;
  }
  .title-style-sixteen h2 {
    font-size: 35px;
    letter-spacing: 0px;
  }
  .fancy-feature-fortyOne p {
    font-size: 18px;
  }
  .vr-landing-slider .slick-next,
  .vr-landing-slider .slick-prev,
  .arrow-left-bottom .slick-next,
  .arrow-left-bottom .slick-prev {
    left: 50%;
    bottom: -20%;
  }
  .fancy-feature-thirtySix {
    padding-bottom: 120px !important;
  }
  .product_slider_space {
    margin: 0 -15px;
  }

  .product-block-one .price {
    font-size: 20px;
    margin-top: 5px;
  }
  .flash-sale-banner .left-half {
    height: 350px;
  }
  .flash-sale-banner .right-half:before {
    top: 10px;
    right: 10px;
    left: 10px;
    bottom: 0;
    border-bottom: none;
    border-left: 1px solid #000;
  }
  .flash-sale-banner .left-half:before {
    top: 0;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border-top: none;
    border-right: 1px solid #fff;
  }
  .product-block-two .price {
    font-size: 20px;
  }
  .product-block-two .img-holder {
    height: 300px;
    margin-bottom: 10px;
  }
  .shop-discount-subscription .form-wrapper {
    max-width: 100%;
    margin-top: 40px;
  }
  .product-details-one .product-img-tab .nav-link {
    width: 82px;
    margin: 20px 10px 0 0;
  }
}

/*(max-width: 991px)*/

@media (min-width: 768px) and (max-width: 991px) {
  .product-img-tab-content.h-100 {
    height: 700px !important;
  }
  .useable-tools-section-two .icon-wrapper ul li .icon-box {
    height: 70px;
  }
  .useable-tools-section-two .icon-wrapper ul li .icon-box img {
    max-width: 70%;
    margin: 0 auto;
  }
  .fancy-text-block-ten .container,
  .fancy-text-block-twentyOne .container {
    max-width: 720px;
  }
  .map-area-one {
    height: 600px;
  }
  .doc-container.full-width {
    padding-left: 15px;
  }
  .doc-sidebar {
    top: 80px;
  }
  #doc-sidebar-nav {
    padding-right: 15px;
  }
  #mega-menu-holder .mega-menu li {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .app-screen-preview-one {
    margin-top: 170px;
  }
  .app-screen-preview-one .round-bg {
    width: 74%;
  }
  .fancy-portfolio-two .mix,
  .fancy-portfolio-two .mix.mixL,
  .fancy-portfolio-four .mix {
    width: 50%;
  }
}
/*(min-width: 768px) and (max-width: 991px)*/

@media (max-width: 767px) {
  .fancy-feature-eight .bg-wrapper {
    padding: 0 15px 15px;
  }
  .pricing-section-three .pricing-table-area-three:before,
  .fancy-text-block-four:after,
  .fancy-text-block-four:before,
  .fancy-text-block-one:before,
  .fancy-text-block-one:after,
  .faqs-inner-page .shape-one,
  .title-style-four span img,
  .faq-classic.with-bg .shapes,
  .fancy-feature-eleven .shape-one,
  .fancy-feature-eleven .shape-two,
  .pricing-table-area-five .shape-two {
    display: none;
  }
  .fancy-video-box-one .video-button {
    width: 70px;
    height: 70px;
    padding-left: 12px;
  }
  .fancy-video-box-one .video-button img {
    width: 65%;
  }
  .useable-tools-section-two .icon-wrapper ul {
    transform: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .useable-tools-section-two .icon-wrapper ul li {
    width: 20%;
    margin: 0 !important;
    float: none;
  }
  .fancy-text-block-ten p {
    font-size: 26px;
    padding-bottom: 30px;
  }
  .contact-style-two .contact-info-wrapper .shape-one {
    display: none;
  }
  .accordion-style-three .card .card-body {
    padding-right: 0;
  }
  .map-area-one {
    height: 500px;
  }
  .map-area-one .si-content h3 {
    font-size: 28px;
  }
  .map-area-one .si-content {
    padding: 35px 15px;
  }
  .map-area-one .si-content {
    width: auto;
    left: 20px;
    height: auto;
    max-width: 250px;
    top: 20px;
  }
  .map-area-one .si-content p {
    font-size: 15px;
    padding: 4px 0 15px;
  }
  .map-area-one .si-content span {
    font-size: 15px;
  }
  .doc-sidebar {
    height: 100%;
    padding-top: 0 !important;
  }
  #doc-sidebar-nav {
    position: fixed;
    top: 79px;
    right: 0;
    height: 100%;
    background: #fff;
    width: 280px;
    border-left: 1px solid #e1e6ee;
    padding: 30px 15px 10px;
    max-height: calc(100vh - 79px);
  }
  .doc-sidebar {
    border: none;
    background: #f5f5f5;
    top: 78px;
    padding: 10px 0 !important;
    z-index: 9;
  }
  .doc-container.full-width {
    padding: 0;
  }
  .doc-sidebar .btn {
    color: #fff;
    background: #2a2a2a;
    margin: 0 15px;
    padding: 2px 5px;
  }
  .doc-container.full-width .doc-main-body {
    padding: 50px 15px 30px;
  }
  .doc-container .doc-main-body {
    padding-left: 0;
    padding-top: 50px;
  }
  .blog-v4 .post-meta.text-post .post {
    padding: 35px 15px;
  }
  .partner-slider-two p {
    font-size: 18px;
    padding-bottom: 30px;
  }
  .counter-box-four .number {
    font-size: 40px;
  }
  .counter-box-four p {
    font-size: 16px;
  }
  .pricing-nav-three .nav-item .nav-link {
    width: 130px;
    line-height: 48px;
    font-size: 16px;
  }
  .pricing-table-area-four .pr-table-wrapper {
    padding: 25px 15px;
  }
  .pricing-table-area-four .row {
    margin: 0 -15px;
  }
  .pricing-table-area-four .row [class*="col-"] {
    padding: 0 15px;
  }
  .pricing-table-area-four .pr-table-wrapper .top-banner {
    padding: 5px 10px;
    text-align: center;
  }
  .pricing-table-area-four .pr-table-wrapper .price {
    padding: 25px 0 5px;
  }
  .modal-contact-popup-one .main-body .left-side,
  .modal-contact-popup-one .main-body .right-side {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .modal-contact-popup-one .main-body {
    display: block !important;
  }
  .fancy-feature-thirteen .carousel-indicators li {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    margin-top: 40px;
  }
  .fancy-feature-thirteen .carousel-inner {
    margin-top: 60px;
  }
  .fancy-feature-fourteen .logo-meta {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .pricing-table-area-five .shape-one {
    top: 0;
  }
  .fancy-feature-fifteen .bg-wrapper:before {
    display: none;
  }
  .fancy-feature-fifteen .carousel-indicators li p {
    font-size: 16px;
  }
  .pricing-section-eight .pr-column {
    border: 1px solid #ededed;
    border-radius: 15px;
    margin-bottom: 40px;
  }
  .pricing-section-eight .pr-table-wrapper {
    border: none;
  }
  .pricing-section-eight .pr-column:last-child {
    border-right: 1px solid #ededed;
  }
  .accordion-style-five .card .card-header button {
    font-size: 18px;
    padding: 22px 30px 22px 0;
  }
  .accordion-style-five .card .card-body {
    padding-right: 30px;
  }
  .fancy-portfolio-three .mix {
    width: 100%;
  }
  .portfolio-details-one .main-content .project-info li {
    width: 48%;
    float: left;
    padding: 0 1%;
  }
  .portfolio-details-one .main-content .project-info strong {
    font-size: 15px;
  }
  .portfolio-details-one .main-content .project-info span {
    font-size: 15px;
    margin: 0 0 20px;
    letter-spacing: 0px;
  }
  .portfolio-details-one .main-content .project-info {
    margin-bottom: 35px;
  }
  .fancy-video-box-two .video-button {
    width: 65px;
    height: 65px;
  }
  .pricing-section-nine .pr-table-wrapper .price {
    padding: 0 0 6px;
  }
  .pricing-section-nine .pr-table-wrapper .pr-feature li {
    font-size: 18px;
    margin: 0;
  }
  .pricing-section-nine .pr-table-wrapper .trial-button {
    margin: 20px 0 25px;
    line-height: 48px;
    font-size: 15px;
  }
}
/*(max-width: 767px)*/

@media (min-width: 768px) {
  #doc-sidebar-nav {
    display: block !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .fancy-text-block-ten .container,
  .fancy-text-block-twentyOne .container {
    max-width: 540px;
  }
  #mega-menu-holder .mega-menu li {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .app-screen-preview-one {
    margin-top: 150px;
  }
  .app-screen-preview-one .round-bg {
    width: 80%;
  }
  .fancy-portfolio-two .mix,
  .fancy-portfolio-two .mix.mixL,
  .fancy-portfolio-four .mix {
    width: 50%;
  }
}
/*(min-width: 576px) and (max-width: 767px)*/

@media (max-width: 575px) {
  .accordion-style-four .card .card-header button {
    padding-left: 15px;
    font-size: 20px;
  }
  .accordion-style-four .card .card-body {
    padding: 0 40px 20px 15px;
  }
  .fancy-text-block-twenty .nav-tabs li {
    margin: 20px 0 0;
  }
  .fancy-text-block-twenty .nav-tabs li a {
    font-size: 20px;
  }
  .fancy-text-block-twenty .nav-tabs {
    justify-content: space-between;
  }
  .fancy-text-block-twenty .tab-pane p {
    padding-top: 20px;
    font-size: 18px;
  }
  .fancy-text-block-twenty .shape-one,
  .title-style-two span img,
  .title-style-three span img,
  .fancy-text-block-six .img-meta-container .shape-five,
  .title-style-two h2 br,
  .fancy-feature-five .shape-five,
  .pricing-section-one .pricing-table-area .shape-two,
  .pricing-table-area-four .pr-table-wrapper.most-popular:after,
  .title-style-five h2 span:before,
  .fancy-feature-fifteen .bg-wrapper .shapes {
    display: none;
  }
  .counter-box-three .icon img {
    width: 70%;
    margin: 0 auto;
  }
  .counter-box-three .icon {
    height: 45px;
  }
  .user-data-form .input-group-meta {
    height: 45px;
  }
  .user-data-form .input-group-meta .placeholder_icon {
    line-height: 45px;
  }
  .user-data-form .input-group-meta input {
    font-size: 16px;
  }
  .user-data-form .input-group-meta label {
    top: -23px;
  }
  .user-data-form .input-group-meta .placeholder_icon span:before {
    top: 10px;
  }
  .useable-tools-section h2 {
    font-size: 32px;
    line-height: 1.35em;
  }
  .counter-box-one.color-one {
    left: 0;
    width: 180px;
    height: 180px;
  }
  .counter-box-one.color-two {
    left: 140px;
    top: 90px;
  }
  .counter-box-one.color-three {
    left: 70px;
    width: 180px;
    height: 180px;
  }
  .faq-classic .card .card-header button {
    font-size: 20px;
    padding-left: 45px;
  }
  .faq-classic .card .card-header button:before {
    left: 18px;
  }
  .faq-classic .card .card-body {
    padding: 0 25px 20px 45px;
  }
  .faqs-all-qus .article-preview h3 {
    font-size: 22px;
  }
  .faqs-all-qus .article-preview .article-details .list-meta {
    padding-left: 15px;
  }
  .blog-sidebar-one .sidebar-title {
    font-size: 26px;
  }
  .blog-sidebar-one .sidebar-recent-news .title {
    font-size: 22px;
  }
  .fancy-text-block-six .img-meta-container {
    max-width: 90%;
  }
  .fancy-text-block-seven .quote-wrapper blockquote {
    font-size: 28px;
  }
  .useable-tools-section-two .icon-wrapper ul li .icon-box {
    height: 70px;
  }
  .useable-tools-section-two .icon-wrapper ul li .icon-box img {
    max-width: 70%;
    margin: 0 auto;
  }
  .fancy-short-banner-three .form-wrapper form button {
    width: 115px;
  }
  .fancy-short-banner-three .form-wrapper form input {
    padding: 0 125px 0 15px;
  }
  .fancy-text-block-thirteen .text-wrapper p {
    font-size: 18px;
  }
  .fancy-text-block-fourteen .img-holder {
    padding: 0 15px;
  }
  .fancy-hero-two .heading {
    font-size: 40px;
    padding-top: 8px;
  }
  .pricing-nav-two .nav-item .nav-link {
    width: 130px;
  }
  .fancy-text-block-seventeen .text-meta {
    font-size: 18px;
    line-height: 1.7em;
  }
  .teamSliderOne {
    margin-right: 0;
  }
  .useable-tools-section h6 {
    padding-bottom: 10px;
  }
  .doc-sidebar .list-item li h4 {
    font-size: 18px;
  }
  .doc-sidebar .list-item > li {
    padding: 5px 0 0;
  }
  .doc-pagination ul li span {
    display: none;
  }
  .pricing-nav-one .nav-item .nav-link {
    width: 130px;
    line-height: 45px;
  }
  .blog-details-post-v1 .comment-area,
  .blog-details-post-v1 .comment-form-section {
    padding: 40px 15px;
  }
  .blog-details-post-v1 .comment-area .reply {
    position: static;
    margin-top: 10px;
  }
  .blog-details-post-v1 .comment-form-section .title,
  .blog-details-post-v1 .comment-area .title {
    font-size: 30px;
  }
  #mega-menu-holder .mega-menu li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #mega-menu-holder .mega-menu li .img-box .hover {
    font-size: 18px;
  }
  .theme-menu-five .right-widget .demo-button {
    width: 50px;
    padding: 7px 0;
    display: block;
  }
  .theme-menu-five .right-widget .demo-button img {
    display: block;
  }
  .theme-menu-five .right-widget .demo-button span {
    display: none;
  }
  .pricing-nav-three {
    margin-bottom: 20px;
  }
  .modal-contact-popup-one .main-body .left-side {
    display: none !important;
  }
  .fancy-feature-thirteen .carousel-inner .shape-two {
    left: -45px;
  }
  .pricing-table-area-five .pr-table-wrapper .popular-badge {
    left: 10px;
  }
  .hero-banner-seven .illustration-container {
    max-width: 100%;
  }
  .hero-banner-seven form button {
    width: 130px;
    font-size: 14px;
  }
  .hero-banner-seven form input {
    padding: 0 150px 0 15px;
  }
  .fancy-feature-fifteen .carousel-indicators {
    flex-direction: column;
  }
  .fancy-feature-fifteen .carousel-indicators li {
    margin-bottom: 15px;
  }
  .fancy-feature-fifteen .carousel-indicators li:before {
    display: none;
  }
  .block-style-eighteen .screen-holder-one {
    width: 100%;
  }
  .block-style-eighteen .screen-holder-one .logo-two,
  .block-style-eighteen .screen-holder-one .logo-three {
    left: 0;
  }
  .block-style-eighteen .screen-holder-one .logo-five,
  .block-style-eighteen .screen-holder-two .screen-one {
    right: 0;
  }
  .block-style-eighteen .screen-holder-three {
    width: auto;
    height: auto;
    padding: 50px 15px;
  }
  .theme-menu-five .right-widget .signup-btn {
    display: none;
  }
  .fancy-feature-eighteen .tab-content .img-holder .ripple-box {
    width: 80%;
    height: 70%;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.2);
  }

  .app-preview-slider-one .img-holder img {
    transform: scale(1);
  }
  .app-preview-slider-one .item {
    margin: 0 15px;
  }
  .app-preview-slider-one {
    margin: 0 -15px;
  }
  .app-screen-preview-one {
    margin-top: 100px;
  }

  .fancy-portfolio-two .mix,
  .fancy-portfolio-two .mix.mixL,
  .fancy-portfolio-four .mix,
  .portfolio-details-one .main-content .project-info li {
    width: 100%;
  }
  .fancy-portfolio-five .slider-wrapper {
    margin: 0 -70vw;
  }
  .fancy-portfolio-five .slider-arrows li {
    margin: 25px 5px 0;
    width: 45px;
    height: 45px;
  }
  .title-style-thirteen h2 {
    font-size: 38px;
  }
  .main-sidebar-nav {
    width: 95%;
    max-width: 320px;
  }
  .product-details-one .product-review-tab .nav-tabs .nav-link {
    margin: 0;
    width: 100%;
    padding: 10px 0;
  }
  .product-details-one .product-review-tab .nav-tabs .nav-item {
    width: 100%;
  }
  .breadcrumb-style-one .breadcrumb-item a,
  .breadcrumb-style-one .breadcrumb-item {
    font-size: 15px;
  }
  .cart-section .cart-footer .cart-total-section {
    text-align: left;
  }
  .cart-section .cart-footer .cart-total-table tr td {
    text-align: right;
  }
  .checkout-form .order-confirm-sheet .order-review {
    padding: 40px 20px;
  }
}
/*(max-width: 575px)*/

@media (max-width: 500px) {
  .useable-tools-section-two .icon-wrapper ul li {
    width: 30%;
  }
  br {
    display: none;
  }
}

@media (max-width: 430px) {
  .theme-main-menu .logo {
    max-width: 150px;
    margin: 0;
  }
}
