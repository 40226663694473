/* CSS Document */

/*** 

====================================================================
  Loading Transition
====================================================================

 ***/
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #fff;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}
/* Spinner cargando */
.ctn-preloader .animation-preloader .spinner {
  animation: spinner 1.5s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(101, 92, 151, 0.06%);
  border-top-color: #655c97;
  border-bottom-color: #655c97;
  height: 170px;
  margin: 0 auto 45px auto;
  width: 170px;
}
/* Texto cargando */
.ctn-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #655c97;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 70px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  color: rgba(101, 92, 151, 0.14%);
  position: relative;
  font-size: 70px;
  line-height: 70px;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.ctn-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
/* Animación del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}
/*==================== Click Top ====================*/
.scroll-top {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: fixed;
  bottom: 20px;
  right: 5px;
  z-index: 99;
  text-align: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: #000;
  transition: all 0.3s ease-in-out;
  img {
    margin: auto;
  }
}
.scroll-top:after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}
/*------------- Global Prefix -------------------*/
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: var(--blue-dark);
  border-radius: 0;
}
:root {
  --text-color: #73737b;
  --text-light: #888888;
  --heading: #101621;
  --blue-dark: #6f55ff;
  --red-light: #fd6a5e;
  --yellow-deep: #ffb840;
  --blue-light: #3bb0d7;
  --purple-blue: #655c97;
  --vin-red: #973c56;
  scroll-behavior: smooth;
}
::selection {
  background-color: var(--blue-dark);
  color: #fff;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 18px;
  position: relative;
  line-height: 1.7em;
  color: var(--text-color);
}
/*______________________ Global style ___________________*/
.main-page-wrapper {
  overflow: hidden;
  padding-top: 40px;
  @media screen and (max-width: 1200px) {
    padding-top: 80px;
  }
}




.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  color: var(--heading);
  font-weight: normal;
}
.h1,
h1 {
  font-size: 80px;
  line-height: 1.1em;
}
.h2,
h2 {
  font-size: 52px;
  line-height: 1.1em;
}
.h3,
h3 {
  font-size: 36px;
}
.h4,
h4 {
  font-size: 22px;
}
.h5,
h5 {
  font-size: 20px;
}
.h6,
h6 {
  font-size: 15px;
}
.font-rubik {
  font-family: "Rubik", sans-serif !important;
}
.font-gilroy-bold {
  font-family: "gilroy-bold" !important;
}
.font-slab {
  font-family: "Roboto Slab", serif !important;
}
.font-gordita {
  font-family: "gordita" !important;
}
.font-recoleta {
  font-family: "Recoleta" !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #acaeb3;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #acaeb3;
}
::placeholder {
  color: #acaeb3;
}
.cs-screen {
  position: absolute;
}
.illustration-holder {
  position: relative;
}
.shapes {
  position: absolute;
  z-index: -1;
}
.dark-style {
  background: #0f1123;
  color: #afafaf;
}
.dark-style .h1,
.dark-style h1,
.dark-style .h2,
.dark-style h2,
.dark-style .h3,
.dark-style h3,
.dark-style .h4,
.dark-style h4,
.dark-style .h5,
.dark-style h5,
.dark-style .h6,
.dark-style h6 {
  color: #fff;
}
.gr-bg-one {
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
}
.hover-reverse-gr-bg-one {
  position: relative;
  z-index: 1;
}
.hover-reverse-gr-bg-one:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(270.01deg, #f548a0 0.01%, #f57c35 100%);
  border-radius: 6px;
  transition: all 0.6s ease-in-out;
  opacity: 0;
  z-index: -1;
}
.hover-reverse-gr-bg-one:hover:before {
  opacity: 1;
}
.top-border {
  border-top: 1px solid #efefef;
}
.bottom-border {
  border-bottom: 1px solid #efefef;
}
/*-------------- Prefix Classes ----------------*/
.tran3s {
  transition: all 0.3s ease-in-out;
}
.tran4s {
  transition: all 0.4s ease-in-out;
}
.tran5s {
  transition: all 0.5s ease-in-out;
}
.tran6s {
  transition: all 0.6s ease-in-out;
}
/*^^^^^^^^^^^^^^^^^^^^^ Section Title ^^^^^^^^^^^^^^^^^^^^^^^^^*/
.title-style-one h2 {
  font-family: "gilroy-black";
  font-size: 50px;
  line-height: 1.15em;
}
.title-style-one h6 {
  font-size: 20px;
  color: #bababa;
  padding-bottom: 15px;
}
.title-style-one p {
  font-size: 22px;
  line-height: 1.58em;
  color: #2a2a2a;
  padding-top: 25px;
}
.title-style-two p {
  text-transform: uppercase;
  font-size: 16px;
  color: #8a8a8a;
  letter-spacing: 1.12px;
  padding-bottom: 7px;
}
.title-style-two h2 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.15em;
}
.title-style-two span {
  position: relative;
}
.title-style-two span img {
  position: absolute;
  bottom: -30px;
  left: 0;
  z-index: -1;
}
.title-style-two .sub-text {
  font-size: 18px;
  line-height: 1.5em;
  color: #6d6d6d;
  padding-top: 15px;
}
.title-style-three p {
  text-transform: uppercase;
  font-size: 20px;
  color: #adb1b5;
  letter-spacing: 1.12px;
  padding-bottom: 17px;
}
.title-style-three h6 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #adb1b5;
  padding-bottom: 15px;
}
.title-style-three h2 {
  font-family: "gilroy-black";
  font-size: 54px;
  line-height: 1.18em;
}
.title-style-three span {
  position: relative;
}
.title-style-three span img {
  position: absolute;
  bottom: -21px;
  left: 0;
  z-index: -1;
}
.title-style-four h6 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.55);
  padding-bottom: 15px;
}
.title-style-four h2 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.15em;
  color: #2a2a2a;
}
.title-style-four span {
  position: relative;
  z-index: 1;
}
.title-style-four span img {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.title-style-five h2 {
  font-family: "Roboto Slab", serif;
  font-size: 54px;
  line-height: 1.25em;
}
.title-style-five h2 span {
  position: relative;
  z-index: 1;
}
.title-style-five h2 span:before {
  content: "";
  width: 100%;
  height: 15px;
  position: absolute;
  background: rgba(241, 193, 83, 0.45);
  left: 0;
  bottom: 12px;
  z-index: -1;
}
.title-style-five h6 {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.55);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 12px;
}
.title-style-six h2 {
  font-family: "Recoleta";
  font-weight: normal;
  font-size: 72px;
  line-height: 1.27em;
  color: #000;
}
.dark-style .title-style-six h2 {
  color: #fff;
}
.title-style-six h2 span {
  color: #ff2759;
  text-decoration: underline;
  text-decoration-thickness: 4px;
}
.title-style-six h6 {
  font-size: 18px;
  text-transform: uppercase;
  color: #8a8a8a;
  padding-bottom: 20px;
  letter-spacing: 1.8px;
}
.title-style-six .text-xs {
  font-size: 22px;
  line-height: 1.65em;
  padding-top: 30px;
}
.dark-style .title-style-six .text-xs {
  color: rgba(255, 255, 255, 0.54);
}
.title-style-seven h2 {
  font-size: 64px;
  font-weight: 500;
  line-height: 1.32em;
}
.title-style-seven h2 span {
  position: relative;
  display: inline-block;
}
.title-style-seven h2 span:before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 10px;
  z-index: -1;
}
.title-style-seven p {
  font-size: 22px;
  color: #000;
  padding-top: 22px;
}
.title-style-eight h2 {
  font-size: 68px;
  font-weight: 700;
  line-height: 1.26em;
}
.title-style-eight h2 span {
  display: inline-block;
  position: relative;
}
.title-style-eight h2 span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.title-style-eight p {
  font-size: 22px;
  color: #000;
  padding-top: 40px;
}
.title-style-eight h6 {
  font-size: 16px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.63);
  padding-bottom: 20px;
}
.title-style-nine h6 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #8a8a8a;
  padding-bottom: 25px;
}
.title-style-nine h2 {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.34em;
}
.title-style-nine h2 span {
  display: inline-block;
  position: relative;
}
.title-style-nine h2 span img {
  position: absolute;
  max-height: 100%;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.title-style-nine p {
  font-size: 20px;
  color: #6a6b72;
  padding-top: 28px;
}
.title-style-ten h2 {
  font-size: 68px;
  font-weight: 500;
  line-height: 1.27em;
}
.title-style-ten p {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
}
.title-style-eleven h2 {
  font-size: 58px;
  font-weight: 500;
  line-height: 1.29em;
}
.title-style-ten h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.53);
  padding-bottom: 20px;
}
.title-style-twelve .upper-title {
  text-transform: uppercase;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 3px;
  padding-bottom: 8px;
}
.title-style-twelve h2 {
  font-weight: 700;
  font-size: 58px;
  line-height: 1.29em;
}
.title-style-twelve span {
  position: relative;
}
.title-style-twelve span img {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.title-style-twelve p {
  font-size: 24px;
  line-height: 1.75em;
}
.title-style-thirteen .upper-title {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.2);
  letter-spacing: 3px;
  padding-bottom: 16px;
}
.title-style-thirteen h2 {
  font-family: "gordita";
  font-weight: 700;
  font-size: 72px;
  line-height: 1.25em;
  letter-spacing: -1px;
}
.title-style-thirteen span {
  position: relative;
}
.title-style-thirteen span img {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.title-style-fourteen h2 {
  font-size: 52px;
  font-weight: 500;
  line-height: 1.25em;
  letter-spacing: -1px;
}
.title-style-fourteen .upper-title {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  letter-spacing: 2px;
  padding-bottom: 10px;
}
.title-style-fifteen h2 {
  font-size: 64px;
  font-weight: 500;
  line-height: 1.4em;
  text-transform: uppercase;
}
.title-style-fifteen .upper-title {
  text-transform: uppercase;
  font-size: 18px;
  color: #b6b6b6;
  letter-spacing: 3px;
  padding-bottom: 18px;
}
.title-style-sixteen h2 {
  font-size: 58px;
  font-weight: 500;
  line-height: 1.29em;
  letter-spacing: -1px;
  text-transform: uppercase;
}
.title-style-sixteen .upper-title {
  font-size: 20px;
  color: #bababa;
  padding-bottom: 25px;
}
/*^^^^^^^^^^^^^^^^^^^^^ Theme Button ^^^^^^^^^^^^^^^^^^^^^^^^^*/
body .theme-btn-one {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 50px;
  background: var(--blue-dark);
  padding: 0 42px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-one.btn-lg {
  padding: 0 50px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: 1.14px;
}
body .theme-btn-one:hover {
  background: #ffba12;
}
body .theme-btn-two {
  font-family: "gilroy-semibold";
  font-size: 18px;
  color: #fff;
  line-height: 48px;
  border: 2px solid var(--red-light);
  background: var(--red-light);
  padding: 0 42px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-two:hover {
  background: transparent;
  color: var(--red-light);
}
body .theme-btn-three {
  font-family: "gilroy-semibold";
  font-size: 18px;
  color: var(--red-light);
  line-height: 51px;
  border-radius: 30px;
  border: 2px solid var(--red-light);
  padding: 0 32px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-three:hover {
  background: var(--red-light);
  color: #fff;
}
body .theme-btn-four {
  font-size: 17px;
  color: var(--blue-dark);
  border: 2px solid var(--blue-dark);
  border-radius: 5px;
  line-height: 46px;
  padding: 0 35px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-four:hover {
  background: var(--blue-dark);
  color: #fff;
}
body .theme-btn-five {
  font-family: "Rubik", sans-serif;
  line-height: 56px;
  padding: 0 35px;
  text-align: center;
  color: #fff;
  background: var(--purple-blue);
  position: relative;
  z-index: 1;
}
body .theme-btn-five:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--vin-red);
  z-index: -1;
  transition: all 0.3s ease-in-out;
  transform: scale(0, 1);
  transform-origin: 0 100%;
}
body .theme-btn-five:hover:before {
  transform: scale(1, 1);
}
body .theme-btn-six {
  font-size: 16px;
  font-weight: 500;
  line-height: 50px;
  color: #000;
  transition: all 0.3s ease-in-out;
  background: #000;
  padding: 0 30px;
  color: #fff;
}
body .theme-btn-six.lg {
  padding: 0 45px;
}
body .theme-btn-six:hover {
  background: var(--blue-light);
  color: #fff;
}
body .theme-btn-seven {
  font-weight: 500;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border-radius: 6px;
  padding: 0 45px;
  background: #000;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-seven:hover {
  background: #ff2759;
}
body .theme-btn-eight {
  font-weight: 500;
  font-size: 16px;
  line-height: 58px;
  padding: 0 40px;
  border-radius: 5px;
  background: #2d2d2d;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-eight:hover {
  background: #ffeaa0;
  color: #212121;
}
body .theme-btn-nine {
  line-height: 58px;
  border-radius: 50px;
  color: #fff;
  padding: 0 34px;
  text-align: center;
  background: #262626;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-nine:hover {
  background: #eb5e2a;
}
body .theme-btn-ten {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.5px;
  color: #000;
  position: relative;
  padding-bottom: 5px;
}
body .theme-btn-ten .fa {
  font-size: 14px;
  margin-left: 5px;
}
body .theme-btn-ten:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000;
  left: 0;
  bottom: 0;
}
body .theme-btn-eleven {
  font-family: "gordita";
  font-weight: 500;
  font-size: 18px;
  line-height: 48px;
  border: 2px solid #000;
  border-radius: 30px;
  color: #000;
  padding: 0 40px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-eleven:hover {
  background: #000;
  color: #fff;
}
body .theme-btn-twelve {
  font-family: "gordita";
  font-size: 17px;
  line-height: 48px;
  background: #ff006b;
  border: 2px solid #ff006b;
  border-radius: 30px;
  color: #fff;
  padding: 0 32px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-twelve:hover {
  background: transparent;
  color: #ff006b;
}
body .theme-btn-thirteen {
  font-family: "gordita";
  font-size: 17px;
  line-height: 50px;
  background: #232323;
  border-radius: 30px;
  color: #fff;
  padding: 0 45px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-thirteen:hover {
  background: #ff006b;
}
body .theme-btn-fourteen {
  font-family: "gordita";
  font-size: 17px;
  line-height: 54px;
  background: #000;
  border-radius: 7px;
  color: #fff;
  letter-spacing: -0.3px;
  padding: 0 33px;
  transition: all 0.3s ease-in-out;
}
body .theme-btn-fourteen:hover {
  background: #6f6cff;
}
body .shop-btn-one {
  width: 70px;
  height: 70px;
  background: #000;
}
body .shop-btn-one:hover {
  background: var(--purple-blue);
}
body .shop-btn-two {
  font-size: 15px;
  line-height: 50px;
  text-transform: uppercase;
  color: #fff;
  background: #161616;
  padding: 0 36px;
}
body .shop-btn-two:hover {
  background: var(--purple-blue);
}
/*-------------------- Pagination ------------------*/
.page-pagination-one li a {
  font-family: "Rubik", sans-serif !important;
  background: #fff;
  width: 40px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 17px;
  color: #515151;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  transition: all 0.3s ease-in-out;
}
.page-pagination-one li a .fa {
  font-size: 22px;
}
.page-pagination-one li:first-child a {
  border-radius: 5px 0 0 5px;
}
.page-pagination-one li:last-child a {
  border-radius: 0 5px 5px 0;
}
.page-pagination-one li a:hover,
.page-pagination-one li a.active {
  background: var(--blue-dark);
  color: #fff;
}
.blog-v3 .page-pagination-one li a:hover,
.blog-v3 .page-pagination-one li a.active {
  background: var(--red-light);
  color: #fff;
}
.page-pagination-two ul li {
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  color: #bababa;
  margin: 0 11px;
}
.page-pagination-two ul li:first-child a {
  transform: rotate(180deg);
}
.page-pagination-two ul li:hover a,
.page-pagination-two ul li.active a {
  color: #000;
}
.page-pagination-two ul li.arrow {
  font-size: 35px;
  color: #000;
}
.page-pagination-two ul li:nth-child(7) a {
  color: #000;
}
/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 30px 70px;
  transition: all 0.4s ease-out;
}
.theme-main-menu.bg-none {
  background: transparent;
}
.theme-main-menu.sticky-menu.fixed {
  z-index: 9999;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}
.theme-main-menu .logo a {
  display: block;
}
/*------------------ Fancy Feature One --------------*/
.fancy-feature-one {
  // background-image: url(../images/assets/dot-bg-01.svg);
  background-repeat: no-repeat;
  background-position: center 230px;
  background-size: cover;
  position: relative;
  z-index: 5;
}
.fancy-feature-one .feature-img-area {
  display: inline-block;
  position: relative;
  z-index: 5;
  padding-right: 125px;
}
.fancy-feature-one .feature-img-area:before {
  content: "";
  position: absolute;
  left: 35px;
  right: 160px;
  background: #fff;
  height: 200px;
  top: -25px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.04);
  z-index: -1;
}
.fancy-feature-one .feature-img-area:after {
  content: "";
  position: absolute;
  left: 80px;
  right: 205px;
  background: #fff;
  height: 200px;
  top: -50px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px -3px 21px rgba(0, 0, 0, 0.04);
  z-index: -2;
}
.fancy-feature-one .feature-img-area .screen-one {
  width: 51%;
  right: -13%;
  bottom: -46%;
  z-index: 1;
  animation: jumpTwo 3s infinite linear;
}
.fancy-feature-one .feature-img-area .screen-two {
  width: 38%;
  right: 0;
  top: 33%;
}
.fancy-feature-one .feature-img-area .screen-three {
  width: 32%;
  left: 21.5%;
  top: 47%;
}
.fancy-feature-one .feature-img-area .screen-four {
  width: 32%;
  left: 35%;
  top: 61%;
}
.fancy-feature-one .feature-img-area .screen-five {
  width: 25%;
  right: 19.5%;
  bottom: 16%;
  z-index: 0;
}

/*------------------ Useable Tools  --------------*/
// .useable-tools-section.bg-shape {
//   position: relative;
//   z-index: 5;
//   background-image: url(../images/shape/7.svg);
//   background-repeat: no-repeat;
//   background-position: top center;
//   background-size: contain;
//   padding: 500px 0 350px;
// }
.useable-tools-section.bg-color {
  background: var(--blue-dark);
  padding: 130px 0 120px;
}
.useable-tools-section h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding-bottom: 15px;
  color: #fff;
}
.useable-tools-section.bg-transparent h6 {
  color: rgba(0, 0, 0, 0.63);
}
.useable-tools-section.bg-transparent h2 {
  color: #2a2a2a;
  margin: 0;
}
.useable-tools-section h2 {
  font-family: "gilroy-black";
  font-size: 58px;
  line-height: 1.2em;
  color: #fff;
  margin: 20px 0 120px;
}
.useable-tools-section .sub-text {
  text-align: center;
  font-size: 22px;
  line-height: 1.59em;
  color: #000;
}
.useable-tools-section .img-box a {
  display: block;
  height: 100%;
}
.useable-tools-section .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.useable-tools-section .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15.436px 30.294px 50px 0px rgba(103, 43, 246, 0.5);
  width: 175px;
  height: 175px;
  margin: 0 auto 25px;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 575px) {
    margin: 0 auto 15px;
  }
}
.useable-tools-section.bg-transparent .img-box {
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}
.useable-tools-section-three .logo-wrapper.brand-bg-white .logo {
  background: #ffffff !important;
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.0696193);
}
.useable-tools-section .img-box:hover {
  transform: scale(1.1);
}
.useable-tools-section .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-g {
  width: 197px;
  height: 197px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
}
.useable-tools-section [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.useable-tools-section [class*="bubble"]:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  background: #9a71ff;
}
.useable-tools-section .bubble-one {
  width: 120px;
  height: 120px;
  left: 43%;
  top: 20%;
  animation: rotated 14s infinite linear;
}
.useable-tools-section .bubble-one:before {
  width: 6px;
  height: 6px;
  top: 0;
  right: 50%;
}
.useable-tools-section .bubble-two {
  width: 140px;
  height: 140px;
  right: 20%;
  top: 14%;
  animation: rotatedTwo 14s infinite linear;
}
.useable-tools-section .bubble-two:before {
  width: 12px;
  height: 12px;
  top: 0;
  left: 50%;
}
.useable-tools-section .bubble-three {
  width: 95px;
  height: 95px;
  left: 10%;
  top: 44%;
  animation: rotated 14s infinite linear;
}
.useable-tools-section .bubble-three:before {
  width: 10px;
  height: 10px;
  top: 100%;
  left: 50%;
}
.useable-tools-section .bubble-four {
  width: 95px;
  height: 95px;
  right: 10%;
  top: 48%;
  animation: rotatedTwo 14s infinite linear;
}
.useable-tools-section .bubble-four:before {
  width: 8px;
  height: 8px;
  top: 0;
  left: 50%;
}
.useable-tools-section .bubble-five {
  width: 95px;
  height: 95px;
  right: 56%;
  bottom: 8%;
  animation: rotated 14s infinite linear;
}
.useable-tools-section .bubble-five:before {
  width: 8px;
  height: 8px;
  top: 0;
  left: 50%;
}
/*-------------------- Fancy Feature Three -------------------*/
.counter-info-classic {
  max-width: 445px;
  height: 442px;
  position: relative;
  z-index: 5;
  margin-top: 100px;
}
.counter-box-one {
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.counter-box-one.color-one {
  background: rgba(0, 166, 255, 0.65);
  width: 195px;
  height: 195px;
  top: 40px;
  left: 40px;
  z-index: -2;
}

.counter-box-one.color-two {
  background: rgba(140, 39, 255, 0.55);
  width: 154px;
  height: 154px;
  top: 136px;
  left: 248px;
  z-index: 2;
}
.counter-box-one.color-three {
  background: rgba(255, 173, 58, 0.72);
  width: 212px;
  height: 212px;
  top: 200px;
  left: 110px;
  z-index: 1;
}
.counter-box-one .number {
  color: #fff;
  font-size: 52px;
}
.counter-box-one p {
  font-size: 20px;
  color: #fff;
}
.counter-info-classic [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.counter-info-classic .bubble-one {
  width: 8px;
  height: 8px;
  background: #7dec72;
  top: 0;
  left: 62%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .bubble-two {
  width: 22px;
  height: 22px;
  background: #ffb56a;
  top: 15%;
  left: 63%;
  animation: scale-up-one 4s infinite linear;
}
.counter-info-classic .bubble-three {
  width: 8px;
  height: 8px;
  background: #88d9ff;
  top: 16%;
  right: 11%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .bubble-four {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  bottom: 24%;
  right: 13%;
  animation: scale-up-one 4s infinite linear;
}
.counter-info-classic .bubble-five {
  width: 8px;
  height: 8px;
  background: #77f4f5;
  bottom: 20%;
  right: 5%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .bubble-six {
  width: 26px;
  height: 26px;
  background: #a595ff;
  bottom: 32%;
  left: 0;
  animation: scale-up-one 4s infinite linear;
}
.counter-info-classic .bubble-seven {
  width: 9px;
  height: 9px;
  background: #66e19e;
  bottom: 40%;
  left: 14%;
  animation: scale-up-three 4s infinite linear;
}
.counter-info-classic .dot-shape {
  z-index: -5;
  top: 35px;
  right: 0;
}
/*----------------------- Faq Classic ----------------------*/
.faq-classic.with-bg {
  background: #f7faff;
  padding: 40px 0 40px;
  z-index: 5;
  position: relative;
}
.faq-classic.with-bg .shape-one {
  top: 13%;
  right: 4%;
}
.faq-classic.with-bg .shape-two {
  top: 36%;
  right: 11%;
}
.faq-classic.with-bg .shape-three {
  top: 63%;
  right: 0;
}
.faq-classic.with-bg .shape-four {
  top: 63%;
  left: 0%;
}
.faq-classic.with-bg .shape-five {
  top: 41%;
  left: 5%;
}
.faq-classic.with-bg .shape-six {
  top: 14%;
  left: 0;
}
.faq-classic .card {
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(90, 111, 155, 0.05);
  margin-bottom: 20px;
  border-radius: 0;
  border: none;
}
.faq-classic .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.faq-classic .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 20px 15px 20px 70px;
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.faq-classic .card .card-header button:before {
  content: "+";
  position: absolute;
  font-size: 24px;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}
.faq-classic .card .card-body {
  padding: 0 50px 20px 70px;
}
.faq-classic .card .card-body p {
  font-size: 17px;
}
.faq-classic .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}
.faq-classic .card:last-child .card-body {
  border: none;
}
/*------------------ Footer One --------------*/
.footer-bg-wrapper {
  position: relative;
  padding-top: 230px;
}
// .footer-bg-wrapper:before {
//   content: url(../images/shape/11.svg);
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   z-index: -1;
// }
.footer-bg-wrapper [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.footer-bg-wrapper .bubble-one {
  width: 25px;
  height: 25px;
  background: #ff6cc4;
  top: 4%;
  left: 26%;
  animation: scale-up-one 4s infinite linear;
}
.footer-bg-wrapper .bubble-two {
  width: 14px;
  height: 14px;
  background: #ffbe50;
  top: 48%;
  left: 10%;
  animation: scale-up-one 4s infinite linear;
}
.footer-bg-wrapper .bubble-three {
  width: 7px;
  height: 7px;
  background: #04e8f4;
  bottom: 18%;
  left: 20%;
  animation: scale-up-three 4s infinite linear;
}

/*------------------- Fancy Text block One ----------------*/
.fancy-text-block-one {
  position: relative;
  z-index: 3;
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 55px 0;
}
// .fancy-text-block-one:before,
// .fancy-text-block-one:after {
//   content: url(../images/shape/10.svg);
//   position: absolute;
//   opacity: 0.9;
// }
.fancy-text-block-one:before {
  top: -133px;
  left: 0;
  animation: jumpTwo 4s infinite linear;
}
.fancy-text-block-one:after {
  bottom: -133px;
  right: 0;
  animation: jumpThree 4s infinite linear;
}
.fancy-text-block-one .quote-wrapper {
  padding-left: 25px;
}
.fancy-text-block-one .quote-wrapper p {
  font-family: "gilroy-semibold";
  font-size: 20px;
  color: #000000;
  padding-bottom: 21px;
}
.fancy-text-block-one .quote-wrapper p span {
  color: #ffb147;
}
.fancy-text-block-one .quote-wrapper blockquote {
  font-size: 40px;
  line-height: 1.5em;
  color: #181818;
  position: relative;
  margin-bottom: 43px;
}
// .fancy-text-block-one .quote-wrapper blockquote:before {
//   content: url(../images/icon/06.svg);
//   position: absolute;
//   left: -92px;
//   top: 11px;
// }
.fancy-text-block-one .quote-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
}
.fancy-text-block-one .quote-wrapper h6 span {
  font-weight: normal;
  color: #9c9c9c;
  font-size: 18px;
}
.fancy-text-block-one [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-text-block-one .bubble-one {
  width: 17px;
  height: 17px;
  background: #ff6cc4;
  top: 15%;
  left: 42%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-one .bubble-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  top: 29%;
  right: 8%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-text-block-one .bubble-three {
  width: 20px;
  height: 20px;
  background: #ffba65;
  bottom: 7%;
  right: 26%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-one .bubble-four {
  width: 8px;
  height: 8px;
  background: #6aee90;
  bottom: 29%;
  left: 43%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-text-block-one .bubble-five {
  width: 25px;
  height: 25px;
  background: #b183ff;
  bottom: 7%;
  left: 9%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-one .bubble-six {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  top: 31%;
  left: 11%;
  animation: scale-up-three 4s infinite linear;
}
/*----------------- Counter Info Standard --------------*/
.counter-info-standard {
  border-bottom: 1px solid #efefef;
}
.counter-box-two {
  text-align: center;
  margin-bottom: 30px;
}
.counter-box-two .number {
  font-size: 80px;
  color: var(--blue-dark);
}
.counter-box-two em {
  font-size: 20px;
  display: block;
  color: #8e8e8e;
  font-style: normal;
}
.counter-box-two p {
  font-size: 28px;
  line-height: 1.35em;
  color: #212121;
  padding-top: 20px;
}
/*---------------- Fancy Text block Two -------------*/
.fancy-text-block-two .fancy_img_media {
  border-radius: 8px;
  margin-left: auto;
}
/*---------------- Fancy Text block Three -------------*/
.fancy-text-block-three {
  position: relative;
  z-index: 1;
  padding-bottom: 110px;
}
.fancy-text-block-three:before {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 16%;
  left: 0;
  background: #fafaff;
  z-index: -2;
}
.fancy-text-block-three .feature-content {
  padding-bottom: 60px;
}
.fancy-text-block-three .feature-content.light-bg {
  background: #5e43f0;
  border-radius: 5px 0 0 5px;
}
.fancy-text-block-three .feature-content.dark-bg {
  background: #462ec5;
  border-radius: 0 5px 5px 0;
}
.fancy-text-block-three .feature-content .header {
  padding: 65px 20px 55px 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.fancy-text-block-three .feature-content .header h3 {
  font-family: "gilroy-semibold";
  color: #fff;
  font-size: 50px;
}
.fancy-text-block-three .feature-content .header p {
  font-size: 20px;
  line-height: 1.5em;
  color: #fff;
  padding-top: 18px;
}
.fancy-text-block-three .feature-content .feature-list {
  padding: 50px 20px 0 55px;
}
.fancy-text-block-three .feature-content .feature-list .icon-box {
  width: 55px;
  height: 55px;
  background: #fff;
  border-radius: 50%;
}
.fancy-text-block-three .feature-content .feature-list .text-meta {
  padding-left: 20px;
  width: calc(100% - 55px);
}
.fancy-text-block-three .feature-content .feature-list h4 {
  font-family: "gilroy-bold";
  font-size: 22px;
  color: #fff;
}
.fancy-text-block-three .feature-content .feature-list p {
  color: rgba(255, 255, 255, 0.8);
  padding-top: 7px;
}
.fancy-text-block-three .slogan {
  text-align: center;
  font-size: 40px;
  line-height: 1.38em;
  padding-top: 80px;
  color: #000;
}
.fancy-text-block-three [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.fancy-text-block-three .bubble-one {
  width: 15px;
  height: 15px;
  background: #ff6cc4;
  top: 27%;
  right: 10%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-three .bubble-two {
  width: 7px;
  height: 7px;
  background: #f5a623;
  top: 70%;
  right: 10%;
  animation: scale-up-three 4s infinite linear;
}
.fancy-text-block-three .bubble-three {
  width: 20px;
  height: 20px;
  background: #b183ff;
  bottom: 16%;
  left: 9%;
  animation: scale-up-one 4s infinite linear;
}
.fancy-text-block-three .bubble-four {
  width: 8px;
  height: 8px;
  background: #6aee90;
  top: 40%;
  left: 8%;
  animation: scale-up-three 4s infinite linear;
}
/*---------------- Fancy Text block Four -------------*/
.fancy-text-block-four {
  background: #fcf7ef;
  position: relative;
  z-index: 5;
}
// .fancy-text-block-four:before {
//   content: url(../images/shape/13.svg);
//   position: absolute;
//   top: -48px;
//   left: 0;
//   animation: jumpTwo 4s infinite linear;
// }
// .fancy-text-block-four:after {
//   content: url(../images/shape/14.svg);
//   position: absolute;
//   bottom: -140px;
//   right: 0;
//   animation: jumpThree 4s infinite linear;
// }
.fancy-text-block-four .wrapper {
  position: relative;
  z-index: 1;
}
.fancy-text-block-four .wrapper:before {
  zoom: 50%;
  position: absolute;
  top: -319px;
  right: -364px;
  z-index: -1;
  animation: jumpTwo 10s infinite linear;
}
.block-style-three {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.02);
  padding: 25px 40px 40px 50px;
  margin-top: 35px;
}
.block-style-three .icon {
  height: 75px;
}
.block-style-three h4 {
  font-family: "gilroy-bold";
  font-size: 22px;
  padding: 25px 0 20px;
}
.block-style-three p {
  font-size: 17px;
  line-height: 1.64em;
}
/*---------------- Fancy Text block Five -------------*/
.fancy-text-block-five {
  position: relative;
  z-index: 5;
}
.fancy-text-block-five:before {
  // content: url(../images/assets/ils_03.svg);
  position: absolute;
  top: 150px;
  left: 7%;
  z-index: -1;
  animation: jumpTwo 10s infinite linear;
}

/*------------------- Contact Us Light --------------*/
.contact-us-light {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  position: relative;
  z-index: 1;
}
// .contact-us-light:before {
//   content: url(../images/shape/15.svg);
//   position: absolute;
//   left: 0;
//   bottom: -140px;
// }
.contact-us-light [class*="bubble"] {
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.contact-us-light .bubble-one {
  width: 30px;
  height: 30px;
  background: #ffdfdf;
  left: 39%;
  top: -15px;
  z-index: 2;
  animation: scale-up-one 4s infinite linear;
}
.contact-us-light .bubble-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  top: 14%;
  right: 8%;
  animation: scale-up-three 4s infinite linear;
}
.contact-us-light .bubble-three {
  width: 8px;
  height: 8px;
  background: #6aee90;
  top: 32%;
  left: 44%;
  animation: scale-up-three 4s infinite linear;
}
.contact-us-light .bubble-four {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  left: 11%;
  top: 15%;
  animation: scale-up-three 4s infinite linear;
}
.contact-us-light .address-info {
  text-align: center;
  margin-bottom: 50px;
}
.contact-us-light .address-info .icon {
  height: 82px;
}
.contact-us-light .address-info .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.contact-us-light .address-info .title {
  font-size: 18px;
  color: #8e8e8e;
  padding: 30px 0 5px;
}
.contact-us-light .address-info p {
  font-size: 24px;
  line-height: 1.45em;
  color: #000;
}
.contact-us-light .address-info ul li a {
  font-size: 22px;
  margin: 5px 15px;
  color: rgba(0, 0, 0, 0.52);
  transition: all 0.2s ease-in-out;
}
.contact-us-light .address-info ul li a:hover {
  color: var(--blue-dark);
}
.form-style-light {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 30px rgba(14, 49, 42, 0.05);
  padding: 100px 100px 95px;
  margin-top: 90px;
}
.form-style-light .input-group-meta {
  height: 55px;
  position: relative;
}
.form-style-light .input-group-meta.lg {
  height: 220px;
}
.form-style-light .input-group-meta input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 16px;
  border: solid 1px #d6d6d6;
  padding: 0 52px 0 20px;
  color: var(--heading);
  background: transparent;
  font-family: "Rubik", sans-serif;
}
.form-style-light .input-group-meta textarea {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 16px;
  color: var(--heading);
  border: solid 1px #d6d6d6;
  resize: none;
  padding: 20px;
  font-family: "Rubik", sans-serif;
}
.form-style-light .input-group-meta input:focus {
  border-color: var(--blue-dark);
}
.form-style-light .input-group-meta label {
  font-size: 16px;
  padding: 0 6px;
  font-weight: normal;
  position: absolute;
  left: 20px;
  line-height: 10px;
  top: -5px;
  z-index: 1;
  background: #fff;
}
.form-style-light .input-group-meta .placeholder_icon {
  position: absolute;
  line-height: 55px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  text-align: center;
  z-index: 1;
  color: rgba(0, 0, 0, 0.54);
  font-size: 17px;
  cursor: pointer;
}
.form-style-light .input-group-meta .placeholder_icon img {
  position: relative;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
}
.form-style-light .input-group-meta .valid-sign {
  opacity: 1;
  visibility: visible;
}
.form-style-light .input-group-meta input:valid + .valid-sign {
  opacity: 0;
  visibility: hidden;
}
#contact-form .form-group .help-block {
  position: absolute;
  left: 0;
  bottom: -24px;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  padding: 0 15px;
  border-radius: 3px;
  background: #dc3545;
  box-shadow: 0px 10px 25px 0px rgba(123, 147, 171, 0.15);
}
#contact-form .form-group .help-block li {
  position: relative;
}
#contact-form .form-group .help-block li:before {
  content: "";
  font-family: "font-awesome";
  position: absolute;
  top: -12px;
  left: 0;
  color: #dc3545;
}

/*=======================================================================
                              CUSTOMER SUPPORT                
=========================================================================*/
.theme-main-menu > div {
  position: relative;
}
/*---------------------- Fancy Feature Two -------------------*/
.fancy-feature-four {
  padding: 0 15px;
}
.fancy-feature-four .inner-content {
  position: relative;
}
.fancy-feature-four .bg-wrapper {
  max-width: 1460px;
  margin: 0 auto;
  position: relative;
  background: #fff6ec;
  z-index: 1;
  padding: 100px 0 190px;
}
// .fancy-feature-four .bg-wrapper:before {
//   content: "";
//   width: 100%;
//   height: 28px;
//   background: url(../images/shape/17.svg) no-repeat center;
//   background-size: cover;
//   position: absolute;
//   left: 0;
//   bottom: -25px;
// }

.fancy-feature-four .shape-right {
  right: -16px;
  top: 30%;
}
.fancy-feature-four .shape-left {
  left: -8px;
  top: 62%;
}
.fancy-feature-four .shape-one {
  top: -20px;
  left: -64px;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-four .shape-two {
  bottom: -85px;
  right: -104px;
  animation: rotated 50s infinite linear;
}
/*---------------------- Fancy Text block Six ---------------*/
.fancy-text-block-six {
  position: relative;
}
.fancy-text-block-six .illustration-holder .shape-one {
  right: -20%;
  top: 29%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-six .img-meta-container {
  position: absolute;
  right: 0;
  top: 23px;
  max-width: 42%;
}
.fancy-text-block-six .img-meta-container .shape-one {
  top: -7%;
  left: -4%;
  animation: rotated 18s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-two {
  top: -11%;
  left: 26%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-three {
  top: -6%;
  right: 31%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-four {
  top: 44%;
  left: -7%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-five {
  bottom: -15%;
  left: -10%;
  animation: rotated 50s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-six {
  bottom: -11%;
  left: 28%;
  animation: rotated 20s infinite linear;
}
.fancy-text-block-six .img-meta-container .shape-seven {
  bottom: -12%;
  left: 57%;
  animation: jumpTwo 5s infinite linear;
}
.accordion-style-two {
  .card {
    background: #fff;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    &:not(:last-child) {
      border-bottom: 2px solid #000 !important;
    }
  }
}
.accordion-style-two .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-two .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 22px 70px 22px 0;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  line-height: 1.4em;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.accordion-style-two .card .card-header button:before,
.accordion .card .card-header button:before {
  content: "";
  font-family: "font-awesome";
  position: absolute;
  font-size: 28px;
  right: 0;
  left: auto;
  top: 30%;
  transition: 0.3s ease-in-out;
}
/*.accordion-style-two .card .card-header button[data-toggle="collapse"].collapsed:before {content: "";}*/
.accordion-style-two .card .card-body {
  padding: 0 70px 30px 0;
}
.accordion-style-two .card .card-body p {
  font-size: 20px;
  line-height: 1.55em;
}
.accordion-style-two .card:last-child {
  border: none;
}
/*------------------ Counter With Icon One --------------*/
.counter-with-icon-one .border-style {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 58px 0 90px;
}
.counter-box-three {
  text-align: center;
  margin-top: 40px;
}
.counter-box-three .icon {
  height: 62px;
  display: inline-block;
}
.counter-box-three .number {
  font-size: 38px;
  padding: 5px 0 3px;
}
.counter-box-three p {
  font-weight: 300;
  font-size: 24px;
  color: #000;
}
/*------------------- Fancy Text block Seven ----------------*/
// .fancy-text-block-seven .bg-wrapper {
//   max-width: 1460px;
//   margin: 0 auto;
//   position: relative;
//   background: url(../images/shape/bg.svg) no-repeat top center;
//   background-size: cover;
//   z-index: 1;
//   padding: 42px 0 70px;
// }
.fancy-text-block-seven .bg-wrapper.no-bg {
  background: none;
}
.fancy-text-block-seven .img-holder {
  position: relative;
}
.fancy-text-block-seven .quote-wrapper blockquote {
  font-size: 42px;
  line-height: 1.35em;
  color: #000;
  padding: 22px 0 30px;
}
.fancy-text-block-seven .quote-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
}
.fancy-text-block-seven .quote-wrapper h6 span {
  display: block;
  font-size: 18px;
  color: #9eadba;
  font-weight: normal;
  padding-top: 8px;
}
.fancy-text-block-seven .shape-one {
  top: 13%;
  left: 5%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-seven .shape-two {
  top: 21%;
  right: 8%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-seven .shape-three {
  bottom: 12%;
  right: 8%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-seven .shape-four {
  bottom: 47%;
  left: 45%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-seven .shape-five {
  bottom: 12%;
  left: 8%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-seven .shape-six {
  top: 14%;
  left: -6%;
  z-index: 1;
  animation: jumpThree 5s infinite linear;
}

/*-------------------- Useable Tools Two --------------------*/
.useable-tools-section-two.bg-shape {
  padding: 0 15px;
}
.useable-tools-section-two.bg-shape .bg-wrapper {
  max-width: 1460px;
  margin: 0 auto;
  background: #fdf3e7;
  position: relative;
  padding: 108px 0 0;
  z-index: 1;
}
// .useable-tools-section-two.bg-shape .bg-wrapper:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 15px;
//   background-image: url(../images/shape/37.svg);
//   background-position: top center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   top: -13px;
//   left: 0;
// }
// .useable-tools-section-two.bg-shape .bg-wrapper:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 24px;
//   background-image: url(../images/shape/38.svg);
//   background-position: bottom center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   bottom: -23px;
//   left: 0;
// }
.useable-tools-section-two.bg-shape .bg-wrapper .shapes {
  z-index: 1;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-one {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: 6%;
  left: 11%;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-two {
  width: 10px;
  height: 10px;
  background: #f77a56;
  top: 10%;
  right: 11%;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-three {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: 45%;
  right: 24%;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-two.bg-shape .bg-wrapper .shape-four {
  width: 8px;
  height: 8px;
  background: #f77a56;
  top: 43%;
  left: 16%;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-two .icon-wrapper ul {
  margin: 0 -15px;
  position: relative;
  z-index: 1;
  transform: translateY(58px);
}
.useable-tools-section-two .icon-wrapper ul li {
  padding: 0 15px;
  float: left;
  width: 11.11111%;
}
.useable-tools-section-two .icon-wrapper ul li:nth-child(odd) {
  margin-top: 50px;
}
.useable-tools-section-two .icon-wrapper ul li:nth-child(4),
.useable-tools-section-two .icon-wrapper ul li:nth-child(6) {
  margin-top: 110px;
}
.useable-tools-section-two .icon-wrapper ul li .icon-box {
  background: #fff;
  border-radius: 10px;
  height: 100px;
  margin: 20px 0;
  cursor: pointer;
}
.useable-tools-section-two .theme-btn-two {
  border-radius: 5px;
}
/*----------------- Fancy Short Banner Three -------------------*/
.fancy-short-banner-three .bg-wrapper {
  background: url(../images/rdy-shapes/bg2.svg) no-repeat;
  background-size: cover;
  border-radius: 40px;
  padding: 48px 50px 50px;
  position: relative;
}
.fancy-short-banner-three .bg-wrapper:before {
  content: url(../images/rdy-shapes/43.svg);
  position: absolute;
  top: -124px;
  right: -104px;
  z-index: -1;
}
.fancy-short-banner-three .title-style-one h2 {
  font-size: 40px;
}
.fancy-short-banner-three .form-wrapper {
  padding-left: 65px;
}
.fancy-short-banner-three .form-wrapper form {
  position: relative;
  height: 70px;
  background: #fff;
  border-radius: 7px;
}
.fancy-short-banner-three .form-wrapper form input {
  font-style: italic;
  font-size: 18px;
  width: 100%;
  border: none;
  height: 100%;
  padding: 0 150px 0 25px;
  background: transparent;
}
.fancy-short-banner-three .form-wrapper form button {
  position: absolute;
  right: 8px;
  bottom: 8px;
  top: 8px;
  width: 150px;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  background: var(--red-light);
  transition: all 0.3s ease-in-out;
}
.fancy-short-banner-three .form-wrapper form button:hover {
  background: #ffba12;
  box-shadow: 0px 15px 30px rgba(255, 186, 18, 0.2);
}
.fancy-short-banner-three .form-wrapper p {
  font-size: 16px;
  padding-top: 10px;
}
.fancy-short-banner-three .form-wrapper p a {
  color: #000;
}
.fancy-short-banner-three .form-wrapper p a:hover {
  text-decoration: underline;
}
/*--------------------- Fancy Hero Two --------------------*/
.fancy-hero-two {
  margin-top: 40px;
  padding: 0 70px;
}
.fancy-hero-two .bg-wrapper {
  background: url(../images/rdy-shapes/bg3.svg) no-repeat center;
  background-size: cover;
  border-radius: 20px;
  // padding: 108px 0 110px;
  padding: 40px 0 40px;
  text-align: center;
}
.fancy-hero-two .page-title {
  font-size: 24px;
  color: #b1b1b1;
}
.fancy-hero-two .heading {
  font-family: "gilroy-black";
  font-size: 80px;
  line-height: 1.1em;
  padding: 0 0 38px;
}
.fancy-hero-two .sub-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.41em;
  color: #101621;
  // color: #2a2a2a;
}
/*------------------ Fancy Text block Nine ----------------*/
.fancy-text-block-nine {
  position: relative;
}
.fancy-text-block-nine .text-meta {
  font-size: 24px;
  line-height: 1.66em;
  padding-top: 40px;
  text-align: center;
  color: #515151;
}
.fancy-text-block-nine .shape-one {
  width: 10px;
  height: 10px;
  background: #ffdbd0;
  top: 5%;
  right: 13%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-nine .shape-two {
  width: 6px;
  height: 6px;
  background: #f77a56;
  bottom: 9%;
  right: 13%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-nine .shape-three {
  width: 8px;
  height: 8px;
  background: #ffdbd0;
  bottom: 9%;
  left: 13%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-nine .shape-four {
  width: 11px;
  height: 11px;
  background: #ff7052;
  top: 12%;
  left: 13%;
  animation: jumpThree 5s infinite linear;
}
/*------------------ Fancy Text block Ten ----------------*/
.fancy-text-block-ten .container {
  max-width: 1300px;
  padding: 0 15px;
}
.fancy-text-block-ten p {
  font-size: 42px;
  line-height: 1.35em;
  color: #000000;
  padding: 25px 0 50px;
}
.fancy-text-block-ten .name {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  position: relative;
  padding-left: 28px;
}
.fancy-text-block-ten .name span {
  font-size: 18px;
  color: #8a8a8a;
  font-weight: normal;
}
.fancy-text-block-ten .name:before {
  content: "";
  width: 15px;
  height: 3px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 14px;
}
.fancy-text-block-ten .img-meta {
  border-radius: 5px;
  margin: 0 auto;
}
.fancy-text-block-ten .img-gallery {
  position: relative;
}
// .fancy-text-block-ten .img-gallery:before {
//   content: url(../images/shape/45.svg);
//   position: absolute;
//   top: -14%;
//   right: -5%;
//   z-index: 1;
//   animation: jumpThree 5s infinite linear;
// }
// .fancy-text-block-ten .img-gallery:after {
//   content: url(../images/shape/46.svg);
//   position: absolute;
//   bottom: -12%;
//   left: -12%;
//   z-index: -1;
//   animation: rotatedTwo 50s infinite linear;
// }
/*-------------------- Fancy Feature Five -------------------*/
.fancy-feature-five {
  padding: 0 70px;
}
.fancy-feature-five .bg-wrapper {
  background: #f2f7ff;
  border-radius: 10px;
  padding: 60px 0 60px;
  position: relative;
  z-index: 5;
}
.block-style-seven {
  padding: 20px 22px 0;
  text-align: center;
}
.block-style-seven .icon {
  height: 100px;
}
.block-style-seven .icon img {
  margin: 0 auto;
  max-height: 100%;
  border-radius: 36px;
}
.block-style-seven .feature-info {
  color: rgba(0, 0, 0, 0.63);
  padding: 30px 0 7px;
}
.block-style-seven p {
  font-size: 18px;
  line-height: 1.35em;
  color: #000;
}
.fancy-feature-five .shape-one {
  width: 6px;
  height: 6px;
  background: #f2dad5;
  top: 14%;
  right: 15%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-five .shape-two {
  width: 12px;
  height: 12px;
  background: #f77a56;
  top: 58%;
  right: 6%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-five .shape-three {
  width: 7px;
  height: 7px;
  background: #f2dad5;
  top: 84%;
  left: 7%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-five .shape-four {
  width: 8px;
  height: 8px;
  background: #f77a56;
  top: 27%;
  left: 8%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-five .shape-five {
  bottom: 0;
  right: 0;
}
/*---------------- Fancy Text block Eleven -----------------*/
.fancy-text-block-eleven .text-wrapper {
  padding: 50px 0 0 65px;
}
.fancy-text-block-eleven .text-wrapper p {
  font-size: 22px;
  line-height: 1.81em;
}
.fancy-text-block-eleven .img-gallery {
  position: relative;
  display: inline-block;
}
.fancy-text-block-eleven .img-gallery .overlay-img {
  position: absolute;
  right: -130px;
  top: -70px;
  z-index: 1;
}
.fancy-text-block-eleven .img-gallery .shape-one {
  top: -13%;
  left: -16%;
}
.fancy-text-block-eleven .img-gallery .shape-two {
  bottom: -19%;
  right: -21%;
  animation: rotatedTwo 50s infinite linear;
}
.fancy-text-block-eleven .img-gallery .shape-three {
  bottom: -9%;
  left: -16%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-eleven .video-box {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.fancy-text-block-eleven .video-box .video-button {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: #fd6a5e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.fancy-text-block-eleven .video-box .video-button:hover {
  background: #212121;
}
/*--------------- Fancy Feature Six -------------*/
.fancy-feature-six {
  padding: 0 15px;
}
// .fancy-feature-six .bg-wrapper {
//   max-width: 1440px;
//   margin: 0 auto;
//   padding: 110px 0 210px;
//   background: url(../images/shape/bg4.svg) no-repeat top center;
//   background-size: cover;
//   position: relative;
//   z-index: 5;
// }
.fancy-feature-six .bg-wrapper .shape-one {
  width: 16px;
  height: 16px;
  background: #f96f60;
  z-index: 1;
  top: 5px;
  left: 14%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-two {
  width: 12px;
  height: 12px;
  background: #f96f60;
  top: 36%;
  right: 4%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-three {
  width: 7px;
  height: 7px;
  background: #f96f60;
  top: 68%;
  right: 4%;
  opacity: 0.25;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-four {
  width: 10px;
  height: 10px;
  background: #f96f60;
  top: 91%;
  left: 7%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-five {
  width: 7px;
  height: 7px;
  background: #f96f60;
  top: 31%;
  left: 4%;
  opacity: 0.25;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-six .bg-wrapper .shape-six {
  top: 78%;
  right: 2%;
  animation: rotatedTwo 50s infinite linear;
}
/*----------------- Fancy Text block Twelve -------------------*/
.fancy-text-block-twelve .text-wrapper .sub-text {
  font-size: 22px;
  line-height: 1.81em;
  padding-top: 40px;
}
.fancy-text-block-twelve .img-gallery {
  display: inline-block;
  padding: 30px 45px 0 25px;
  position: relative;
}
.fancy-text-block-twelve .img-gallery .shape-one {
  top: -7%;
  left: -10%;
}
.fancy-text-block-twelve .img-gallery .shape-two {
  bottom: -13%;
  right: -4%;
  animation: jumpTwo 5s infinite linear;
}

/*----------------- Fancy Feature Seven --------------*/
.fancy-feature-seven {
  background: #ecf6ff;
  padding: 170px 0 150px;
  position: relative;
  z-index: 1;
}
// .fancy-feature-seven:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 45px;
//   background: url(../images/shape/58.svg) no-repeat top center;
//   background-size: cover;
//   left: 0;
//   top: -42px;
// }
// .fancy-feature-seven:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 23px;
//   background: url(../images/shape/59.svg) no-repeat top center;
//   background-size: cover;
//   left: 0;
//   bottom: -20px;
// }

/*------------------ Fancy Text Block Thirteen ---------------*/
.fancy-text-block-thirteen .text-wrapper p {
  font-size: 24px;
  line-height: 1.7em;
  padding-top: 45px;
}
/*------------------ Fancy Text Block Fourteen ---------------*/
.fancy-text-block-fourteen .text-wrapper .sub-text {
  font-size: 24px;
  line-height: 1.58em;
  color: #0e0e0e;
  padding-top: 40px;
}
.fancy-text-block-fourteen .text-wrapper .name {
  font-family: "gilroy-bold";
  font-size: 24px;
  color: #000;
  position: relative;
  padding-left: 28px;
  margin-top: 40px;
}
.fancy-text-block-fourteen .text-wrapper .name span {
  font-family: "gilroy-semibold";
  color: #8a8a8a;
}
.fancy-text-block-fourteen .text-wrapper .name:before {
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 13px;
}
.fancy-text-block-fourteen .img-holder {
  position: relative;
  padding: 0 50px 0 60px;
}
.fancy-text-block-fourteen .img-holder .shape-one {
  right: 0;
  top: -10%;
}
.fancy-text-block-fourteen .img-holder .shape-two {
  left: -1%;
  top: -12%;
  z-index: 1;
  animation: jumpTwo 5s infinite linear;
}
/*------------------- Pricing Section One ------------------*/

.pricing-nav-one {
  margin: 40px 0 20px;
  border: none;
  &.pricing-custom-nav-one {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -70px;
    z-index: 5;
    @media only screen and (max-width: 991px) {
      top: -35px;
    }
  }
}
.pricing-nav-one .nav-item {
  margin: 0;
}
.pricing-nav-one .nav-item .nav-link,
.pricing-nav-one .react-tabs__tab {
  font-family: "Rubik", sans-serif;
  line-height: 50px;
  border: 2px solid var(--blue-dark);
  padding: 0 15px;
  width: 172px;
  text-align: center;
  cursor: pointer;
  color: var(--blue-dark);
  transition: all 0.3s ease-in-out;
}

.pricing-nav-one .nav-item:nth-child(2) .nav-link,
.pricing-nav-one #react-tabs-2 {
  border-radius: 0 3px 3px 0;
}
.pricing-nav-one .nav-item .nav-link.active,
.pricing-nav-one .react-tabs__tab.react-tabs__tab--selected {
  background: var(--blue-dark);
  color: #fff;
}
.pricing-section-one .offer-text {
  text-align: center;
  color: var(--blue-dark);
}
.pricing-section-one .pricing-table-area {
  position: relative;
  margin-top: -40px;
  padding-bottom: 130px;
}
.pricing-section-one .pricing-table-area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 78%;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(45deg, #fffbf2, #edfffd);
}
.pricing-section-one .pricing-table-area .shape-one {
  left: 0;
  top: 7%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-one .pricing-table-area .shape-two {
  right: 0;
  bottom: -140px;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-one .pr-table-wrapper {
  background: #fff;
  box-shadow: 0 -10px 40px rgba(60, 67, 113, 0.05);
  padding: 35px 0 25px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.pricing-section-one .pr-table-wrapper:hover {
  transform: translateY(-5px);
}
.pricing-section-one .pr-table-wrapper .pack-name {
  font-family: "gilroy-semibold";
  font-size: 18px;
  text-transform: uppercase;
  color: #202020;
  letter-spacing: 2.1px;
}
.pricing-section-one .pr-table-wrapper .price {
  font-size: 60px;
  color: #000;
  padding: 20px 0 27px;
}
.pricing-section-one .pr-table-wrapper .price sup {
  font-size: 25px;
  top: -21px;
}
.pricing-section-one .pr-table-wrapper .icon {
  margin: 28px auto 20px;
  height: 102px;
}
.pricing-section-one .pr-table-wrapper .bill-cycle {
  font-size: 17px;
  color: #464646;
}
.pricing-section-one .pr-table-wrapper .pr-feature {
  text-align: left;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 46px 0 36px 20px;
  margin: 19px 0 33px;
}
.pricing-section-one .pr-table-wrapper .pr-feature li {
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  line-height: 42px;
  color: #464646;
  padding-left: 30px;
  position: relative;
}
// .pricing-section-one .pr-table-wrapper .pr-feature li:before {
//   content: url(../images/icon/39.svg);
//   position: absolute;
//   left: 0;
//   top: 2px;
// }
.pricing-section-one .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.58);
}
/*----------------- Fancy Text Block Fifteen --------------*/
.fancy-text-block-fifteen {
  position: relative;
  padding-bottom: 170px;
}
.fancy-text-block-fifteen:before {
  content: "";
  width: 100%;
  height: 82%;
  background: #fafaff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.fancy-text-block-fifteen .shape-one {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff6cc4;
  right: 13%;
  top: 28%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-fifteen .shape-two {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #f5a623;
  right: 13%;
  bottom: 28%;
  animation: jumpThree 5s infinite linear;
}
.fancy-text-block-fifteen .shape-three {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #b183ff;
  left: 11%;
  bottom: 16%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-fifteen .shape-four {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6aee90;
  left: 11%;
  top: 42%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-text-block-fifteen .bg-wrapper {
  background: #5e43f0;
  border-radius: 10px;
  padding: 85px 60px 0 15px;
}
.fancy-text-block-fifteen .bg-wrapper .main-img {
  margin: 0 auto;
}
.fancy-text-block-fifteen .text-wrapper .more-text {
  font-family: "gilroy-semibold";
  font-size: 20px;
  color: #fff;
  padding: 28px 0 20px;
}
.fancy-text-block-fifteen .text-wrapper .more-text span {
  text-decoration: underline;
}
.fancy-text-block-fifteen .text-wrapper p {
  font-size: 40px;
  line-height: 1.5em;
  color: #fff;
}
.fancy-text-block-fifteen .text-wrapper h6 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  padding-top: 37px;
}
.fancy-text-block-fifteen .text-wrapper h6 span {
  font-weight: normal;
  font-size: 18px;
}
.fancy-text-block-fifteen .contact-banner p {
  font-size: 42px;
  line-height: 1.42em;
  color: #000;
}
.fancy-text-block-fifteen .contact-banner .theme-btn-four {
  line-height: 55px;
  width: 220px;
  font-size: 22px;
  text-align: center;
  display: block;
}
/*--------------------- Pricing Section Two -----------------*/
.pricing-nav-two {
  margin: 40px 0 20px;
  border: none;
}
.pricing-nav-two .nav-item {
  margin: 0;
}
.pricing-nav-two .nav-item .nav-link,
.pricing-nav-two .react-tabs__tab {
  font-family: "Rubik", sans-serif;
  line-height: 50px;
  border: 2px solid var(--red-light);
  padding: 0 15px;
  width: 172px;
  text-align: center;
  color: var(--red-light);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.pricing-nav-two .nav-item:nth-child(1) .nav-link,
.pricing-nav-two:nth-child(1) .react-tabs__tab {
  border-radius: 3px 0 0 3px;
}
.pricing-nav-two .nav-item:nth-child(2) .nav-link,
.pricing-nav-two #react-tabs-2 {
  border-radius: 0 3px 3px 0;
}
.pricing-nav-two .nav-item .nav-link.active,
.pricing-nav-two .react-tabs__tab.react-tabs__tab--selected {
  background: var(--red-light);
  color: #fff;
}
.pricing-section-two .pricing-table-area {
  position: relative;
  margin-top: -280px;
  z-index: 6;
  border: 1px solid #f1f1f1;
  border-right: none;
}
.pricing-section-two .pricing-table-area .tab-content {
  background: #fff;
}
.pricing-section-two .pricing-table-area .pr-bg {
  border-right: 1px solid #f1f1f1;
}
.pricing-section-two .pr-table-wrapper {
  text-align: center;
  padding: 20px 0 40px;
  width: 100%;
}
.pricing-section-two .pr-table-wrapper .pack-name {
  font-family: "gilroy-semibold";
  font-size: 18px;
  text-transform: uppercase;
  color: #202020;
  letter-spacing: 2.1px;
}
.pricing-section-two .pr-table-wrapper .price {
  font-family: "gilroy-semibold";
  font-size: 72px;
  color: #000;
  padding: 44px 0 20px;
}
.pricing-section-two .pr-table-wrapper .price sup {
  font-size: 25px;
  top: -45px;
}
.pricing-section-two .pr-table-wrapper .bill-cycle {
  font-size: 18px;
  color: #000;
  margin-bottom: 22px;
}
.pricing-section-two .pr-table-wrapper .theme-btn-three {
  line-height: 42px;
  padding: 0 40px;
}
.pricing-section-two .pr-table-wrapper .pr-feature {
  text-align: left;
  border-top: 1px solid #e8e8e8;
  padding: 34px 0 0 26px;
  margin: 35px 0 0;
}
.pricing-section-two .pr-table-wrapper .pr-feature li {
  font-size: 17px;
  line-height: 39px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 26px;
  position: relative;
}
// .pricing-section-two .pr-table-wrapper .pr-feature li:before {
//   content: url(../images/icon/43.svg);
//   position: absolute;
//   left: 0;
//   top: 2px;
// }
.pricing-section-two .pricing-table-area .shape-one {
  left: -7%;
  top: -7%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-two .pricing-table-area .shape-two {
  right: -7%;
  bottom: -9%;
  animation: rotatedTwo 50s infinite linear;
}
/*------------------- FAQ Section -------------------*/
.accordion-style-three .card {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #000000 !important;
}
.accordion-style-three .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-three .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 35px 35px 35px 0;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid transparent;
}
.accordion-style-three .card:first-child .card-header button {
  border-top: 1px solid #000000;
}
.accordion-style-three .card .card-header button:before {
  content: "\f107";
  font-family: "font-awesome";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.accordion-style-three .card .card-body {
  border-bottom: 1px solid #000000;
  padding: 0 50px 20px 0;
}
.accordion-style-three .card .card-body p {
  padding-bottom: 15px;
}
.accordion-style-three .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}
.accordion-style-three .card:last-child .card-body {
  border: none;
  padding-bottom: 0;
}
.more-faq-meta h3 {
  font-size: 36px;
}
.more-faq-meta a {
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  border: 1px solid rgba(255, 122, 81, 0.4);
  background: rgba(255, 104, 40, 0.07);
  padding: 0 45px;
  border-radius: 22px;
  color: var(--red-light);
  transition: all 0.3s ease-in-out;
}
.more-faq-meta a:hover {
  background: var(--red-light);
  color: #fff;
}
/*-------------- Contact Style Two --------------*/
.contact-style-two .contact-info-wrapper {
  position: relative;
  z-index: 5;
  margin-top: -195px;
}
.contact-style-two .contact-info-wrapper .shape-one {
  left: -7%;
  top: -18%;
  animation: jumpTwo 5s infinite linear;
}
.contact-style-two .contact-info-wrapper .shape-two {
  right: -8%;
  bottom: -26%;
  animation: rotatedTwo 50s infinite linear;
}
.contact-style-two .contact-info-wrapper .address-info {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);
  padding: 55px 15px 22px;
  text-align: center;
  margin-bottom: 35px;
  height: 100%;
  width: 100%;
}
.contact-style-two .contact-info-wrapper .address-info .icon {
  height: 82px;
}
.contact-style-two .contact-info-wrapper .address-info .icon img {
  margin: 0 auto;
  max-height: 100%;
}
.contact-style-two .contact-info-wrapper .address-info .title {
  font-size: 20px;
  color: #8e8e8e;
  padding: 17px 0 22px;
  position: relative;
  display: inline-block;
}
.contact-style-two .contact-info-wrapper .address-info .title:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #ffb840;
  border-radius: 2px;
  position: absolute;
  left: 0;
  bottom: 22px;
}
.contact-style-two .contact-info-wrapper .address-info p {
  font-size: 28px;
  line-height: 1.35em;
  color: #000;
}
.contact-style-two .contact-info-wrapper .address-info ul li a {
  font-size: 22px;
  margin: 5px 15px;
  color: rgba(0, 0, 0, 0.52);
  transition: all 0.2s ease-in-out;
}
.contact-style-two .contact-info-wrapper .address-info ul li a:hover {
  color: var(--red-light);
}

.form-style-classic .input-group-meta {
  height: 58px;
  position: relative;
}
.form-style-classic .input-group-meta.lg {
  height: 200px;
}
.form-style-classic .input-group-meta input {
  width: 100%;
  height: 100%;
  font-size: 23px;
  border: none;
  border-bottom: solid 2px #000;
  color: var(--heading);
  background: transparent;
  font-family: "Rubik", sans-serif;
}
.form-style-classic .input-group-meta textarea {
  width: 100%;
  height: 100%;
  font-size: 23px;
  color: var(--heading);
  border: none;
  border-bottom: solid 2px #000;
  resize: none;
  padding: 20px 0;
  font-family: "Rubik", sans-serif;
}
.form-style-classic .input-group-meta label {
  font-size: 14px;
  font-weight: normal;
  color: #bfbfbf;
  position: absolute;
  left: 0;
  top: -20px;
  z-index: 1;
}
.form-style-classic [class*="theme-btn"] {
  border-radius: 5px;
}
/*---------------------- Faqs -------------------*/
.faqs-inner-page {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 170px 0 185px;
  position: relative;
  z-index: 5;
}
.faqs-header .icon {
  height: 55px;
  margin-right: 32px;
}
.faqs-header h3 {
  font-family: "gilroy-semibold";
  font-size: 36px;
}
.faqs-header .collection-preview {
  font-size: 20px;
  line-height: 1.6em;
  padding-bottom: 25px;
}
.faqs-header .avatar {
  margin-top: -7px;
}
.faqs-header .avatar img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 3px white;
  box-shadow: 0 0 0 3px white;
  position: relative;
}
.faqs-header .avatar img:nth-child(n + 2) {
  z-index: 1;
  margin-left: -10px;
}
.faqs-header .avatar_fallback {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--blue-dark);
  border: 3px solid #fff;
  line-height: 42px;
  color: #fff;
  font-size: 17px;
  z-index: 5;
  margin-left: -10px;
  text-align: center;
}
.faqs-header .collection-info {
  font-size: 16px;
  line-height: 1.5em;
  padding: 10px 0 0 0;
  color: #878787;
}
.faqs-header .collection-info span {
  color: var(--heading);
  font-weight: 500;
}
.all-faqs .faqs-all-qus {
  margin-bottom: 70px;
}
.all-faqs .faqs-all-qus .article-preview {
  background: #fff;
  padding: 45px 40px;
  margin-bottom: 18px;
  box-shadow: 0 15px 30px rgba(23, 59, 40, 0.05);
  h3 {
    transition: 0.3s;
  }
  &:hover {
    h3 {
      color: var(--blue-dark);
    }
  }
}

.faqs-all-qus .article-preview .avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 22px;
}
.faqs-all-qus .article-preview h3 {
  font-size: 24px;
  padding-bottom: 10px;
}
.faqs-all-qus .article-preview .avatar-info {
  font-size: 16px;
  line-height: 1.63em;
  color: #606060;
}
.faqs-all-qus .article-preview .avatar-info span {
  font-weight: 500;
}
.faqs-all-qus .article-preview .article-details {
  border-top: 1px solid #d8d8d8;
  margin-top: 46px;
  padding: 38px 0 48px;
  color: rgba(0, 0, 0, 0.8);
}
.faqs-all-qus .article-preview .article-details .list-meta {
  padding: 31px 0 32px 43px;
}
.faqs-all-qus .article-preview .article-details .list-meta li {
  padding-left: 25px;
  position: relative;
  margin-bottom: 20px;
}
// .faqs-all-qus .article-preview .article-details .list-meta li:before {
//   content: url(../images/icon/49.svg);
//   position: absolute;
//   left: 0;
//   top: 0;
// }
.faqs-all-qus .article-preview .article-details .reaction-wrapper {
  border: 2px solid #979797;
  border-radius: 5px;
  text-align: center;
  padding: 50px 15px;
  margin-top: 68px;
  position: relative;
  z-index: 1;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper h4 {
  font-size: 24px;
  padding-bottom: 24px;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper button {
  margin: 0 11px;
  transition: all 0.2s ease-in-out;
}
.faqs-all-qus .article-preview .article-details .reaction-wrapper button:hover {
  transform: translateY(-3px);
}
.faqs-inner-page .more-faq-ask h3 {
  font-size: 36px;
}
.faqs-inner-page .shape-one {
  left: 0;
  bottom: -140px;
  animation: jumpTwo 5s infinite linear;
}
.faqs-inner-page .shape-two {
  width: 30px;
  height: 30px;
  background: #ffdfdf;
  border-radius: 50%;
  top: -15px;
  left: 40%;
}
.faqs-inner-page .shape-three {
  width: 7px;
  height: 7px;
  background: #51fcff;
  border-radius: 50%;
  top: 190px;
  right: 9%;
}
.faqs-inner-page .shape-four {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  border-radius: 50%;
  top: 211px;
  left: 12%;
}
/*------------------ Login/Sign Up ----------------------*/
.user-data-page .illustration-wrapper {
  float: left;
  width: 48%;
  padding: 40px 0;
  min-height: 100vh;
  background: #f8fafe;
  text-align: center;
}
.user-data-page .illustration-wrapper h3 {
  font-size: 36px;
  line-height: 1.27em;
  padding: 40px 0;
}
.user-data-page .illustration-wrapper h3 a {
  text-decoration: underline;
}
.user-data-page .illustration-wrapper .illustration-holder {
  width: 100%;
}
.user-data-page .illustration-wrapper .illustration-holder .illustration {
  width: 100%;
}
.user-data-page .illustration-wrapper .illustration-holder .shape-one {
  bottom: 2%;
  left: 2%;
  z-index: 1;
  width: 60%;
  animation: jumpTwo 5s infinite linear;
}
.user-data-page .illustration-wrapper .illustration-holder .shape-two {
  bottom: 2%;
  left: 59%;
  z-index: 1;
  width: 40%;
  animation: jumpThree 5s infinite linear;
}
.user-data-page .form-wrapper {
  float: left;
  width: 47%;
  height: 100%;
  padding: 50px 4% 20px;
  position: relative;
}
.user-data-page .go-back-button {
  font-size: 15px;
}
.user-data-page .go-back-button:hover {
  color: #000;
  text-decoration: underline;
}
.user-data-page .form-wrapper h2 {
  font-family: "gilroy-bold";
  font-size: 58px;
  line-height: 1.17em;
}
.user-data-page .form-wrapper .header-info {
  font-size: 22px;
  color: var(--heading);
}
.user-data-page .form-wrapper .header-info a {
  color: var(--blue-dark);
  text-decoration: underline;
}
.user-data-page .form-wrapper .copyright-text {
  font-size: 16px;
}
.user-data-form .input-group-meta {
  height: 55px;
  position: relative;
}
.user-data-form .input-group-meta input {
  font-family: "Rubik", sans-serif;
  width: 100%;
  height: 100%;
  font-size: 18px;
  border: none;
  border-bottom: solid 2px #000;
  padding: 0 52px 0 0;
  color: var(--heading);
  background: transparent;
}
.user-data-form .input-group-meta input:focus {
  border-color: var(--blue-dark);
}
.user-data-form .input-group-meta label {
  font-size: 15px;
  font-weight: normal;
  color: #bfbfbf;
  position: absolute;
  left: 0;
  top: -21px;
}
.user-data-form .input-group-meta .placeholder_icon {
  position: absolute;
  line-height: 55px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  text-align: center;
  z-index: 1;
  color: rgba(0, 0, 0, 0.45);
  font-size: 17px;
}
.user-data-form .input-group-meta .valid-sign img {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.user-data-form .input-group-meta input:valid + .valid-sign img {
  opacity: 1;
}
.user-data-form .input-group-meta .placeholder_icon img {
  position: relative;
  top: 50%;
  margin: 0 auto;
  transform: translateY(-50%);
}
.user-data-form .input-group-meta .placeholder_icon span {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  position: relative;
}
.user-data-form .input-group-meta .placeholder_icon span:before {
  content: "";
  width: 2px;
  height: 26px;
  background: #000;
  position: absolute;
  top: 15px;
  left: 24px;
  transform: rotate(45deg);
  z-index: 5;
  transition: all 0.2s ease-in-out;
}
.user-data-form .input-group-meta .placeholder_icon span.eye-slash:before {
  opacity: 0;
}
.user-data-form .agreement-checkbox label {
  position: relative;
  font-size: 15px;
  color: var(--heading);
  cursor: pointer;
  padding-left: 22px;
  line-height: 18px;
  transition: all 0.1s ease-in-out;
}
.user-data-form .agreement-checkbox label a {
  color: var(--p-color);
  text-decoration: underline;
}
.user-data-form .agreement-checkbox input[type="checkbox"] {
  display: none;
}
.user-data-form .agreement-checkbox label:before {
  content: "";
  width: 13px;
  height: 13px;
  line-height: 11px;
  border-radius: 2px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  font-size: 8px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 2px;
  transition: all 0.1s ease-in-out;
}
.user-data-form .agreement-checkbox input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  background: #000;
  color: #fff;
  border-color: #000;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 9px;
}
.user-data-form .agreement-checkbox a {
  position: relative;
  font-size: 15px;
  color: rgba(30, 30, 30, 0.55);
}
.user-data-form .agreement-checkbox a:hover {
  text-decoration: underline;
  color: var(--p-color);
}
.user-data-form .theme-btn-one {
  width: 100%;
}
.user-data-page .full-height {
  min-height: 100vh;
  padding: 20px 0;
  flex-direction: column;
  text-align: center;
}
.full-height .user-data-form .button-solid-one {
  width: 200px;
  margin: 0 auto 30px;
}
/*------------------- Blog Pages ----------------*/
.blog-page-bg {
  background: linear-gradient(45deg, #fffbf2, #edfffd);
  padding: 180px 0 150px;
  position: relative;
  z-index: 5;
}
.blog-page-white-bg {
  padding: 140px 0 0;
}
.blog-page-bg .shape-one {
  width: 30px;
  height: 30px;
  background: #b183ff;
  border-radius: 50%;
  top: -15px;
  left: 40%;
  z-index: 1;
}
.blog-page-bg .shape-two {
  width: 7px;
  height: 7px;
  background: #51fcff;
  border-radius: 50%;
  top: 190px;
  right: 9%;
}
.blog-page-bg .shape-three {
  width: 8px;
  height: 8px;
  background: #ffcd8b;
  border-radius: 50%;
  top: 211px;
  left: 12%;
}
.blog-sidebar-one {
  padding-left: 20px;
}
.blog-sidebar-one .sidebar-search-form form {
  height: 60px;
  background: #fff;
  box-shadow: 5px 10px 25px rgba(15, 24, 40, 0.04);
  position: relative;
}
.blog-v3 .blog-sidebar-one .sidebar-search-form form {
  box-shadow: none;
  border: 1.5px solid #000;
  border-radius: 4px;
}
.blog-sidebar-one .sidebar-search-form form input {
  font-size: 17px;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 45px 0 20px;
  background: transparent;
}
.blog-sidebar-one .sidebar-search-form form button {
  width: 40px;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: transparent;
}
.blog-sidebar-one .sidebar-title {
  font-family: "gilroy-bold";
  font-size: 30px;
  padding-bottom: 15px;
}
.blog-sidebar-one .sidebar-categories ul li a {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  line-height: 60px;
  color: #000;
  border-bottom: 1px solid #ececec;
  display: block;
  transition: all 0.3s ease-in-out;
}
.blog-sidebar-one .sidebar-categories ul li a span {
  float: right;
}
.blog-sidebar-one .sidebar-categories ul li:first-child a {
  border-top: 1px solid #ececec;
}
.blog-sidebar-one .sidebar-categories ul li a:hover {
  color: var(--blue-dark);
}
.blog-v3 .blog-sidebar-one .sidebar-categories ul li a:hover {
  color: var(--red-light);
}
.blog-sidebar-one .sidebar-recent-news .title {
  font-family: "gilroy-bold";
  font-size: 24px;
  line-height: 1.25em;
  color: #000;
  display: block;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}
.blog-sidebar-one .sidebar-recent-news ul li a:hover .title {
  color: var(--blue-dark);
}
.blog-v3 .blog-sidebar-one .sidebar-recent-news ul li a:hover .title {
  color: var(--red-light);
}
.blog-sidebar-one .sidebar-recent-news .date {
  color: #a2a2a2;
}
.blog-sidebar-one .sidebar-recent-news ul li a {
  border-bottom: 1px solid #ececec;
  padding: 17px 0;
}
.blog-sidebar-one .sidebar-recent-news ul li:first-child a {
  border-top: 1px solid #ececec;
}
.blog-sidebar-one .sidebar-keyword ul {
  margin: 0 -5px;
}
.blog-sidebar-one .sidebar-keyword ul li {
  float: left;
  padding: 0 5px;
  margin-bottom: 18px;
}
.blog-sidebar-one .sidebar-keyword ul li a {
  font-weight: 500;
  font-size: 14px;
  background: #fff;
  line-height: 35px;
  border-radius: 4px;
  padding: 0 25px;
  color: #000;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.blog-v3 .blog-sidebar-one .sidebar-keyword ul li a {
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 4px;
}
.blog-sidebar-one .sidebar-keyword ul li a:hover {
  background: var(--blue-dark);
  color: #fff;
}
.blog-v3 .blog-sidebar-one .sidebar-keyword ul li a:hover {
  background: var(--red-light);
  border-color: var(--red-light);
}
.blog-sidebar-one .sidebar-keyword .sidebar-title {
  border-bottom: 1px solid #ececec;
  margin-bottom: 15px;
}
.blog-v4 .wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
.blog-v4 .post-meta {
  margin-bottom: 80px;
}
.blog-v4 .post-meta .post {
  padding: 45px 0 0;
}
.blog-v4 .post-meta .post .date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.63);
}
.blog-v4 .post-meta .post .title {
  font-family: "gilroy-black";
  font-size: 48px;
  line-height: 1.04em;
  color: #000;
  display: block;
  margin: 5px 0 45px;
  transition: all 0.3s ease-in-out;
}
.blog-v4 .post-meta .post p {
  font-size: 20px;
  line-height: 1.7em;
}
.blog-v4 .post-meta .post .read-more {
  font-family: "gilroy-bold";
  font-size: 18px;
  color: #2a2a2a;
  margin-top: 30px;
  transition: all 0.3s ease-in-out;
}
.blog-v4 .post-meta .post .read-more span {
  text-decoration: underline;
}
.blog-v4 .post-meta.text-post {
  border: 2px solid #000;
  border-radius: 5px;
}
.blog-v4 .post-meta.text-post .post {
  padding: 45px 40px;
}
.blog-details-post-v1 .post-meta .mark-text {
  font-family: "gilroy-bold";
  font-size: 23px;
  color: #000;
  line-height: 1.41em;
  padding-top: 35px;
}
.blog-details-post-v1 .post-meta h4 {
  font-family: "gilroy-black";
  font-size: 36px;
  padding-top: 60px;
}
.blog-details-post-v1 .share-area {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  padding: 25px 0;
  margin-top: 40px;
  width: 100%;
}
.blog-details-post-v1 .share-option li {
  font-size: 15px;
  color: var(--heading);
  font-style: italic;
  margin-left: 10px;
}
.blog-details-post-v1 .share-option li:first-child {
  margin-left: 0;
}
.blog-details-post-v1 .share-option li a {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  color: #fff;
  font-size: 20px;
}
.blog-details-post-v1 .tag-feature li:first-child {
  font-family: "gilroy-bold";
  color: #000;
  font-size: 15px;
}
.blog-details-post-v1 .tag-feature li a {
  font-size: 15px;
  color: #73737b;
  &:hover {
    color: var(--blue-dark);
  }
}
.blog-details-post-v1 .comment-area {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  margin-bottom: 50px;
  padding: 50px 40px 30px;
}
.blog-details-post-v1 .comment-area .title {
  font-family: "gilroy-black";
  font-size: 36px;
  padding-bottom: 40px;
}
.blog-details-post-v1 .comment-area .single-comment {
  border-top: 1px solid #e5e5e5;
  padding: 35px 0;
  position: relative;
}
.blog-details-post-v1 .comment-area .user-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.blog-details-post-v1 .comment-area .comment {
  padding-left: 20px;
  position: relative;
}
.blog-details-post-v1 .comment-area .name {
  font-family: "gilroy-semibold";
  font-size: 20px;
}
.blog-details-post-v1 .comment-area .time {
  font-size: 16px;
  color: #adadad;
  padding: 5px 0 15px;
}
.blog-details-post-v1 .comment-area p {
  font-size: 17px;
  line-height: 1.58em;
}
.blog-details-post-v1 .comment-area .reply {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 29px;
  border-radius: 15px;
  background: var(--blue-dark);
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.blog-details-post-v1 .comment-form-section {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08%);
  margin-bottom: 50px;
  padding: 50px 40px 60px;
}
.blog-details-post-v1 .comment-form-section .title {
  font-family: "gilroy-black";
  font-size: 36px;
  padding-bottom: 15px;
}
.blog-details-post-v1 .comment-form-section p {
  font-size: 18px;
  color: #2a2a2a;
}
.blog-details-post-v1 .comment-form-section p a {
  font-weight: 500;
  color: #000;
  text-decoration: underline;
}
.blog-details-post-v1 .comment-form-section .form-style-light {
  padding: 0;
  box-shadow: none;
  margin-top: 50px;
}
.blog-details-post-v1 .comment-form-section .form-style-light [class*="col-"] {
  display: block;
}
.blog-details-post-v1 .blog-quote {
  font-size: 24px;
  line-height: 40px;
  padding: 40px 50px;
  margin: 35px 0 0;
  position: relative;
  z-index: 2;
  background: #f8fafc;
  font-style: normal;
  border-left: 5px solid var(--blue-dark);
}
.search-filter-form form {
  max-width: 750px;
  margin: 0 auto;
  height: 78px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 30px 50px rgba(7, 21, 36, 0.06);
}
.search-filter-form input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 260px 0 35px;
  font-size: 16px;
  border-radius: 5px;
}
.search-filter-form button {
  width: 85px;
  right: 7px;
  bottom: 7px;
  top: 7px;
  background: #111111;
  position: absolute;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.search-filter-form button img {
  margin: 0 auto;
}
.search-filter-form button:hover {
  background: var(--blue-light);
}
.search-filter-form select {
  position: absolute;
  width: 135px;
  height: 35px;
  position: absolute;
  right: 120px;
  top: 21px;
  border: none;
  border-radius: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.4) !important;
  outline: none !important;
  box-shadow: none !important;
}

/*------------------ Fancy Text block Sixteen ---------------*/
.fancy-text-block-sixteen {
  position: relative;
  padding-bottom: 150px;
  z-index: 5;
}
.fancy-text-block-sixteen:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 82%;
  left: 0;
  bottom: 0;
  background: #f7faff;
  z-index: -1;
}
.fancy-text-block-sixteen .img-slick-slider {
  box-shadow: 0 30px 100px rgba(8, 17, 40, 0.05);
  margin-bottom: 90px;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -84px;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li {
  float: left;
  width: 33.333333%;
  height: 4px;
  background: rgba(216, 216, 216, 0.35);
  position: relative;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li button {
  text-indent: -50000px;
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background: #ffd084;
  border-radius: 4px;
  width: 75%;
  opacity: 0;
  transform: scale(0, 1);
  transform-origin: 0 100%;
  transition: all 0.3s ease-in-out;
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li.slick-active button {
  opacity: 1;
  transform: scale(1, 1);
}
.fancy-text-block-sixteen .img-slick-slider .slick-dots li:last-child button {
  left: auto;
  right: 0;
}

/*------------------- Useable Tools -------------*/
.useable-tools-section-three {
  position: relative;
}
.useable-tools-section-three .logo-wrapper {
  position: absolute;
  right: -7%;
  top: 50%;
  transform: translateY(-50%);
  max-width: 63%;
}
.useable-tools-section-three .logo-wrapper .logo {
  border-radius: 50%;
  margin: 12px 4%;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(1) {
  width: 165px;
  height: 165px;
  background: #ffe3e3;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(2) {
  width: 110px;
  height: 110px;
  background: #e8f8ff;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(3) {
  width: 165px;
  height: 165px;
  background: #eaffe7;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(4) {
  width: 140px;
  height: 140px;
  background: #f3f1ff;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(5) {
  width: 125px;
  height: 125px;
  background: #f0f5ef;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(6) {
  width: 185px;
  height: 185px;
  background: #fff9e5;
  animation: jumpTwo 5s infinite linear;
}
.useable-tools-section-three .logo-wrapper .logo:nth-child(7) {
  width: 125px;
  height: 125px;
  background: #e5fffe;
  animation: jumpThree 5s infinite linear;
}
.useable-tools-section-three .sub-text {
  font-size: 22px;
  line-height: 1.69em;
  color: #000;
  padding: 50px 50px 40px 0;
}
.useable-tools-section-three .all-button {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.useable-tools-section-three .all-button i {
  margin-left: 5px;
}
.useable-tools-section-three .all-button:hover {
  color: var(--blue-light);
}


.feature-block-text-meta {
  font-size: 20px;
  line-height: 1.75em;
  color: #4f4f4f;
  padding-bottom: 20px;
}
/*------------------- Fancy Text block Seventeen ----------------*/
.fancy-text-block-seventeen .text-meta {
  font-size: 24px;
  line-height: 1.66em;
  color: #2a2a2a;
  padding-bottom: 40px;
}
/*------------------- Fancy Text block Eighteen ----------------*/
.fancy-text-block-eighteen {
  background: linear-gradient(180deg, #ffffff, #edf2f9);
  padding-bottom: 425px;
}
.fancy-text-block-eighteen .illustration {
  margin: 0 auto;
}

.teamSliderOne {
  max-width: 1680px;
  margin-right: -127px;
  margin-left: auto;
}
.teamSliderOne .team-member {
  padding: 50px 15px;
}
.team-block-one {
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.team-block-one:hover {
  transform: translateY(-5px);
}
.team-block-one .info {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 32px 45px;
}
.team-block-one .info .desig {
  color: rgba(0, 0, 0, 0.63);
}
.team-block-one .info .name {
  font-size: 28px;
  font-weight: 500;
  color: #2a2a2a;
  padding-top: 5px;
}
.team-block-one .img-meta {
  overflow: hidden;
}
.team-block-one .img-meta img {
  width: 100%;
  border-radius: 0 0px 10px 10px;
}
.teamSliderOne .team-member .info {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 32px 45px;
}
.teamSliderOne .team-member .info .desig {
  color: rgba(0, 0, 0, 0.63);
}
.teamSliderOne .team-member .info .name {
  font-size: 28px;
  font-weight: 500;
  color: #2a2a2a;
  padding-top: 5px;
}
.teamSliderOne .team-member .img-meta {
  overflow: hidden;
}
.teamSliderOne .team-member .img-meta img {
  width: 100%;
  border-radius: 0 0px 10px 10px;
}

/*---------------------- Map Area One ----------------*/
.map-area-one {
  height: 700px;
  position: relative;
  .embed-responsive {
    height: 100%;
  }
  iframe {
    filter: brightness(0.3);
  }
}
.map-area-one .map-canvas {
  height: 100%;
}
.map-area-one .si-content {
  background: #fff;
  padding: 35px 30px 35px 50px;
  border-bottom: 5px solid #33d4b8;
  position: absolute;
  top: 20%;
  left: 20%;
  width: 440px;
  height: 240px;
  z-index: 5;
}
.map-area-one .si-content h3 {
  font-family: "gilroy-black";
  font-size: 42px;
}
.map-area-one .si-content p {
  font-size: 18px;
  line-height: 25px;
  color: #2a2a2a;
  padding: 5px 0 25px;
}
.map-area-one .si-content span {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.63);
  display: block;
  line-height: 25px;
}
/*----------------- Documentation ------------------*/
.doc-container.full-width {
  padding: 0 45px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
.doc-container.top-border {
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  margin-top: 150px;
}
.doc-container.top-border .doc-main-body,
.doc-container.top-border .doc-sidebar,
.doc-container.top-border .doc-sideNav {
  padding-top: 50px;
}
.doc-sidebar {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  height: 100vh;
  position: sticky;
  top: 90px;
}
.doc-sidebar .search-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.doc-sidebar .search-form form {
  position: relative;
  height: 50px;
}
.doc-sidebar .search-form form input {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 5px;
  font-size: 16px;
  color: #2a2a2a;
  padding: 0 15px 0 50px;
}
.doc-sidebar .search-form form button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
}
.doc-sidebar .search-form form button img {
  margin: 0 auto;
}
#doc-sidebar-nav {
  max-height: 100vh;
  overflow-y: auto;
  padding-right: 45px;
}
.doc-sidebar .list-item > li {
  border-bottom: 2px solid #000;
  padding: 18px 0;
}
.doc-sidebar .list-item > li:first-child {
  padding-top: 0;
}
.doc-sidebar .list-item li .sub-menu {
  padding-bottom: 15px;
}
.doc-sidebar .list-item li h4 {
  font-family: "gilroy-semibold";
  display: block;
  position: relative;
  line-height: 44px;
  font-size: 24px;
  color: #2a2a2a;
  cursor: pointer;
  margin-bottom: 6px;
}
.doc-sidebar .list-item li h4 .expander {
  position: absolute;
  background: transparent;
  line-height: 50px;
  right: 0;
  top: 0;
  color: inherit;
  font-size: 12px;
}
.doc-sidebar .list-item li .sub-menu a,
.doc-sidebar-menu .card .sub-menu a {
  font-size: 16px;
  line-height: 36px;
  color: #2a2a2a;
  display: block;
  padding: 0 15px;
  transition: all 0.3s ease-in-out;
}
.doc-sidebar .list-item li .sub-menu a.active,
.doc-sidebar-menu .card .sub-menu .active {
  background: #000;
  color: #fff;
  line-height: 32px;
  text-decoration: none;
}
.doc-sidebar .list-item li .sub-menu a.sec-menu {
  position: relative;
}
.doc-sidebar .list-item li .sub-menu a.sec-menu:before {
  content: "";
  position: absolute;
  font-family: "font-awesome";
  right: 10px;
  top: 0;
  line-height: 32px;
  color: #000;
}
.doc-sidebar .list-item li .sub-menu .main-side-nav {
  margin-top: 10px;
}
.doc-sidebar .list-item li .sub-menu .main-side-nav a {
  margin-left: 15px;
}
.doc-sidebar .list-item li .sub-menu a:hover,
.doc-sidebar-menu .card .sub-menu a:hover {
  text-decoration: underline;
}
.doc-sidebar ul .sub-menu {
  display: none;
  transition: all 0.3s ease-in-out;
}
.doc-sidebar ul .sub-menu.show {
  display: block;
}
.doc-sidebar ul .sub-menu.open {
  display: block;
}

.doc-container .doc-main-body {
  padding-left: 50px;
  padding-bottom: 65px;
}
.doc-container.full-width .doc-main-body {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  padding-right: 50px;
}
.doc-container .doc-main-body h2 {
  font-family: "gilroy-black";
  font-size: 42px;
  padding-bottom: 25px;
}
.doc-container .doc-main-body h3 {
  font-family: "gilroy-semibold";
  font-size: 28px;
  padding-bottom: 30px;
}
.doc-container .doc-main-body h3:not(:first-child) {
  padding-top: 20px;
}
.doc-container .doc-main-body p {
  color: #2a2a2a;
  padding-bottom: 30px;
}
.doc-container .doc-main-body p code {
  background: #f0f3f9;
  border-radius: 4px;
}
.doc-container .doc-main-body .mark-blue {
  background: #f4fafa;
  border-left: 4px solid #9cdfc9;
  border-radius: 0 8px 8px 0;
  padding: 22px 40px;
  margin-bottom: 45px;
}
.doc-container .doc-main-body .mark-blue pre {
  font-size: 16px;
  color: #414141;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.doc-container .doc-main-body .mark-blue code {
  color: #e83e8c;
}
.doc-container .doc-main-body .mark-blue pre .s1 {
  color: #78d0b6;
}
.doc-container .doc-main-body .mark-red {
  background: #fcfaf3;
  border-left: 4px solid #fae6bf;
  border-radius: 0 8px 8px 0;
  padding: 22px 40px;
  margin-bottom: 45px;
}
.doc-container .doc-main-body .mark-red pre {
  font-size: 16px;
  color: #575858;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.doc-container .doc-main-body .mark-red pre .s1 {
  color: #78d0b6;
  background: #fff;
}
.doc-container .doc-main-body .bg-black {
  background: #2d2d2d;
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 30px 45px 30px;
  max-height: 740px;
  white-space: pre-wrap;
  color: inherit;
  font-size: 16px;
}
.doc-container .doc-main-body .bg-black code {
  white-space: pre-wrap;
}
.doc-container .doc-main-body .bg-black .s1 {
  color: #5e5e5e;
}
.doc-container .doc-main-body .bg-black .s2 {
  color: #cacaca;
}
.doc-container .doc-main-body .bg-black .s3 {
  color: #21b787;
}
.doc-container .doc-main-body .list-item-one li {
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  position: relative;
  padding-left: 15px;
  margin-bottom: 5px;
}
.doc-container .doc-main-body .list-item-one li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  left: 0;
  top: 11px;
}
.doc-container .doc-main-body .img-meta {
  margin: 40px auto;
}
.doc-pagination ul li a {
  width: 35px;
  height: 35px;
  line-height: 31px;
  border: 2px solid #000;
  text-align: center;
  border-radius: 50%;
  font-size: 28px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.doc-pagination ul li a:hover {
  background: #000;
  color: #fff;
}
.doc-pagination ul li span {
  font-family: "gilroy-semibold";
  font-size: 22px;
  color: #000;
}
.doc-sideNav .wrapper {
  padding-left: 40px;
}
.doc-sideNav .nav-link {
  display: block;
  font-size: 16px;
  line-height: 36px;
  color: #767676;
  padding: 0 15px;
  transition: all 0.3s ease-in-out;
}
.doc-sideNav .nav-link.active {
  line-height: 32px;
  background: #000;
  color: #fff;
}
.doc-sideNav {
  top: 100px;
  position: sticky;
  height: 100%;
}

/*-------------Fancy Feature Eight -----------*/
.fancy-feature-eight .bg-wrapper {
  position: relative;
  z-index: 5;
  background: #fff;
  padding: 35px 0 42px;
}
// .fancy-feature-eight .bg-wrapper:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 24px;
//   background: url(../images/shape/97.svg) no-repeat center top;
//   left: 0;
//   top: -20px;
// }
// .fancy-feature-eight .bg-wrapper:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 13px;
//   background: url(../images/shape/98.svg) no-repeat center bottom;
//   left: 0;
//   bottom: -10px;
// }

/*------------------ Fancy Text block Nineteen ----------------*/

.fancy-text-block-nineteen .text-wrapper p {
  font-size: 24px;
  color: #0e0e0e;
  line-height: 1.58em;
  padding-top: 40px;
}
.fancy-text-block-nineteen .text-wrapper .name {
  font-size: 24px;
  color: #000;
  position: relative;
  padding-left: 28px;
  margin-top: 50px;
}
.fancy-text-block-nineteen .text-wrapper .name:before {
  content: "";
  width: 18px;
  height: 3px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 13px;
}
/*------------------ Fancy Feature Nine --------------*/
.fancy-feature-nine {
  position: relative;
  background: #dff1ee;
  padding: 140px 0 135px;
  z-index: 5;
}
// .fancy-feature-nine:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 52px;
//   background: url(../images/shape/105.svg) no-repeat center top;
//   background-size: cover;
//   left: 0;
//   top: -50px;
// }
// .fancy-feature-nine:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 75px;
//   background: url(../images/shape/106.svg) no-repeat center bottom;
//   background-size: cover;
//   left: 0;
//   bottom: -70px;
// }
.fancy-feature-nine .shape-one {
  top: 7%;
  right: 13%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-nine .shape-two {
  top: 45%;
  right: 5%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-nine .shape-three {
  top: 88%;
  right: 10%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-nine .shape-four {
  top: 89%;
  left: 11%;
  animation: jumpTwo 5s infinite linear;
}
.fancy-feature-nine .shape-five {
  top: 43%;
  left: 4%;
  animation: jumpThree 5s infinite linear;
}
.fancy-feature-nine .shape-six {
  top: 9%;
  left: 15%;
  animation: jumpTwo 5s infinite linear;
}

/*--------------------- Pricing Section Three ------------------*/
.pricing-section-three {
  background: #fdecd8;
  position: relative;
  z-index: 1;
  padding: 105px 0 170px;
}
// .pricing-section-three:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 85px;
//   background: url(../images/shape/115.svg) no-repeat center top;
//   background-size: cover;
//   left: 0;
//   top: -84px;
// }
// .pricing-section-three:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 33px;
//   background: url(../images/shape/116.svg) no-repeat center bottom;
//   background-size: cover;
//   left: 0;
//   bottom: -32px;
// }
.pricing-section-three .shape-one {
  top: 7%;
  right: 13%;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-three .shape-two {
  top: 45%;
  right: 5%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-three .shape-three {
  top: 88%;
  right: 10%;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-three .shape-four {
  top: 89%;
  left: 11%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-three .shape-five {
  top: 43%;
  left: 4%;
  animation: jumpThree 5s infinite linear;
}
.pricing-section-three .shape-six {
  top: 9%;
  left: 15%;
  animation: jumpTwo 5s infinite linear;
}
.pricing-section-three .pricing-table-area-three {
  position: relative;
}
// .pricing-section-three .pricing-table-area-three:before {
//   content: url(../images/shape/117.svg);
//   position: absolute;
//   left: -5%;
//   top: -5%;
//   z-index: -1;
// }
.pricing-table-area-three .pr-table-wrapper {
  background: #fff;
  padding: 40px 20px 40px;
  text-align: center;
  margin-top: 45px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-three .pr-table-wrapper:hover {
  transform: translateY(-10px);
}
.pricing-table-area-three .pr-table-wrapper.skew-right:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 0;
  width: 100%;
  height: 25px;
  background: #fff;
  transform: skewY(2.3deg);
}
.pricing-table-area-three .pr-table-wrapper.skew-left:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 0;
  width: 100%;
  height: 25px;
  background: #fff;
  transform: skewY(-2.3deg);
}
.pricing-table-area-three .pr-table-wrapper .pack-name {
  font-size: 32px;
  color: #000;
}
.pricing-table-area-three .pr-table-wrapper .pack-name span {
  position: relative;
  z-index: 1;
}
.pricing-table-area-three .pr-table-wrapper .pack-name span:before {
  content: "";
  width: 109%;
  height: 15px;
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  z-index: -1;
}
.pricing-table-area-three .pr-table-wrapper .pack-name.pc1 span:before {
  background: #d4fdf1;
}
.pricing-table-area-three .pr-table-wrapper .pack-name.pc2 span:before {
  background: #dbf7ff;
}
.pricing-table-area-three .pr-table-wrapper .pack-name.pc3 span:before {
  background: #ffe3e3;
}

.pricing-table-area-three .pr-table-wrapper .price {
  font-size: 65px;
  color: #000;
  padding: 24px 0 0;
  line-height: initial;
}
.pricing-table-area-three .pr-table-wrapper .user-condition {
  font-size: 24px;
  color: #000;
}
.pricing-table-area-three .pr-table-wrapper .line {
  width: 100%;
  margin: 25px auto 38px;
}
.pricing-table-area-three .pr-table-wrapper ul {
  text-align: left;
  padding-left: 18px;
}
.pricing-table-area-three .pr-table-wrapper ul li {
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  line-height: 42px;
  color: #464646;
  position: relative;
  padding-left: 30px;
}
.pricing-table-area-three .pr-table-wrapper ul li.disable {
  color: #b9b9b9;
}
// .pricing-table-area-three .pr-table-wrapper ul li:before {
//   content: url(../images/icon/63.svg);
//   position: absolute;
//   left: 0;
//   top: 1px;
// }
// .pricing-table-area-three .pr-table-wrapper ul li.disable:before {
//   content: url(../images/icon/64.svg);
//   top: 5px;
//   left: -8px;
// }
.pricing-table-area-three .pr-table-wrapper .subscribe-btn {
  display: block;
  width: 95%;
  margin: 37px auto 0;
  line-height: 46px;
  border: 2px solid var(--purple-blue);
  text-align: center;
  color: var(--purple-blue);
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-three .pr-table-wrapper .subscribe-btn:hover {
  background: var(--purple-blue);
  color: #fff;
}
.pricing-section-three-inner-page {
  background: #fbf3ec;
  position: relative;
  z-index: 1;
  padding: 215px 0 110px;
}
.pricing-section-three-inner-page .shape-one {
  left: 0;
  top: 19%;
}
.pricing-section-three-inner-page .shape-two {
  right: 0;
  top: 19%;
}

/*------------------- Faq Section Three ----------------*/
.faq-section-four {
  background: #dff1ee;
  position: relative;
  z-index: 1;
  padding: 78px 0 150px;
}
.faq-section-four.bg-white {
  background: #fff;
}
.faq-section-four.bg-white:before,
.faq-section-four.bg-white:after {
  display: none;
}
// .faq-section-four:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100px;
//   background: url(../images/shape/118.svg) no-repeat center top;
//   background-size: cover;
//   left: 0;
//   top: -98px;
// }
// .faq-section-four:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 33px;
//   background: url(../images/shape/119.svg) no-repeat center bottom;
//   background-size: cover;
//   left: 0;
//   bottom: -32px;
// }
.faq-section-four .shape-one {
  left: 0;
  top: 21%;
}
.faq-section-four .shape-two {
  right: 0;
  top: 75%;
}
.accordion-style-four .card {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 10px;
}
.faq-section-four.bg-white .accordion-style-four .card {
  background: #f7f7f7;
}
.accordion-style-four .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-four .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 27px 35px 27px 45px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.accordion-style-four .card .card-header button:before {
  content: "\f107";
  font-family: "font-awesome";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 767px) {
    right: 12px;
  }
}
.accordion-style-four .card .card-body {
  padding: 0 50px 20px 45px;
}
.accordion-style-four .card .card-body p {
  padding-bottom: 15px;
}
.accordion-style-four .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}


/*----------------- Fancy Text block Twenty -------------------*/
.fancy-text-block-twenty {
  position: relative;
  padding: 215px 0 100px;
}
// .fancy-text-block-twenty:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 36%;
//   background: url(../images/shape/123.svg) no-repeat center bottom;
//   top: 0;
//   left: 0;
//   z-index: -1;
//   background-size: cover;
// }
.fancy-text-block-twenty .shape-one {
  left: 0;
  top: 19%;
}
.fancy-text-block-twenty .shape-two {
  right: 0;
  top: 19%;
}
.fancy-text-block-twenty .title {
  text-align: center;
  font-size: 72px;
  padding-bottom: 130px;
}
.fancy-video-box-one .main-img {
  width: 100%;
}
.fancy-video-box-one {
  position: relative;
}
.fancy-video-box-one .video-button {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding-left: 8px;
}
.fancy-text-block-twenty .nav-tabs {
  border: none;
}
.fancy-text-block-twenty .nav-tabs li {
  margin: 0 82px 0 0;
}
.fancy-text-block-twenty .nav-tabs li:last-child {
  margin-right: 0;
}
.fancy-text-block-twenty .nav-tabs li {
  font-size: 22px;
  padding: 0;
  border: none;
  cursor: pointer;
}
.fancy-text-block-twenty .nav-tabs li.react-tabs__tab--selected {
  color: #000;
  text-decoration: underline;
}
.fancy-text-block-twenty .tab-content p {
  font-size: 20px;
  line-height: 1.9em;
  color: #000;
  padding-top: 35px;
}
/*=======================================================================
                              Product landing               
=========================================================================*/
.theme-menu-five .logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-five .right-widget {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.theme-menu-five .right-widget .demo-button {
  width: 100px;
  font-size: 17px;
  line-height: 40px;
  border: 2px solid #000;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  color: #000;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.theme-menu-five .right-widget .demo-button:hover {
  background: #f4912e;
  border-color: #f4912e;
  color: white;
}
.theme-menu-five .right-widget .demo-button img {
  width: 20px;
  display: none;
  margin: 0 auto;
}



.theme-menu-five .navbar-nav .nav-item .nav-link {
  font-size: 20px;
  margin: 0 23px;
}
.theme-menu-five .navbar-nav .nav-item .nav-link.active {
  text-decoration: underline;
}

.partner-slider-two p {
  font-size: 20px;
  color: #000;
  padding-bottom: 45px;
}
.partner-slider-two p span {
  font-weight: 500;
}
.partner-slider-two .img-meta {
  height: 80px;
}
.partner-slider-two p.text-lg {
  font-size: 36px;
  line-height: 1.44em;
  font-weight: 500;
}
.partner-slider-two p.text-lg span {
  color: #ffb840;
}
/*------------------ Fancy Feature Ten --------------*/
.fancy-feature-ten {
  padding-left: 15px;
  padding-right: 15px;
}
.fancy-feature-ten .bg-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  background: #f3f8ff;
  border-radius: 40px;
  padding: 130px 0 150px;
  position: relative;
  z-index: 1;
}
.fancy-feature-ten .shape-one {
  bottom: -37px;
  right: 10%;
}
.fancy-feature-ten .sub-text {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
}

/*----------------- Fancy Text block Twenty One -------------------*/
.fancy-text-block-twentyOne .container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.fancy-text-block-twentyOne .text-wrapper p {
  font-size: 48px;
  color: #000000;
  line-height: 1.59em;
  padding-bottom: 40px;
}
.fancy-text-block-twentyOne .text-wrapper p span {
  color: #ff2759;
  text-decoration: underline;
}
.fancy-text-block-twentyOne .text-wrapper .name {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  font-style: italic;
  position: relative;
  padding-left: 30px;
  margin-top: 32px;
}
.fancy-text-block-twentyOne .text-wrapper .name:before {
  content: "";
  width: 22px;
  height: 2px;
  border-radius: 2px;
  background: #000;
  position: absolute;
  left: 0;
  top: 13px;
}
.fancy-text-block-twentyOne .img-meta {
  position: relative;
}
.fancy-text-block-twentyOne .img-meta .shape-one {
  bottom: -123px;
  left: 6px;
}
/*-------------------- Counter Style Two --------------*/
.counter-style-two {
  padding: 0 15px;
}
.counter-style-two .border-bottom {
  max-width: 1600px;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 120px;
}
.counter-box-four {
  text-align: center;
  margin-top: 40px;
}
.counter-box-four .number {
  font-family: "Recoleta";
  font-size: 60px;
  color: #000;
}
.counter-box-four p {
  color: #000;
}
/*-------------------- Fancy Feature Eleven ----------------*/
.fancy-feature-eleven {
  position: relative;
  z-index: 1;
}
.fancy-feature-eleven .inner-container {
  max-width: 1630px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}
.fancy-feature-eleven .shape-one {
  top: 3%;
  left: 7%;
}
.fancy-feature-eleven .shape-two {
  bottom: -60px;
  left: 37%;
  z-index: 1;
}

/*----------------- Pricing Section Four ----------------*/
.pricing-nav-three {
  margin: 60px 0 100px;
  border: none;
}
.pricing-nav-three .nav-item {
  margin: 0;
}
.pricing-nav-three .nav-item .nav-link,
.pricing-nav-three .react-tabs__tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 61px;
  border: 2px solid #000;
  padding: 0 15px;
  width: 150px;
  text-align: center;
  color: #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.pricing-nav-three .nav-item:nth-child(1) .nav-link,
.pricing-nav-three .react-tabs__tab:first-child {
  border-right: none;
  border-radius: 10px 0 0 10px;
}
.pricing-nav-three .nav-item:nth-child(2) .nav-link,
.pricing-nav-three .react-tabs__tab:last-child {
  border-left: none;
  border-radius: 0 10px 10px 0;
}
.pricing-nav-three .nav-item .nav-link.active,
.pricing-nav-three .react-tabs__tab.react-tabs__tab--selected {
  background: #000;
  color: #fff;
}
.pricing-table-area-four {
  max-width: 1350px;
  padding: 0 15px;
  margin: 0 auto;
}
.pricing-table-area-four .row {
  margin: 0 -30px;
}
.pricing-table-area-four .row [class*="col-"] {
  padding: 0 30px;
}
.pricing-table-area-four .pr-table-wrapper {
  border: 1px solid #ececec;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  padding: 25px 35px 35px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.pricing-table-area-four .pr-table-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  border: 2px solid #000;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
// .pricing-table-area-four .pr-table-wrapper.most-popular:after {
//   content: url(../images/shape/populer-tag.svg);
//   position: absolute;
//   top: -97px;
//   right: -87px;
// }
.pricing-table-area-four .pr-table-wrapper .pack-name {
  font-weight: 500;
  font-size: 28px;
  color: #000;
}
.pricing-table-area-four .pr-table-wrapper .pack-details {
  color: #878787;
  padding: 5px 0 45px;
}
.pricing-table-area-four .pr-table-wrapper .top-banner {
  padding: 9px 5px 15px 22px;
  color: #000;
  text-align: left;
}
.pricing-table-area-four .pr-table-wrapper .price {
  font-size: 40px;
  font-weight: 500;
  padding: 25px 20px 0 0;
}
.pricing-table-area-four .pr-table-wrapper .price sup {
  font-size: 20px;
  top: -22px;
}
.pricing-table-area-four .pr-table-wrapper .top-banner span {
  font-size: 22px;
}
.pricing-table-area-four .pr-table-wrapper .top-banner em {
  font-size: 14px;
  font-style: normal;
  display: block;
  margin-top: -7px;
}
.pricing-table-area-four .pr-table-wrapper .pr-feature {
  text-align: left;
  padding: 38px 0 60px 20px;
}
.pricing-table-area-four .pr-table-wrapper .pr-feature li {
  position: relative;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 30px;
}
// .pricing-table-area-four .pr-table-wrapper .pr-feature li:before {
//   content: url(../images/icon/76.svg);
//   position: absolute;
//   top: 0;
//   left: 0;
// }
.pricing-table-area-four .pr-table-wrapper .trial-button {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 52px;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-four .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 20px;
}
.pricing-table-area-four .pr-table-wrapper:hover:before,
.pricing-table-area-four .pr-table-wrapper.active:before {
  opacity: 1;
}
.pricing-table-area-four .pr-table-wrapper .trial-button:hover,
.pricing-table-area-four .pr-table-wrapper.active .trial-button {
  background: #000;
  color: #fff;
}




/*---------------- Modal Contact Form One ------------------*/
.modal-backdrop {
  z-index: 10000;
}
.modal {
  z-index: 10001;
}
.modal-contact-popup-one .modal-dialog {
  max-width: 1330px;
  padding: 0 15px;
}
.modal-contact-popup-one .main-body {
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: none;
  -ms-flex-direction: row;
  flex-direction: row;
}
.dark-style.modal-contact-popup-one .main-body {
  background: #2e2b3a;
}
.modal-contact-popup-one .main-body .close {
  position: absolute;
  right: 30px;
  top: 27px;
  z-index: 5;
}
.dark-style.modal-contact-popup-one .main-body .close {
  filter: invert(0.8);
}
.modal-contact-popup-one .main-body .left-side {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  background: #fde3c5;
  text-align: center;
  padding: 50px 0 0;
}
.dark-style.modal-contact-popup-one .main-body .left-side {
  background: linear-gradient(319.09deg, #f2a530 15.78%, #ed5879 48.89%, #b439ff 81.59%);
}
.dark-style.modal-contact-popup-one .main-body .left-side blockquote,
.dark-style.modal-contact-popup-one .main-body .left-side .bio {
  color: #fff;
}
.modal-contact-popup-one .main-body .left-side blockquote {
  font-weight: 500;
  font-size: 32px;
  line-height: 1.31em;
  color: #000;
  padding: 0 15px 15px;
}
.modal-contact-popup-one .main-body .left-side .bio {
  font-size: 20px;
  color: #000;
  display: block;
  padding-bottom: 15px;
}
.modal-contact-popup-one .main-body .left-side .illustration {
  width: 100%;
}
.modal-contact-popup-one .main-body .right-side {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 50px 5% 50px 5%;
  position: relative;
  z-index: 1;
}
.modal-contact-popup-one .main-body .right-side .form-title {
  font-size: 40px;
  font-weight: 500;
  color: #000;
  padding-bottom: 30px;
}
.dark-style.modal-contact-popup-one .main-body .right-side .form-title {
  color: #fff;
}
.modal-contact-popup-one .main-body .input-group-meta {
  position: relative;
}
.modal-contact-popup-one .main-body .input-group-meta label {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 8px;
  font-weight: normal;
  display: block;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta label {
  color: rgba(255, 255, 255, 0.52);
}
.modal-contact-popup-one .main-body .input-group-meta input {
  width: 100%;
  height: 60px;
  border: 2px solid #000;
  border-radius: 6px;
  padding: 0 20px;
  color: #000;
  font-size: 17px;
  background: transparent;
}
.modal-contact-popup-one .main-body .input-group-meta textarea {
  max-width: 100%;
  width: 100%;
  height: 150px;
  border: 2px solid #000;
  border-radius: 6px;
  padding: 10px 20px;
  color: #000;
  font-size: 17px;
  background: transparent;
  display: block;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta input,
.dark-style.modal-contact-popup-one .main-body .input-group-meta textarea {
  border-color: #fff;
  color: #fff;
}
.modal-contact-popup-one .main-body .input-group-meta ::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}
.modal-contact-popup-one .main-body .input-group-meta :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
.modal-contact-popup-one .main-body .input-group-meta ::placeholder {
  color: #000;
}

.dark-style.modal-contact-popup-one .main-body .input-group-meta ::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.dark-style.modal-contact-popup-one .main-body .input-group-meta ::placeholder {
  color: #fff;
}
.dark-style.modal-contact-popup-one .theme-btn-seven {
  background: rgba(255, 255, 255, 0.25);
}

.dark-style .modal-contact-popup-one .right-side:before {
  content: "";
  position: absolute;
  width: 568px;
  height: 568px;
  border-radius: 50%;
  top: -14%;
  left: 18%;
  background: rgba(89, 156, 255, 0.12);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -1;
}
.dark-style .modal-contact-popup-one .right-side:after {
  content: "";
  position: absolute;
  width: 568px;
  height: 568px;
  border-radius: 50%;
  top: 7%;
  left: 7%;
  background: rgba(255, 4, 64, 0.16);
  filter: blur(300px);
  transform: rotate(150deg);
  z-index: -2;
}
/*=======================================================================
                              Product landing Dark            
=========================================================================*/
/*-------------------- Menu ---------------*/
.dark-style .theme-main-menu {
  background: transparent;
}
.dark-style .navbar .navbar-toggler {
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
}
.dark-style .theme-main-menu.sticky-menu.fixed {
  background: #17192b;
}

.dark-style .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
.dark-style .navbar-nav .nav-item.active .nav-link {
  color: rgba(255, 255, 255, 1);
}
.dark-style .theme-menu-five .navbar-nav .nav-item .nav-link.active {
  color: #fff;
}
.dark-style .theme-menu-five .right-widget .demo-button {
  color: #fff;
  border-color: #fff;
}
.dark-style .theme-menu-five .right-widget .demo-button:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90.6deg, #f548a0 2.93%, #f57c35 99.47%);
  border-radius: 8px;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.dark-style .theme-menu-five .right-widget .demo-button:hover {
  background: transparent;
  border-color: transparent;
}
.dark-style .theme-menu-five .right-widget .demo-button:hover:before {
  opacity: 1;
}

/*------------------ Fancy Feature Twelve --------------*/
.fancy-feature-twelve {
  position: relative;
  z-index: 2;
}
.fancy-feature-twelve .shape-one {
  top: 3%;
  left: 10%;
}
.fancy-feature-twelve .row {
  margin: 0 -30px;
}
.fancy-feature-twelve .row [class*="col-"] {
  padding: 0 30px;
}
/*------------------ Fancy Feature Thirteen --------------*/

.fancy-feature-thirteen .carousel-indicators {
  position: relative;
  margin: 0;
  border-top: 5px solid rgba(255, 255, 255, 0.1);
}
.fancy-feature-thirteen .carousel-indicators li {
  width: 30%;
  margin: 0;
  padding: 0;
  height: auto;
  background: transparent;
  opacity: 1;
  border: none;
  text-indent: 0;
  position: relative;
  padding-top: 50px;
}
.fancy-feature-thirteen .carousel-indicators li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: #ffd35b;
  left: 0;
  top: -5px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.fancy-feature-thirteen .item_content .icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  margin: 6px 30px 0 0;
}
.fancy-feature-thirteen .item_content .icon img {
  transition: all 0.3s ease-in-out;
  opacity: 0.4;
}
.fancy-feature-thirteen .item_content {
  padding-top: 50px;
  p {
    font-size: 24px;
    line-height: 1.41em;
    color: rgba(255, 255, 255, 0.5);
    transition: all 0.3s ease-in-out;
  }
}
.fancy-feature-thirteen .slick-slider.slick-nav-custom .slick-slide.slick-active.slick-current {
  border-top-color: #ffd35b;
}
.fancy-feature-thirteen .carousel-indicators li.active:before {
  opacity: 1;
}
.fancy-feature-thirteen .slick-slide.slick-active.slick-current {
  .item_content {
    p {
      color: rgba(255, 255, 255, 1);
    }
  }
  .icon img {
    opacity: 1;
    transform: rotate(90deg);
  }
}
.fancy-feature-thirteen .carousel-indicators li.active p {
  color: rgba(255, 255, 255, 1);
}
.fancy-feature-thirteen .carousel-inner {
  background: rgba(243, 247, 248, 0.1);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.15);
  border-radius: 40px;
  padding: 70px 75px;
  margin-top: 85px;
  position: relative;
  overflow: visible;
}
#productScreenSlider .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}
#productScreenSlider .carousel-item.active,
#productScreenSlider .carousel-item-next.carousel-item-left,
#productScreenSlider .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
#productScreenSlider .active.carousel-item-left,
#productScreenSlider .active.carousel-item-right {
  opacity: 0;
}
#productScreenSlider .carousel-item-next,
#productScreenSlider .carousel-item-prev,
#productScreenSlider .carousel-item.active,
#productScreenSlider .active.carousel-item-left,
#productScreenSlider .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}
.fancy-feature-thirteen .shape-one {
  top: -57px;
  right: -60px;
  z-index: 1;
}
.fancy-feature-thirteen .shape-two {
  bottom: -116px;
  left: -137px;
  z-index: 1;
}
/*------------------ Fancy Feature Fourteen --------------*/
.fancy-feature-fourteen .logo-meta {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  height: 170px;
  margin-bottom: 40px;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}
.fancy-feature-fourteen .logo-meta.lg {
  height: 248px;
}
.fancy-feature-fourteen .js-tilt-glare {
  border-radius: 20px;
}
.fancy-feature-fourteen .right-side {
  position: relative;
  z-index: 1;
}
.fancy-feature-fourteen .right-side .shape-one {
  bottom: -29px;
  right: -31px;
  z-index: 1;
}

/*------------------ Fancy Portfolio One --------------*/

.fancy-portfolio-one .img-meta {
  border-radius: 20px;
  overflow: hidden;
  margin: 0 20px;
}
.fancy-portfolio-one .img-meta img {
  margin: 0 auto;
  cursor: pointer;
  transition: 0.3s;
}
.fancy-portfolio-one .img-meta:hover img {
  opacity: 0.8;
}
.portfolioSliderOne {
  max-width: 1800px;
  margin: 0 auto;
}
.fancy-portfolio-one .slider-arrows > div {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  margin-left: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.fancy-portfolio-one .slider-arrows > div:hover {
  background: #ff2759;
}
.fancy-portfolio-one .slider-arrows {
  position: absolute;
  top: 28px;
  right: 16%;
}

/*------------------ Pricing Section Five ---------------*/

.pricing-table-area-five .row {
  margin: 0 -20px;
}
.pricing-table-area-five .row [class*="col-"] {
  padding: 0 20px;
}
.pricing-table-area-five .pr-table-wrapper {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  position: relative;
  padding: 58px 15px 55px 47px;
}
.pricing-table-area-five .pr-table-wrapper .pack-name {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  padding: 39px 0 37px;
}
.pricing-table-area-five .pr-table-wrapper .pr-feature li {
  line-height: 42px;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 30px;
  position: relative;
}
// .pricing-table-area-five .pr-table-wrapper .pr-feature li:before {
//   content: url(../images/icon/87.svg);
//   position: absolute;
//   left: 0;
//   top: 0;
// }
.pricing-table-area-five .pr-table-wrapper .price {
  color: #fff;
  font-size: 28px;
  padding: 45px 0 7px;
}
.pricing-table-area-five .pr-table-wrapper .trial-text {
  color: rgba(255, 255, 255, 0.54);
}
.pricing-table-area-five .pr-table-wrapper .trial-button {
  width: 170px;
  line-height: 50px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 35px;
  text-align: center;
}
.pricing-table-area-five .pr-table-wrapper .trial-button:before {
  border-radius: 24px;
}
.pricing-table-area-five .pr-table-wrapper.active {
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
}
.pricing-table-area-five .pr-table-wrapper.active .trial-button {
  background: rgba(255, 255, 255, 0.1);
}
.pricing-table-area-five .shape-one {
  z-index: 1;
  right: -47px;
  top: -76px;
  animation: rotated 35s infinite linear;
}
.pricing-table-area-five .shape-two {
  z-index: 1;
  left: -107px;
  bottom: -95px;
  animation: rotated 20s infinite linear;
}
.pricing-table-area-five .pr-table-wrapper .popular-badge {
  top: -90px;
  left: -77px;
  position: absolute;
  z-index: 1;
  animation: jumpTwo 10s infinite linear;
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.pricing-table-area-five .js-tilt-glare .js-tilt-glare-inner {
  border-radius: 15px;
  background-image: none !important;
}
.pricing-table-area-five .pr-table-wrapper.active .popular-badge {
  display: none;
}

/*=======================================================================
                              Product landing / Note Taking            
=========================================================================*/
/*-------------------- Menu ---------------*/
.theme-menu-five .right-widget .signIn-action {
  font-size: 20px;
  font-weight: 300;
  line-height: 46px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-menu-five .right-widget .signIn-action:hover {
  text-decoration: underline;
}
.theme-menu-five .right-widget .signIn-action img {
  margin-right: 12px;
  margin-top: -3px;
}
.theme-menu-five .right-widget .download-btn {
  margin-left: 40px;
}
.theme-menu-five .right-widget .download-btn button {
  width: 190px;
  line-height: 60px;
  border-radius: 9px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: #ffeaa0;
  padding-left: 12px;
}
// .theme-menu-five .right-widget .download-btn button:after {
//   content: url(../images/icon/89.svg);
//   border: none;
//   margin: 0 0 0 11px;
//   vertical-align: 0;
//   transition: all 0.2s ease-in-out;
// }
.theme-menu-five .right-widget .download-btn.style-two button {
  background: #fff;
  border: 2px solid #000;
  line-height: 56px;
}
.theme-menu-five .right-widget .download-btn.style-two .dropdown-menu {
  background: #fff;
  border: 2px solid #000;
}
.theme-menu-five .right-widget .download-btn.show button:after {
  transform: rotate(180deg);
}
.theme-menu-five .right-widget .download-btn .dropdown-menu {
  width: 100%;
  background: #fff5d1;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
}
.theme-menu-five .right-widget .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
}
.theme-menu-five .right-widget .download-btn .dropdown-menu a:hover,
.theme-menu-five .right-widget .download-btn .dropdown-menu a:focus {
  background: rgba(255, 255, 255, 0.5);
}
.theme-menu-five .right-widget .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
.theme-menu-five .right-widget .signup-btn {
  color: #000;
  position: relative;
  margin-left: 38px;
}
.theme-menu-five .right-widget .signup-btn:before {
  content: "";
  position: absolute;
  height: 13px;
  width: 2px;
  background: #000;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.theme-menu-five .right-widget .signup-btn span {
  font-weight: 500;
}
.theme-menu-five .right-widget .signup-btn:hover {
  text-decoration: underline;
}
/*----------------------- Theme Hero Banner / Seven ---------------*/
.hero-banner-seven {
  position: relative;
  z-index: 1;
  margin: 30px 0 0;
}
.hero-banner-seven .illustration-container {
  position: absolute;
  top: 22px;
  right: 70px;
  max-width: 46%;
}
.hero-banner-seven .illustration-container img {
  animation: jumpTwo 6s infinite linear;
}
.hero-banner-seven .hero-heading {
  font-weight: 500;
  font-size: 60px;
  line-height: 1.15em;
  padding-right: 50px;
  margin-top: 60px;
}
.hero-banner-seven .hero-heading span {
  position: relative;
}
.hero-banner-seven .hero-heading span:before {
  content: "";
  width: 100%;
  height: 15px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 15px;
  z-index: -1;
}
.hero-banner-seven .hero-sub-heading {
  font-size: 24px;
  line-height: 1.5em;
  color: #2f2f2f;
  padding: 36px 0 58px 0;
}
.hero-banner-seven form {
  max-width: 550px;
  height: 80px;
  position: relative;
}
.hero-banner-seven form input {
  border: none;
  background: #f4f4f4;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 0 190px 0 30px;
}
.hero-banner-seven form input::placeholder {
  color: #adadad;
}
.hero-banner-seven form input::-webkit-input-placeholder {
  color: #adadad;
}
.hero-banner-seven form input::-moz-placeholder {
  color: #adadad;
}
.hero-banner-seven form input:-ms-input-placeholder {
  color: #adadad;
}
.hero-banner-seven form input:-moz-placeholder {
  color: #adadad;
}
.hero-banner-seven form button {
  position: absolute;
  right: 10px;
  top: 8px;
  bottom: 8px;
  background: #F39711;
  width: 170px;
  border-radius: 7px;
  text-align: center;
  color: #fff;
  font-size: 17px;
  transition: all 0.3s ease-in-out;
}
.hero-banner-seven form button:hover {
  background: #ffb851;
  // color: #212121;
}
.hero-banner-seven form ::-webkit-input-placeholder {
  /* Edge */
  color: #0b0b0b;
}
.hero-banner-seven form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0b0b0b;
}
.hero-banner-seven form ::placeholder {
  color: #0b0b0b;
}
.hero-banner-seven .term-text {
  font-size: 16px;
  color: #878787;
  margin-top: 22px;
}
.hero-banner-seven .download-btn {
  width: 190px;
  margin-top: 25px;
  display: none;
}
.hero-banner-seven .download-btn button {
  width: 100%;
  line-height: 60px;
  border-radius: 9px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background: #ffeaa0;
  padding-left: 12px;
}
// .hero-banner-seven .download-btn button:after {
//   content: url(../images/icon/89.svg);
//   border: none;
//   margin: 0 0 0 11px;
//   vertical-align: 0;
//   transition: all 0.2s ease-in-out;
// }
.hero-banner-seven .download-btn.show button:after {
  transform: rotate(180deg);
}
.hero-banner-seven .download-btn .dropdown-menu {
  width: 100%;
  background: #fff5d1;
  border-radius: 0px 0px 10px 10px;
  border: none;
  margin: -5px 0 0 0;
}
.hero-banner-seven .download-btn .dropdown-menu a {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
}
.hero-banner-seven .download-btn .dropdown-menu a:hover,
.hero-banner-seven .download-btn .dropdown-menu a:focus {
  background: rgba(255, 255, 255, 0.5);
}
.hero-banner-seven .download-btn .dropdown-menu a span {
  padding-left: 12px;
}
.hero-banner-seven .hero-left {
  padding-left: 70px;
}
@media screen and (max-width: 1199px) {
  .hero-banner-seven .hero-left {
    padding-left: 0px;
  }
}


/*---------------------- Fancy Feature Fifteen ------------------*/
.fancy-feature-fifteen {
  margin: 0 15px;
}
.fancy-feature-fifteen .bg-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  background: #f6f9fc;
  padding: 40px 0;
  border-radius: 20px;
  position: relative;
  z-index: 1;
}
.fancy-feature-fifteen .bg-wrapper:before {
  content: "";
  position: absolute;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  background: #75f1d5;
  top: -59px;
  right: -48px;
  z-index: 1;
  animation: jumpTwo 6s infinite linear;
}
.fancy-feature-fifteen .bg-wrapper .shape-one {
  left: -33px;
  top: 22%;
  animation: jumpTwo 6s infinite linear;
}
.fancy-feature-fifteen .bg-wrapper .shape-two {
  right: -33px;
  top: 58%;
  animation: jumpThree 6s infinite linear;
}
.fancy-feature-fifteen .carousel-indicators {
  position: relative;
  margin: 0;
}
.fancy-feature-fifteen li {
  margin: 0;
  padding: 0;
  height: auto;
  background: transparent;
  opacity: 0.75;
  border: none;
  text-indent: 0;
  position: relative;
  width: auto;
  margin-bottom: 10px;
}
.fancy-feature-fifteen li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #000;
  left: 0;
  bottom: -3px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.fancy-feature-fifteen li p {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding-left: 10px;
  transition: all 0.3s ease-in-out;
}
.fancy-feature-fifteen .react-tabs__tab-list {
  max-width: 66%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    max-width: 75%;
  }
  @media screen and (max-width: 991px) {
    max-width: 100%;
    justify-content: center;
    li {
      margin: 10px;
    }
  }
}
.fancy-feature-fifteen li.react-tabs__tab--selected,
.fancy-feature-fifteen li.react-tabs__tab--selected:before {
  opacity: 1;
}

/*--------------------- Fancy Feature Sixteen ------------------*/
.block-style-eighteen .text-wrapper h6 {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.63);
  padding-bottom: 15px;
}
.block-style-eighteen .text-wrapper .title {
  font-size: 48px;
  font-weight: 500;
  line-height: 1.05em;
}
.block-style-eighteen .text-wrapper .title span {
  position: relative;
  display: inline-block;
}
.block-style-eighteen .text-wrapper .title span:before {
  content: "";
  width: 100%;
  height: 13px;
  background: #ffeaa0;
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: -1;
}
.block-style-eighteen .text-wrapper p {
  font-size: 20px;
  line-height: 1.75em;
  color: #4f4f4f;
  padding: 45px 0 40px;
}
.block-style-eighteen .text-wrapper .learn-more {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.block-style-eighteen .text-wrapper .learn-more img {
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
}
.block-style-eighteen .text-wrapper .learn-more:hover img {
  transform: translateX(3px);
}
.block-style-eighteen .screen-holder-one {
  // width: 460px;
  width: 50vw;
  height: 470px;
  border-radius: 24px;
  background: #faf7f0;
  position: relative;
  z-index: 1;
  margin-left: auto;
}
.block-style-eighteen .screen-holder-one .round-bg {
  background: #fff;
  border-radius: 50%;
  &.big_g {
    @media screen and (max-width: 575px) {
      width: 150px !important;
      height: 150px !important;
    }
  }
}
.block-style-eighteen .screen-holder-one .shapes {
  z-index: 1;
}
.block-style-eighteen .screen-holder-one .logo-one {
  top: -33px;
  right: 32%;
  animation: jumpTwo 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-two {
  top: 20%;
  left: -42px;
  animation: jumpThree 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-three {
  top: 74%;
  left: -37px;
  animation: jumpTwo 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-four {
  bottom: -54px;
  left: 52%;
  animation: jumpThree 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .logo-five {
  top: 9%;
  right: -43px;
  animation: jumpThree 6s infinite linear;
}
.block-style-eighteen .screen-holder-one .shape-one {
  top: 42%;
  right: -43px;
}
.block-style-eighteen .screen-holder-two {
  position: relative;
  display: inline-block;
}
.block-style-eighteen .screen-holder-two .screen-one {
  z-index: 1;
  // bottom: -5%;
  bottom: -10%;
  right: -70%;
  width: 80%;
  // right: -42%;
  // width: 44.8%;
  animation: jumpThree 10s infinite linear;
}
.block-style-eighteen .screen-holder-three {
  width: 530px;
  height: 513px;
  background: #f0f6fb;
  position: relative;
  display: inline-block;
  border-radius: 25px;
  margin-left: auto;
}
.block-style-eighteen .screen-holder-three img {
  animation: jumpTwo 5s infinite linear;
}
/*----------------- Fancy Text block Twenty Two -------------------*/
.fancy-text-block-twentyTwo .text-wrapper {
  padding-left: 40px;
}
.fancy-text-block-twentyTwo .text-wrapper p {
  font-size: 20px;
  line-height: 1.75em;
  color: #292929;
  padding: 40px 0 67px;
}
.fancy-text-block-twentyTwo .illustration-holder img {
  animation: jumpTwo 6s infinite linear;
}
/*----------------- Fancy Feature Seventeen -------------------*/
.fancy-feature-seventeen {
  padding-left: 15px;
  padding-right: 15px;
}
.fancy-feature-seventeen .bg-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  background: #fcf7ea;
  border-radius: 25px;
  padding: 90px 0 40px;
}
.fancy-feature-seventeen .block-meta {
  margin-bottom: 70px;
  text-align: center;
  padding: 0 30px;
}
.fancy-feature-seventeen .block-meta .icon {
  height: 35px;
}
.fancy-feature-seventeen .block-meta .icon img {
  margin: 0 auto;
}
.fancy-feature-seventeen .block-meta h4 {
  font-size: 24px;
  font-weight: 500;
  padding: 28px 0 14px;
}
.fancy-feature-seventeen .block-meta p {
  line-height: 32px;
  color: #292929;
}
/*------------------- Pricing Section Six ------------------*/
.pricing-nav-four {
  margin: 70px auto 35px;
  border: 2px solid #000;
  padding: 6px;
  border-radius: 10px;
  width: 310px;
}
.pricing-nav-four .nav-item {
  margin: 0;
}
.pricing-nav-four .nav-item .nav-link,
.pricing-nav-four .react-tabs__tab {
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  width: 147px;
  text-align: center;
  color: #000;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.pricing-nav-four .nav-item .nav-link.active,
.pricing-nav-four .react-tabs__tab.react-tabs__tab--selected {
  background: #000;
  color: #fff;
}
.pricing-table-area-six {
  max-width: 1335px;
  padding: 0 15px;
  margin: 0 auto;
}
.pricing-table-area-six .row {
  margin: 0 -30px;
}
.pricing-table-area-six .row [class*="col-"] {
  padding: 0 30px;
}
.pricing-table-area-six .pr-table-wrapper {
  border: 1px solid #ececec;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  padding: 35px 35px 35px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.pricing-table-area-six .pr-table-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  border: 2px solid #000;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-six .pr-table-wrapper .pack-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  padding: 0 13px;
  display: inline-block;
  color: #000;
}
.pricing-table-area-six .pr-table-wrapper .pack-details {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 0 27px;
}
.pricing-table-area-six .pr-table-wrapper .price {
  font-size: 58px;
  font-weight: 500;
  color: #000;
  line-height: initial;
  padding: 13px 0 6px;
}
.pricing-table-area-six .pr-table-wrapper .pr-feature {
  text-align: left;
  padding: 28px 0 42px 8px;
}
.pricing-table-area-six .pr-table-wrapper .pr-feature li {
  position: relative;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 30px;
}
// .pricing-table-area-six .pr-table-wrapper .pr-feature li:before {
//   content: url(../images/icon/76.svg);
//   position: absolute;
//   top: 0;
//   left: 0;
// }
.pricing-table-area-six .pr-table-wrapper .trial-button {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 52px;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-six .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 20px;
}
.pricing-table-area-six .pr-table-wrapper:hover:before,
.pricing-table-area-six .pr-table-wrapper.active:before {
  opacity: 1;
}
.pricing-table-area-six .pr-table-wrapper .trial-button:hover,
.pricing-table-area-six .pr-table-wrapper.active .trial-button {
  background: #ffeaa0;
  border-color: #ffeaa0;
}

/*-------------- Footer Style Seven -------------*/
.theme-footer-seven .title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 3px;
  padding-bottom: 25px;
}
.theme-footer-seven .footer-list a {
  line-height: 40px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-footer-seven .footer-list a:hover {
  text-decoration: underline;
}
.theme-footer-seven .newsletter p {
  font-size: 19px;
  color: #000;
  padding: 10px 0 40px;
  span {
    text-decoration: underline;
  }
}
.theme-footer-seven .inner-btn-black .newsletter {
  button {
    background: #262626;
    color: #fff;
    &:hover {
      background: #eb5e2a;
    }
  }
}
.theme-footer-seven .newsletter p span {
  text-decoration: underline;
}
.theme-footer-seven .newsletter form {
  height: 70px;
  position: relative;
  margin-bottom: 12px;
}
.theme-footer-seven .newsletter form input {
  width: 100%;
  height: 100%;
  border: 2px solid #000;
  border-radius: 8px;
  padding: 0 125px 0 20px;
  font-size: 16px;
  color: #000;
}
.theme-footer-seven .newsletter form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}
.theme-footer-seven .newsletter form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
.theme-footer-seven .newsletter form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}
.theme-footer-seven .newsletter form button {
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  top: 8px;
  right: 8px;
  bottom: 8px;
  width: 115px;
  border-radius: 8px;
  background: #ffeaa0;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.theme-footer-seven .newsletter form button.dark-btn {
  background: #262626;
  color: #fff;
}
.theme-footer-seven .newsletter form button.dark-btn:hover {
  background: #eb5e2a;
}
.theme-footer-seven .newsletter .info {
  font-size: 14px;
  color: #878787;
}
.theme-footer-seven .bottom-footer {
  // border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 15px 0 15px;
  // margin-top: 45px;
}
.theme-footer-seven .bottom-footer .footer-nav a {
  font-size: 16px;
  color: #000;
  margin-right: 25px;
}
.theme-footer-seven .bottom-footer .footer-nav a:hover {
  text-decoration: underline;
}
.theme-footer-seven .bottom-footer .copyright {
  font-size: 16px;
  color: #373737;
  a {
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
}
.theme-footer-seven .bottom-footer .social-icon a {
  font-size: 20px;
  margin-left: 15px;
  color: #000;
  transition: 0.3s;
  &:hover {
    transform: translateY(-3px);
  }
}

/*---- Dark Style ----*/
.theme-footer-seven.dark-bg {
  .logo {
    img {
      filter: invert(1);
    }
  }
}
.theme-footer-seven.dark-bg {
  background: #262626;
  color: #fff;
}
.theme-footer-seven.dark-bg .title {
  color: rgba(255, 255, 255, 0.54);
}
.theme-footer-seven.dark-bg .footer-list a,
.theme-footer-seven.dark-bg .newsletter p,
.theme-footer-seven.dark-bg .bottom-footer .footer-nav a,
.theme-footer-seven.dark-bg .bottom-footer .copyright,
.theme-footer-seven.dark-bg .bottom-footer .social-icon a {
  color: #fff;
}
.theme-footer-seven.dark-bg .bottom-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

/*----------------- Fancy Feature Eighteen -------------------*/
.fancy-feature-eighteen {
  background: #f3f8ff;
  padding: 120px 15px 150px;
  position: relative;
  z-index: 1;
}
.fancy-feature-eighteen .react-tabs__tab-panel {
  background: #fff;
}
.fancy-feature-eighteen .react-tabs__tab-list {
  margin: 90px 0 70px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fancy-feature-eighteen .react-tabs__tab-panel {
  box-shadow: 0 40px 100px rgba(14, 33, 44, 0.05);
  overflow: hidden;
  border-radius: 20px;
}

.fancy-feature-eighteen .react-tabs__tab-list .react-tabs__tab {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fancy-feature-eighteen .nav-link {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 15px;
  padding: 10px;
  border: none;
  background: transparent;
  margin: 10px 22px;
}
.fancy-feature-eighteen .react-tabs__tab .hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0) translate(-50%, -50%);
}
.fancy-feature-eighteen .react-tabs__tab--selected .current {
  transform: scale(0);
}
.fancy-feature-eighteen .react-tabs__tab--selected .hover {
  transform: scale(1) translate(-50%, -50%);
}
.fancy-feature-eighteen .react-tabs__tab--selected .nav-link {
  background: #fff;
  box-shadow: 0 15px 30px rgba(9, 16, 29, 0.03);
}
.fancy-feature-eighteen .tab-content {
  max-width: 1450px;
  margin: 0 auto;
  background: transparent;
  min-height: 490px;
}
.fancy-feature-eighteen .tab-content .text-wrapper {
  padding: 40px 80px;
}
.fancy-feature-eighteen .tab-content .text-wrapper h4 {
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 40px;
}
.fancy-feature-eighteen .tab-content .text-wrapper p {
  font-size: 24px;
  line-height: 1.83em;
  color: #000;
}
.fancy-feature-eighteen .shape-right {
  bottom: 4%;
  right: 0;
  width: 13.35%;
  animation: fade-in 1.5s infinite linear;
}
.fancy-feature-eighteen .shape-left {
  bottom: 8%;
  left: 0;
  width: 13%;
  animation: fade-in-rev 1.5s infinite linear;
}
.fancy-feature-eighteen .tab-content .img-holder {
  position: relative;
}
.fancy-feature-eighteen .tab-content .img-holder .ripple-box {
  width: 385px;
  height: 160px;
  border: 3px solid #fff;
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fancy-feature-eighteen .tab-content .img-holder .ripple-box:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 40px;
  background: #fff;
  top: -40px;
  left: -3px;
}
.fancy-feature-eighteen .tab-content .img-holder .ripple-box:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background: #fff;
  top: -3px;
  left: -40px;
}

/*----------------- Fancy Feature Twenty One -------------------*/
.fancy-feature-twentyOne {
  background: #fcf6ee;
  padding: 140px 0;
}
/*----------------- Coming Soon ---------------*/
.full-height-layout {
  min-height: 100vh;
  padding: 40px 15px;
  position: relative;
  z-index: 5;
}
.coming-soon-content {
  max-width: 860px;
  margin: 0 auto;
  text-align: center;
}
.coming-soon-content h6 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: rgba(0, 0, 0, 0.63);
}
.coming-soon-content h1 {
  font-size: 72px;
  line-height: 1.18em;
  font-weight: 700;
  color: #000;
  padding: 35px 0 60px;
}
.coming-soon-content p {
  font-size: 24px;
  line-height: 1.79em;
  color: #000;
  padding-bottom: 55px;
}
.coming-soon-content form {
  max-width: 670px;
  height: 75px;
  position: relative;
  margin: 0 auto;
}
.coming-soon-content form input {
  display: block;
  width: 100%;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 0 220px 0 20px;
  height: 100%;
}
.coming-soon-content form button {
  width: 200px;
  background: #151515;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
  bottom: 6px;
  transition: all 0.3s ease-in-out;
}
.coming-soon-content form button:hover {
  background: var(--blue-dark);
}
.coming-soon-content .social-icon a {
  width: 34px;
  height: 34px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
  color: #333333;
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
}
.coming-soon-content .social-icon a:hover {
  background: #232323;
  color: #fff;
}
.coming-soon-content .social-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}
.coming-soon-content .shape-one {
  left: 0;
  top: 5%;
  animation: fade-in-rev 1.5s infinite linear;
}
.coming-soon-content .shape-two {
  left: 20%;
  top: 9%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-three {
  left: 11%;
  top: 35%;
  animation: jumpTwo 3.5s infinite linear;
}
.coming-soon-content .shape-four {
  left: 10%;
  top: 70%;
  animation: fade-in 1.5s infinite linear;
}
.coming-soon-content .shape-five {
  left: 22%;
  top: 90%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-six {
  right: 22%;
  top: 9%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-seven {
  right: 12%;
  top: 28%;
  animation: fade-in-rev 1.5s infinite linear;
}
.coming-soon-content .shape-eight {
  right: 12%;
  top: 60%;
  animation: jumpTwo 3.5s infinite linear;
}
.coming-soon-content .shape-nine {
  right: 25%;
  top: 85%;
  animation: jumpThree 3.5s infinite linear;
}
.coming-soon-content .shape-ten {
  right: 5%;
  bottom: 0;
  animation: fade-in 1.5s infinite linear;
}


/*----------------- Fancy Feature Twenty Four -------------------*/
.fancy-feature-twentyFour .bg-wrapper {
  background: #f5fbff;
  position: relative;
  z-index: 1;
  padding: 170px 0 130px;
}
.fancy-feature-twentyFour .click-scroll-button {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  top: -25px;
  left: 50%;
  margin-left: -26px;
}
.fancy-feature-twentyFour .click-scroll-button img {
  width: 20px;
}
.fancy-feature-twentyFour .shape-one {
  top: -25px;
  right: 25%;
  animation: rotated 12s infinite linear;
}
.fancy-feature-twentyFour .shape-two {
  top: 47%;
  right: 8%;
  animation: rotated 12s infinite linear;
}
.fancy-feature-twentyFour .shape-three {
  bottom: -20px;
  right: 13%;
  animation: jumpTwo 3s infinite linear;
}
.fancy-feature-twentyFour .shape-four {
  bottom: 31%;
  left: 10%;
  animation: rotated 12s infinite linear;
}
.fancy-feature-twentyFour .shape-five {
  top: 13%;
  left: 10%;
  animation: jumpTwo 3s infinite linear;
}
/*----------------- Partner Section One -------------------*/
.partner-section-one {
  position: relative;
  z-index: 1;
}
.partner-section-one .img-box a {
  display: block;
  height: 100%;
}
.partner-section-one .img-box img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.partner-section-one .img-box {
  background: #fff;
  border-radius: 50%;
  box-shadow: 15px 30px 50px rgba(26, 34, 72, 0.08);
  width: 175px;
  height: 175px;
  margin: 0 auto 35px;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}
.partner-section-one.bg-transparent .img-box {
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}
.partner-section-one .img-box:hover {
  transform: scale(1.1);
}
.partner-section-one .img-box.bx-b {
  width: 121px;
  height: 121px;
  margin-top: 45px;
}
.partner-section-one .img-box.bx-d {
  width: 151px;
  height: 151px;
  margin-top: 25px;
}
.partner-section-one .img-box.bx-f {
  width: 135px;
  height: 135px;
  margin-top: 20px;
}
.partner-section-one .img-box.bx-g {
  width: 197px;
  height: 197px;
  @media screen and (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
}
.partner-section-one .img-box.bx-h {
  width: 138px;
  height: 138px;
  margin-top: 20px;
}

.partner-section-one .shape-one {
  top: 9%;
  right: 14%;
  animation: rotated 12s infinite linear;
}
.partner-section-one .shape-two {
  top: 44%;
  right: 9%;
  animation: rotatedTwo 12s infinite linear;
}
.partner-section-one .shape-three {
  bottom: 19%;
  right: 12%;
  animation: jumpTwo 3s infinite linear;
}
.partner-section-one .shape-four {
  bottom: 0;
  right: 36%;
  animation: rotated 12s infinite linear;
}
.partner-section-one .shape-five {
  bottom: 0;
  left: 36%;
  animation: rotatedTwo 12s infinite linear;
}
.partner-section-one .shape-six {
  bottom: 17%;
  left: 18%;
  animation: rotated 12s infinite linear;
}
.partner-section-one .shape-seven {
  top: 43%;
  left: 9%;
  animation: jumpTwo 3s infinite linear;
}
.partner-section-one .shape-eight {
  top: 10%;
  left: 19%;
  animation: rotatedTwo 12s infinite linear;
}

/*----------------- Team Details ---------------*/
.team-details {
  background: #f2fbfd;
  padding: 160px 0;
  position: relative;
  z-index: 1;
}
.team-details .main-bg {
  background: #fff;
  box-shadow: 0px 40px 80px rgba(0, 29, 56, 0.03);
}
.team-details .main-bg .img-meta {
  width: 48%;
}
.team-details .main-bg .text-wrapper {
  width: 52%;
  padding: 40px 50px 40px 115px;
}
.team-details .main-bg .text-wrapper .name {
  font-size: 33px;
  color: #000;
}
.team-details .main-bg .text-wrapper .position {
  color: rgba(0, 0, 0, 0.63);
  padding: 10px 0 50px;
}
.team-details .main-bg .text-wrapper h6 {
  font-size: 18px;
  padding-bottom: 15px;
  text-transform: uppercase;
}
.team-details .main-bg .text-wrapper .social-icon a {
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 1;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}
.team-details .main-bg .text-wrapper .social-icon a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.team-details .main-bg .text-wrapper .social-icon a:hover {
  color: #fff;
  transform: translateY(-5px);
}
.team-details .main-bg .text-wrapper .social-icon a:hover:before {
  opacity: 1;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(1) a:before {
  background: #6f55ff;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(2) a:before {
  background: #1da1f2;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(3) a:before {
  background: #ea4c89;
}
.team-details .main-bg .text-wrapper .social-icon li:nth-child(4) a:before {
  background: #d44638;
}
.team-details .shape-one {
  left: 0;
  top: -127px;
  animation: jumpTwo 4s infinite linear;
}
.team-details .shape-two {
  right: 0;
  bottom: -127px;
  animation: jumpThree 4s infinite linear;
}
.team-details .pager {
  position: absolute;
  width: calc(100% + 180px);
  left: -90px;
  top: 50%;
  margin-top: -27px;
}
.team-details .pager a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  color: #000;
  font-size: 22px;
  transition: all 0.3s ease-in-out;
}
.team-details .pager a:hover {
  background: #353535;
  color: #fff;
}
.team-details .pager a:first-child i {
  display: inline-block;
  transform: rotate(180deg);
}
/*----------------- Terms and Condition ---------------*/
.terms_and_policy {
  padding-top: 60px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ececec;
}
.terms_and_policy .nav-tabs {
  border: none;
  background: #f2fbfd;
  padding: 30px 10px 30px 45px;
}
.terms_and_policy .nav-item {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
}
.terms_and_policy .nav-item .nav-link {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 60px;
  padding: 0;
  background: transparent;
  border: none;
  color: #222;
  transition: all 0.2s ease-in-out;
}
.terms_and_policy .nav-item .nav-link.active {
  color: var(--blue-dark);
}
.terms_and_policy .tab-content {
  padding: 0 0 0 50px;
}
.terms_and_policy .tab-content h2 {
  font-size: 55px;
  padding-bottom: 10px;
}
.terms_and_policy .tab-content .update-date {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
}
.terms_and_policy .tab-content h3 {
  font-family: "gilroy-semibold";
  font-size: 28px;
  padding: 20px 0 6px;
}
.terms_and_policy .tab-content h4 {
  margin-bottom: 20px;
}
.terms_and_policy .tab-content p {
  line-height: 34px;
  margin-bottom: 15px;
  color: #545454;
}
.terms_and_policy .tab-content p a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul {
  list-style-type: disc;
  padding-left: 18px;
}
.terms_and_policy .tab-content ul li {
  color: #545454;
}
.terms_and_policy .tab-content ul li a {
  text-decoration: underline;
  color: #000;
}
.terms_and_policy .tab-content ul li {
  padding-bottom: 10px;
}
/*----------------- 404 ---------------*/
.error-page {
  min-height: 100vh;
}
.error-page .img-holder {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.error-page .img-holder .illustration {
  min-height: 100vh;
  object-fit: cover;
  object-position: top center;
}
.error-page .img-holder .qus {
  top: 34%;
  left: 15%;
  width: 13%;
  z-index: 1;
  animation: fade-in 1s infinite linear;
}
.error-page .text-wrapper {
  width: 50%;
  padding: 50px 20px 50px 5%;
}
.error-page .text-wrapper h1 {
  font-size: 82px;
  line-height: 1.21em;
}
.error-page .text-wrapper p {
  font-size: 24px;
  line-height: 1.66em;
  font-weight: 300;
  color: #000;
  padding: 55px 15% 60px 0;
}
.error-page .text-wrapper .back-home {
  font-size: 17px;
  text-align: center;
  line-height: 55px;
  width: 200px;
  transition: all 0.3s ease-in-out;
  background: #000;
  padding: 0 20px;
  color: #fff;
}
.error-page .text-wrapper .back-home img {
  margin-left: 12px;
}
.error-page .text-wrapper .back-home:hover {
  background: var(--purple-blue);
  color: #fff;
}
.error-page .text-wrapper .logo {
  position: absolute;
  top: 50px;
  left: 5%;
}

.bg-2 {
  background: #f2fbfd;
}

/*--------------------- App Screen Preview ---------------*/
.app-screen-preview-one {
  position: relative;
  z-index: 1;
  margin-top: 200px;
}
.app-preview-slider-one .img-holder img {
  transform: scale(0.8);
  margin: 0 auto;
  border-radius: 40px;
  transition: all 0.3s ease-in-out;
}
.app-preview-slider-one .slick-center .img-holder img {
  transform: scale(1);
}
.app-screen-preview-one .round-bg {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.app-preview-slider-one {
  margin: 0 -55px;
}
.app-screen-preview-one .shape-one {
  top: 9%;
  right: 9%;
}
.app-screen-preview-one .shape-two {
  bottom: 37%;
  right: 9%;
}
.app-screen-preview-one .shape-three {
  bottom: 10%;
  left: 9%;
}
.app-screen-preview-one .shape-four {
  top: 42%;
  left: 9%;
}
/*--------------------- App Screen Preview ---------------*/
.app-preview-slider-two {
  max-width: 1830px;
  padding: 0 15px;
  margin: 0 auto;
}
.app-preview-slider-two .img-holder img {
  transform: scale(0.8);
  border-radius: 20px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}
.app-preview-slider-two .slick-center .img-holder img {
  transform: scale(1);
}
.app-screen-preview-one .round-bg {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*--------------------- Pricing Section Seven ------------------*/
.pricing-table-area-seven .pr-table-wrapper {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 3px 4px rgba(8, 20, 32, 0.04);
  border-radius: 15px;
  position: relative;
  padding: 60px 15px 50px 58px;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-seven .pr-table-wrapper .icon {
  height: 25px;
}
.pricing-table-area-seven .pr-table-wrapper .pack-name {
  font-size: 32px;
  font-weight: 500;
  color: #000;
  padding: 37px 0 30px;
}
.pricing-table-area-seven .pr-table-wrapper .pr-feature li {
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 26px;
  position: relative;
}
// .pricing-table-area-seven .pr-table-wrapper .pr-feature li:before {
//   content: url(../images/icon/139.svg);
//   position: absolute;
//   left: 0;
//   top: 0;
// }
.pricing-table-area-seven .pr-table-wrapper .price {
  color: #000;
  font-size: 28px;
  padding: 50px 0 3px;
}
.pricing-table-area-seven .pr-table-wrapper .price span {
  font-size: 18px;
}
.pricing-table-area-seven .pr-table-wrapper .trial-text {
  color: rgba(0, 0, 0, 0.54);
}
.pricing-table-area-seven .pr-table-wrapper .trial-button {
  width: 154px;
  line-height: 43px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  margin-top: 28px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.pricing-table-area-seven .pr-table-wrapper .trial-button:hover,
.pricing-table-area-seven .pr-table-wrapper.active .trial-button {
  background: #373737;
  color: #fff;
}
.pricing-table-area-seven .pr-table-wrapper.active,
.pricing-table-area-seven .pr-table-wrapper:hover {
  box-shadow: 0px 25px 60px rgba(0, 13, 48, 0.0480786);
  border-radius: 16px;
  border-color: #fff;
}

/*----------------- Fancy Feature Twenty Six -------------------*/
.fancy-feature-twentySix .row {
  margin: 0 -20px;
}
.fancy-feature-twentySix .row [class*="col-"] {
  padding: 0 20px;
}
/*----------------- Video Box Two -------------------*/
.video-box-two .video-icon {
  width: 170px;
  height: 110px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.3s ease-in-out;
  transform: translate(-50%, -50%);
}
.video-box-two .video-icon img {
  margin-left: -9px;
}
.video-box-two .video-icon:hover {
  background: #ffb840;
}
/*----------------- Fancy Text block Twenty Three -------------------*/
.fancy-text-block-twentyThree .text-wrapper .cl-text {
  font-size: 24px;
  color: #000;
  margin-bottom: 25px;
}
.fancy-text-block-twentyThree .text-wrapper .cl-text span {
  color: #ffb840;
}
.fancy-text-block-twentyThree .img-container {
  padding: 0 10% 12% 0;
}
.fancy-text-block-twentyThree .img-container .screen-one {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 51.8%;
  animation: jumpTwo 5s infinite linear;
}
/*-------------------- Useable Tools Four --------------------*/
.useable-tools-section-four {
  padding: 44px 0 35px;
  overflow: hidden;
}
.useable-tools-section-four .bg-wrapper {
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
  background: #ebf3f8;
}
// .useable-tools-section-four .bg-wrapper:before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 44px;
//   background: url(../images/shape/226.jpg) no-repeat center top;
//   background-size: cover;
//   left: 0;
//   top: -40px;
// }
// .useable-tools-section-four .bg-wrapper:after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 35px;
//   background: url(../images/shape/227.jpg) no-repeat center bottom;
//   background-size: cover;
//   left: 0;
//   bottom: -30px;
// }
.useable-tools-section-four .text-wrapper p {
  font-size: 24px;
  line-height: 1.91em;
  color: #000;
  padding: 40px 0 50px;
}
.useable-tools-section-four .text-wrapper {
  padding: 120px 0 115px;
}
.useable-tools-section-four .logo-container {
  position: relative;
  z-index: 1;
}
.useable-tools-section-four .logo-container .inner-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.useable-tools-section-four .logo-container .icon {
  height: 235px;
  background: #fff;
  margin: 35px 0;
}
.useable-tools-section-four .logo-container .line-two {
  transform: translateY(30px);
}
/*-------------------- Pricing Section Eight --------------------*/
.pricing-section-eight .pr-table-wrapper {
  border: 1px solid #ededed;
  border-radius: 15px;
  text-align: center;
}
.pricing-section-eight .pr-column {
  border-right: 1px solid #ededed;
}
.pricing-section-eight .pr-column:last-child {
  border-right: none;
}
.pricing-section-eight .pr-column .plan {
  display: inline-block;
  border: 1px solid #cfcfcf;
  border-radius: 17px;
  line-height: 28px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.5px;
  margin: 29px 0 22px;
  padding: 0 15px;
}
.pricing-section-eight .pr-column .price {
  font-size: 52px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #000;
  line-height: initial;
  margin-bottom: 3px;
}
.pricing-section-eight .pr-column .pr-header {
  padding-bottom: 35px;
  border-bottom: 1px solid #ededed;
}
.pricing-section-eight .pr-body .pr-text {
  font-size: 16px;
  display: inline-block;
  margin-bottom: 5px;
}
.pricing-section-eight .pr-body li img {
  height: 14px;
}
.pricing-section-eight .pr-body li {
  border-bottom: 1px solid #ededed;
  padding: 32px 0;
}
.pricing-section-eight .trial-button {
  width: 160px;
  line-height: 46px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.pricing-section-eight .trial-button:hover {
  background: #373737;
  color: #fff;
}
.pricing-section-eight .trial-text {
  font-size: 16px;
  margin-top: 20px;
}
.pricing-section-eight .pr-footer {
  padding: 38px 0 25px;
}
.pricing-section-eight .pr-list-wrapper {
  border: 1px solid #ededed;
  border-radius: 15px 0 0 15px;
  border-right: none;
  top: 229px;
  left: 0;
  height: 100%;
}
.pricing-section-eight .pr-list-wrapper li {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  line-height: 14px;
  border-bottom: 1px solid #ededed;
  padding: 32px 0 32px 25px;
}
.pricing-section-eight .pr-list-wrapper li:last-child {
  border-bottom: none;
}
/*-------------------- Faq Section Five --------------------*/
.accordion-style-five .card {
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
.accordion-style-five .card .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion-style-five .card .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 30px 35px 30px 0;
  font-size: 21px;
  font-weight: 500;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
  border-top: 1px solid #e5e5e5;
}
.accordion-style-five .card:first-child .card-header button {
  border-top: 1px solid #e5e5e5;
}
.accordion-style-five .card .card-header button:before {
  content: "\f107";
  font-family: "font-awesome";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.accordion-style-five .card .card-body {
  padding: 0 50px 20px 0;
}
.accordion-style-five .card .card-body p {
  padding-bottom: 15px;
}
.accordion-style-five .card .card-body p a {
  text-decoration: underline;
  color: var(--p-color);
}
.accordion-style-five .card:last-child .card-body {
  border: none;
  padding-bottom: 0;
}


/*-------------------- Fancy Portfolio Two ---------------------*/
.mixitUp-container {
  overflow: hidden;
}
.po-control-one button {
  display: inline-block;
  line-height: 48px;
  border-radius: 8px;
  padding: 0 33px;
  margin: 0 0.9%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.po-control-one li.react-tabs__tab.react-tabs__tab--selected button {
  background: #000;
  color: #fff;
}
.mixitUp-container.gutter-space-one {
  margin: 0 -25px;
}
.mixitUp-container.gutter-space-one .mix {
  padding: 0 25px;
  margin-bottom: 50px;
}
.mixitUp-container.gutter-space-two {
  margin: 0 -20px;
}
.mixitUp-container.gutter-space-two .mix {
  padding: 0 20px;
  margin-bottom: 40px;
}
.fancy-portfolio-two .mix {
  width: 33.33%;
}
.fancy-portfolio-two .mix.mixL {
  width: 66.66%;
}
.portfolio-block-two {
  border-radius: 20px;
}
.portfolio-block-two {
  overflow: hidden;
  box-shadow: 0 5px 28px 4px #efefef;
}
.portfolio-block-two .hover-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #141222;
  padding: 15px 5px 20px 30px;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  transform: translateY(100%);
}
.portfolio-block-two .hover-content h3 a {
  font-family: "gilroy-bold";
  font-size: 22px;
  color: #fff;
  position: relative;
}
.portfolio-block-two .hover-content h3 a:before {
  content: "";
  position: absolute;
  left: 104%;
  bottom: 6px;
  width: 22px;
  height: 2px;
  background: #fff;
}
.portfolio-block-two .hover-content h3 a:hover {
  text-decoration: underline;
}
.portfolio-block-two .hover-content .tag {
  font-size: 15px;
  margin-top: -5px;
  color: rgba(255, 255, 255, 0.7);
}
.portfolio-block-two {
  .fancybox {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0;
    transform: translateX(10px);
    transition: all 0.3s ease-in-out;
    .inner {
      cursor: pointer;
    }
  }
}
.portfolio-block-two .fancybox:hover {
  background: var(--blue-dark);
}
.portfolio-block-two:hover .fancybox {
  opacity: 1;
  transform: translateX(0);
}
.portfolio-block-two:hover .hover-content {
  opacity: 1;
  transform: translateY(0);
}
.portfolio-block-two:hover .img-meta {
  opacity: 0.85;
}
/*-------------------- Fancy Portfolio Three ---------------------*/
.fancy-portfolio-three .mix {
  width: 50%;
}
/*-------------------- Fancy Portfolio Four ---------------------*/
.fancy-portfolio-four .mix {
  width: 33.33333%;
}
.contact-title {
  font-weight: 500;
  line-height: 1.34em;
  font-size: 55px;
  letter-spacing: -1px;
  @media (max-width: 991px) {
    font-size: 28px;
  }
}
/*-------------------- Fancy Portfolio Five ---------------------*/
.fancy-portfolio-five {
  overflow: hidden;
  .slick-dots li button {
    width: 17px;
    height: 17px;
  }
}
.fancy-portfolio-five .slider-wrapper {
  margin: 0 -20vw;
}
.portfolio_slider_one {
  margin: 0 -25px;
}
.portfolio_slider_one .item {
  padding: 0 25px;
}
.portfolio-block-three {
  display: flex;
  flex-direction: column-reverse;
  .img-holder {
    display: block;
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    margin-top: 36px;
    margin-bottom: 25px;
  }
}
.portfolio-block-three .img-holder:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.slick-center .portfolio-block-three .img-holder:hover img {
  transform: scale3d(1.1, 1.1, 1);
}
.portfolio-block-three .text {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.portfolio-block-three .text .tag {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #b2b2b2;
  margin-bottom: 12px;
}
.portfolio-block-three .text a {
  font-family: "gilroy-semibold";
  font-size: 44px;
  line-height: 1.27em;
  color: #000;
  letter-spacing: -1px;
}
.portfolio-block-three .text a:hover {
  text-decoration: underline;
}
.slick-center .portfolio-block-three .img-holder:before {
  opacity: 0;
}
.slick-center .portfolio-block-three .text {
  opacity: 1;
}
.fancy-portfolio-five .slider-arrows li {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: #000;
  font-size: 25px;
  margin-left: 8px;
  cursor: pointer;
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
}
.fancy-portfolio-five .slider-arrows li:first-child i {
  transform: rotate(-180deg);
  display: inline-block;
}
.fancy-portfolio-five .slider-arrows li:hover {
  background: #353535;
  color: #fff;
  transform: scale(1);
}
/*-------------------- Fancy Portfolio Six ---------------------*/
.fancy-portfolio-six .mixitUp-container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}
.fancy-portfolio-six .mix {
  margin-bottom: 50px;
}
.po-control-two .react-tabs__tab {
  padding: 0 3%;
  margin: 8px 0;
  font-size: 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.po-control-two button {
  color: #868686;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background: #000;
    transition: 1.2s;
  }
}
.po-control-two .react-tabs__tab.react-tabs__tab--selected button {
  color: #000;
  &::after {
    width: 100%;
  }
}
.po-control-two .react-tabs__tab:not(:last-child):after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #868686;
  border-radius: 50%;
  right: -2px;
  bottom: 7px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.fancy-portfolio-six .scroll-target {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border: 1px solid #dedede;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.fancy-portfolio-six .scroll-target:hover {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.05);
  border-color: transparent;
}
/*-------------------- Portfolio Details One ---------------------*/
.portfolio-details-one .header .tag {
  font-size: 16px;
  color: #bdbdbd;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 12px;
}
.portfolio-details-one .header h2 {
  letter-spacing: -1px;
}
.portfolio-details-one .header .social-icon a {
  width: 44px;
  height: 44px;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  line-height: 42px;
  color: #000;
  font-size: 20px;
  margin: 0 6px;
  transition: all 0.3s ease-in-out;
}
.portfolio-details-one .header .social-icon a:hover {
  background: #000;
  color: #fff;
}
.portfolio-details-one .main-content .project-info strong {
  display: block;
  font-size: 16px;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.portfolio-details-one .main-content .project-info span {
  display: block;
  text-transform: uppercase;
  color: #a1a1a1;
  font-size: 17px;
  letter-spacing: 1px;
  margin: -2px 0 50px;
}
.portfolio-details-one .main-content h4 {
  font-family: "gordita";
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
  letter-spacing: -1px;
}
.portfolio-details-one .main-content p {
  margin-bottom: 50px;
}
.portfolio-details-one .portfolio-pagination .tp1 {
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 3px;
  color: rgba(0, 0, 0, 0.3);
}
.portfolio-details-one .portfolio-pagination .tp2 {
  font-family: "gordita";
  font-weight: 500;
  font-size: 25px;
  letter-spacing: -1px;
  color: #000;
}
.portfolio-details-one .portfolio-pagination a:hover .tp2 {
  text-decoration: underline;
}

/*-------------------- Counter Style Three --------------*/
.counter-box-five .number {
  font-size: 58px;
  font-weight: 700;
  line-height: initial;
}
.counter-box-five p {
  color: #000;
}
.counter-box-five .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 auto 15px;
}
/*-------------------- Fancy Feature Thirty --------------------*/
.fancy-feature-thirty {
  padding: 0 10px;
}
.fancy-feature-thirty .wrapper {
  max-width: 1450px;
  margin: 0 auto;
  padding: 85px 10px 105px;
  position: relative;
  background: #f5fafd;
  border-radius: 30px;
}
.fancy-feature-thirty .wrapper .shape-one {
  right: -55px;
  top: -65px;
  animation: jumpThree 6s infinite linear;
}
.fancy-feature-thirty .wrapper .shape-two {
  left: -45px;
  bottom: -70px;
  animation: jumpTwo 6s infinite linear;
}

/*-------------- Pricing Section Nine ----------------*/
.pricing-section-nine .pr-table-wrapper {
  border: 1px solid #ececec;
  border-radius: 20px;
  padding: 35px 45px;
}
.pricing-section-nine .pr-table-wrapper .pack-name {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}
.pricing-section-nine .pr-table-wrapper .pack-details {
  font-size: 16px;
  line-height: 28px;
  color: #878787;
  padding: 9px 50px 30px 0;
}
.pricing-section-nine .pr-table-wrapper .price {
  font-size: 26px;
  letter-spacing: -1px;
  padding-right: 4px;
  font-weight: 500;
  color: #000;
}
.pricing-section-nine .pr-table-wrapper .sub-price {
  position: relative;
  top: 6px;
}
.pricing-section-nine .pr-table-wrapper .top-banner span {
  font-size: 22px;
  color: #000;
  display: block;
  margin-bottom: -5px;
}
.pricing-section-nine .pr-table-wrapper .top-banner em {
  font-size: 14px;
  color: #9b9b9b;
  display: block;
  font-style: normal;
}
.pricing-section-nine .pr-table-wrapper .trial-button {
  font-size: 16px;
  color: #fff;
  display: block;
  text-align: center;
  line-height: 55px;
  border-radius: 5px;
  background: #6f6cff;
  margin: 10px 0 20px;
}
.pricing-section-nine .pr-table-wrapper .trial-button:hover {
  background: #212121;
}
.pricing-section-nine .pr-table-wrapper .pr-feature li {
  position: relative;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 30px;
  margin-left: 10px;
  font-size: 15px;
}
.pricing-section-nine .pr-table-wrapper .pr-feature li:before {
  content: url(../images/rdy-icons/76.svg);
  position: absolute;
  top: 0;
  left: 0;
}
.pricing-section-nine .pr-table-wrapper .trial-text {
  font-size: 16px;
  padding-top: 30px;
  color: rgba(0, 0, 0, 0.4);
}





.gradient-wipe {
  text-align: center;
  
  background: repeating-linear-gradient(90deg, #32CCEE 1%, #FF2D86 25%, #FF01F1 50%, #FFD335 75%, #32CCEE 99%);
  background-size: 200% auto;
  
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  animation: shine 30s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

.theme-btn-orange {
  background: #F39711 !important;
  // width: 170px;
  // border-radius: 7px;
  // text-align: center;
  color: #fff !important;
  // font-size: 17px;
  transition: all 0.3s ease-in-out;
}
.theme-btn-orange:hover {
  background: #ffb851 !important;
  // color: #212121;
}